import React, {
    DetailedHTMLProps,
    FunctionComponent,
    HTMLAttributes,
} from "react";
import {
    Box,
    Button,
    ButtonProps,
    Menu,
    MenuButton,
    MenuButtonProps,
    MenuItem,
    MenuList,
    OmitCommonProps,
} from "@chakra-ui/react";
import { MdArrowDropDown } from "react-icons/md";
import { isNil } from "lodash";
import { MenuListProps } from "@mui/material";

type Props = {
    options: {
        label: string;
        value: string;
    }[];
    onChangeHandler: (value: string) => void;
    value: string;
    placeholder?: string;
    extraPropsForMenuButton?: Partial<MenuButtonProps> | Partial<ButtonProps>;
    extraPropsForMenuList?: Partial<
        OmitCommonProps<
            DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
            keyof MenuListProps
        >
    >;
};

export const RellieSelect: FunctionComponent<Props> = ({
    options,
    onChangeHandler,
    value,
    placeholder = "SELECT",
    extraPropsForMenuButton = {},
    extraPropsForMenuList = {},
}) => {
    return (
        <Box>
            <Menu>
                <MenuButton
                    as={Button}
                    rightIcon={<MdArrowDropDown fill={"#FF4DD1"} />}
                    borderRadius={"none"}
                    bg={"none"}
                    _hover={{ bg: "none" }}
                    _expanded={{ bg: "none" }}
                    _focus={{ bg: "none" }}
                    color={"#032E59"}
                    fontWeight={700}
                    border={"none"}
                    minW={"250px"}
                    textAlign={"left"}
                    borderBottom={"1px solid #032E59"}
                    paddingLeft={0}
                    {...extraPropsForMenuButton}
                >
                    <pre style={{ fontFamily: "inherit" }}>
                        {isNil(value)
                            ? placeholder
                            : options.find((option) => option.value === value)
                                  ?.label ?? placeholder}
                    </pre>
                </MenuButton>
                <MenuList
                    maxH={"230px"}
                    overflowY={"scroll"}
                    {...extraPropsForMenuList}
                >
                    {options.map((option, i) => (
                        <MenuItem
                            color={"#032E59"}
                            fontSize={"16px"}
                            fontWeight={700}
                            lineHeight={"20px"}
                            key={i}
                            onClick={() => onChangeHandler(option.value)}
                        >
                            <pre style={{ fontFamily: "inherit" }}>
                                {option.label}
                            </pre>
                        </MenuItem>
                    ))}
                </MenuList>
            </Menu>
        </Box>
    );
};
