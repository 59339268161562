import React, { FunctionComponent } from "react";
import {
    AbsoluteCenter,
    Box,
    Button,
    Checkbox,
    Divider,
    Grid,
    GridItem,
    Heading,
    HStack,
    Image,
    Stack,
    Text,
    VStack,
} from "@chakra-ui/react";
import { BsCheck2, BsX } from "react-icons/bs";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { InputForm } from "../common/InputForm";
import { AdminFillFormButton } from "../../../lib/app/AdminFillFormButton";
import { Link as ReactRouterLink, useLocation } from "@reach/router";
import googleIcon from "../../assets/google-icon.png";
import { Dot } from "../common/Dot";
import { useApp } from "../../../lib/app/App";
import { ErrorMessage } from "../common/ErrorMessage"

type Props = {
    feedback?: any;
    onContinueHandler: any;
    email: string;
    mode: string;
    role: string;
};
const schema = z.object({
    email: z.string().email(),
    password: z
        .string()
        .min(1, "Password is required")
        .min(8, "Password must have more than 8 characters")
        .refine(
            (value) =>
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/.test(
                    value,
                ),
            "Password must contain at least one uppercase letter, one lowercase letter, one number and one special character",
        ),
    confirmPassword: z.string().min(1, "Password confirmation is required"),
});

export const CreatePasswordComponent: FunctionComponent<Props> = ({
    feedback,
    onContinueHandler,
    email,
    mode,
    role,
}) => {
    const { search } = useLocation();
    const qs = new URLSearchParams(search);
    let finalSchema = schema;

    if (role !== "STUDENT") {
        finalSchema = finalSchema.extend({
            termsAndConditions_PASSWORD: z
                .boolean()
                .refine(
                    (value) => !!value,
                    "You must accept the terms and conditions",
                ),
        });
    }

    finalSchema = finalSchema.refine(
        (data) => data.password === data.confirmPassword,
        {
            path: ["confirmPassword"],
            message: "Passwords do not match",
        },
    );

    const {
        register,
        formState: { errors },
        getValues,
        clearErrors,
        setValue,
        watch,
        handleSubmit,
    } = useForm({
        resolver: zodResolver(finalSchema),
        defaultValues: {
            email: email || "",
            password: "",
            confirmPassword: "",
            termsAndConditions_PASSWORD: false,
            termsAndConditions_GOOGLE_OAUTH: false,
            privacyPolicy: false,
        },
    });
    const password = watch("password");
    const isGOOGLE_OAUTHTermsAndConditionsAccepted =
        role === "STUDENT" || watch("termsAndConditions_GOOGLE_OAUTH");

    const { formatRequestUrl } = useApp();

    function onAutofill() {
        const isPreview = window.localStorage.getItem(
            "re-unlock-preview-token",
        );
        if (isPreview) {
            if (!getValues("password")) {
                setValue("password", "123!abc!ABC");
                setValue("confirmPassword", "123!abc!ABC");
                // NOTE: These are not working
                // TODO: Get these working
                // setValue("termsAndConditions_PASSWORD", true);
            }
        }
    }
    function triggerLogin() {
        window.location.href = formatRequestUrl("login-google", "", {
            token: qs.get("token"),
        });
    }

    const renderTermsAndConditions = (mode) => {
        if (role === "PARENT") {
            return (
                <VStack>
                    <Checkbox
                        size="sm"
                        colorScheme="brand.blue"
                        border={"#136AFF"}
                        {...register(`termsAndConditions_${mode}`, {
                            required: true,
                        })}
                    >
                        <Text fontSize={"12px"} color={"#032E59"}>
                            I have read and accepted{" "}
                            <a
                                href="https://rellie.com/terms-conditions/"
                                target={"_blank"}
                            >
                                <strong>Rellie’s Terms and Conditions</strong>
                            </a>
                        </Text>
                    </Checkbox>
                    <Text fontSize={"12px"} color={"#032E59"}>
                        Minors must have a parent or guardian consent on their
                        behalf.
                    </Text>
                    {errors?.[`termsAndConditions_${mode}`]?.message && (
                        <Text
                            fontSize={"12px"}
                            color={"#FF0000"}
                            alignSelf={"flex-start"}
                        >
                            {errors?.[`termsAndConditions_${mode}`]?.message}
                        </Text>
                    )}
                </VStack>
            );
        } else if (role === "STUDENT") {
            return (
                <Text fontSize={"12px"} color={"#032E59"} textAlign={"center"}>
                    Use of the Rellie Platform is governed by the{" "}
                    <a
                        href="https://rellie.com/terms-conditions/"
                        target={"_blank"}
                    >
                        <strong>Rellie’s Terms and Conditions</strong>
                    </a>{" "}
                    as accepted by the individual or institution purchasing this
                    license on your behalf.
                </Text>
            );
        } else if (role === "TEACHER") {
            return (
                <>
                    <Checkbox
                        size="sm"
                        colorScheme="brand.blue"
                        border={"#136AFF"}
                        {...register(`termsAndConditions_${mode}`, {
                            required: true,
                        })}
                    >
                        <Text fontSize={"12px"} color={"#032E59"}>
                            I have read and accepted{" "}
                            <a
                                href="https://rellie.com/terms-conditions/"
                                target={"_blank"}
                            >
                                <strong>Rellie’s Terms and Conditions</strong>
                            </a>
                        </Text>
                    </Checkbox>
                    {errors?.[`termsAndConditions_${mode}`]?.message && (
                        <Text
                            fontSize={"12px"}
                            color={"#FF0000"}
                            alignSelf={"flex-start"}
                        >
                            {errors?.[`termsAndConditions_${mode}`]?.message}
                        </Text>
                    )}
                </>
            );
        } else {
            throw new Error(`Login role '${role}' not supported!`);
        }
    };

    return (
        <>
            <HStack
                zIndex={"2"}
                spacing={"144px"}
                mb={"80px"}
                bg={"#fff"}
                borderRadius={"20px"}
            >
                <VStack
                    bg={"rgba(19, 106, 255, 0.2)"}
                    w={"100%"}
                    maxW={"420px"}
                    p={"30px"}
                    boxShadow={"0px 4px 14px rgba(19, 106, 255, 0.2)"}
                    borderRadius={"20px"}
                    spacing={"11px"}
                >
                    <Heading
                        textAlign={"left"}
                        w={"100%"}
                        fontSize={"26px"}
                        size={"md"}
                        lineHeight={"20px"}
                        color={"#032E59"}
                    >
                        Let’s Get Started
                    </Heading>
                    {/* <Text alignSelf={"start"}>
                        Already have an account? &nbsp;
                        <Text
                            as={ReactRouterLink}
                            to="/"
                            fontWeight={"900"}
                            color={"#032E59"}
                        >
                            Log in here.
                        </Text>
                    </Text> */}

                    <Text
                        alignSelf={"flex-start"}
                        fontSize={"16px"}
                        color={"#032E59"}
                        fontWeight={"700"}
                    >
                        Account Information
                    </Text>

                    {(!mode || mode === "ANY" || mode === "GOOGLE_OAUTH") && (
                        <>
                            <Text fontSize={"12px"}>
                                Please sign-up your Google Account
                                {email && " for email address"}
                            </Text>
                            <Text fontSize={"12px"} fontWeight="bold">
                                {email}
                            </Text>
                            {renderTermsAndConditions("GOOGLE_OAUTH")}
                            <Button
                                w={"100%"}
                                display={"flex"}
                                alignItems={"center"}
                                bg={"#ffffff"}
                                fontSize={"14px"}
                                fontWeight={"500"}
                                lineHeight={"19px"}
                                boxShadow={
                                    "0px 0px 0px rgba(0, 0, 0, 0.08), 0px 1px 2px rgba(0, 0, 0, 0.25)"
                                }
                                borderRadius={"37px"}
                                minHeight={"40px"}
                                color={"rgba(0, 0, 0, 0.54)"}
                                mt={"19px"}
                                onClick={triggerLogin}
                                isDisabled={
                                    !isGOOGLE_OAUTHTermsAndConditionsAccepted
                                }
                            >
                                <Image
                                    src={googleIcon}
                                    maxW={"30px"}
                                    maxH={"30px"}
                                />
                                Continue with Google account
                            </Button>
                        </>
                    )}

                    {(!mode || mode === "ANY") && (
                        <Box position="relative" padding="5" w="100%">
                            <Divider borderColor="#bbb" w="100%" />
                            <AbsoluteCenter px="6">OR</AbsoluteCenter>
                        </Box>
                    )}

                    {(!mode || mode === "ANY" || mode === "PASSWORD") && (
                        <>
                            <VStack alignItems={"flex-start"}>
                                <AdminFillFormButton onClick={onAutofill} />

                                <Text fontSize={"12px"}>
                                    Enter your email address
                                </Text>
                                <InputForm
                                    placeholder={"Enter email address"}
                                    name={"email"}
                                    required={true}
                                    register={register}
                                    height={"40px"}
                                    width={"354px"}
                                    type={"email"}
                                    rest={{
                                        fontSize: "14px",
                                        border: "1px solid #ACCBFF",
                                        borderRadius: "37px",
                                        bg: "black.100",
                                        color: "gray.800",
                                        sx: {
                                            "&[disabled],& [aria-disabled=true],&[aria-disabled]":
                                                {
                                                    opacity: 1,
                                                },
                                        },
                                        isDisabled: !!email,
                                    }}
                                    error={
                                        errors?.email?.message as
                                            | string
                                            | undefined
                                    }
                                />
                            </VStack>
                            <VStack alignItems={"flex-start"}>
                                <Text fontSize={"12px"}>Create password</Text>
                                <InputForm
                                    placeholder={"Enter password"}
                                    name={"password"}
                                    required={true}
                                    register={register}
                                    height={"40px"}
                                    width={"354px"}
                                    type={"password"}
                                    rest={{
                                        fontSize: "14px",
                                        border: "1px solid #ACCBFF",
                                        borderRadius: "37px",
                                        bg: "#ffffff",
                                    }}
                                    error={
                                        errors?.password?.message as
                                            | string
                                            | undefined
                                    }
                                />
                            </VStack>
                            <VStack
                                alignItems={"flex-start"}
                                color={"#032E59"}
                                fontSize={"9px"}
                                w={"100%"}
                            >
                                <Text>Password must contain:</Text>
                                <Grid templateColumns={"1fr 1fr"} w={"100%"}>
                                    <GridItem
                                        display={"flex"}
                                        alignItems={"center"}
                                    >
                                        {password.length >= 8 ? (
                                            <BsCheck2 color={"#1B998B"} />
                                        ) : (
                                            <BsX color={"#FF0000"} />
                                        )}
                                        At least 8 characters
                                    </GridItem>
                                    <GridItem
                                        display={"flex"}
                                        alignItems={"center"}
                                    >
                                        {/\d/.test(password) ? (
                                            <BsCheck2 color={"#1B998B"} />
                                        ) : (
                                            <BsX color={"#FF0000"} />
                                        )}
                                        At least 1 number
                                    </GridItem>
                                    <GridItem
                                        display={"flex"}
                                        alignItems={"center"}
                                    >
                                        {/[A-Z]/.test(password) ? (
                                            <BsCheck2 color={"#1B998B"} />
                                        ) : (
                                            <BsX color={"#FF0000"} />
                                        )}
                                        At least 1 uppercase letter
                                    </GridItem>
                                    <GridItem
                                        display={"flex"}
                                        alignItems={"center"}
                                    >
                                        {/[a-z]/.test(password) ? (
                                            <BsCheck2 color={"#1B998B"} />
                                        ) : (
                                            <BsX color={"#FF0000"} />
                                        )}
                                        At least 1 lowercase letter
                                    </GridItem>
                                    <GridItem
                                        display={"flex"}
                                        alignItems={"center"}
                                    >
                                        {/[^A-Za-z0-9]/.test(password) ? (
                                            <BsCheck2 color={"#1B998B"} />
                                        ) : (
                                            <BsX color={"#FF0000"} />
                                        )}
                                        At least 1 special character
                                    </GridItem>
                                </Grid>
                            </VStack>

                            <VStack alignItems={"flex-start"}>
                                <Text fontSize={"12px"}>Confirm password</Text>
                                <InputForm
                                    placeholder={"Confirm Password"}
                                    name={"confirmPassword"}
                                    required={true}
                                    register={register}
                                    height={"40px"}
                                    width={"354px"}
                                    type={"password"}
                                    rest={{
                                        fontSize: "14px",
                                        border: "1px solid #ACCBFF",
                                        borderRadius: "37px",
                                        bg: "#ffffff",
                                        onChange: () => {
                                            clearErrors("confirmPassword");
                                        },
                                    }}
                                    error={
                                        errors?.confirmPassword?.message as
                                            | string
                                            | undefined
                                    }
                                />
                            </VStack>

                            {renderTermsAndConditions("PASSWORD")}

                            <ErrorMessage feedback={feedback}/>

                            <Button
                                bg={"#136AFF"}
                                borderRadius={"30px"}
                                width={"171px"}
                                minHeight={"40px"}
                                color={"#FFFFFF"}
                                fontWeight={"900"}
                                fontSize={"16px"}
                                lineHeight={"20px"}
                                onClick={handleSubmit(onContinueHandler)}
                            >
                                Create Account
                            </Button>
                        </>
                    )}
                </VStack>
            </HStack>
            <Stack zIndex={"1"}>
                <Dot
                    size={"29px"}
                    color={"#1CCF8C"}
                    top={{ base: "2%", lg: "10%" }}
                    left={{ base: "82%", lg: "83.17%" }}
                />
                <Dot
                    size={"41px"}
                    color={"#F5C306"}
                    top={{ base: "2%", lg: "18.875%" }}
                    left={{ base: "18%", lg: "24.33%" }}
                />
                <Dot
                    size={"44px"}
                    color={"#FFFC82"}
                    top={{ base: "41%", lg: "46.84%" }}
                    left={{ base: "3%", lg: "8.68%" }}
                />
                <Dot
                    size={"18px"}
                    color={"#136AFF"}
                    top={{ base: "21%", lg: "26.76%" }}
                    left={{ base: "90%", lg: "76.05%" }}
                />
                <Dot
                    size={"53px"}
                    color={"#F56806"}
                    top={{ base: "41%", lg: "57.23%" }}
                    left={{ base: "89%", lg: "77.44%" }}
                />
                <Dot
                    size={"53px"}
                    color={"#ED47C4"}
                    top={{ base: "16%", lg: "60.28%" }}
                    left={{ base: "3%", lg: "20.83%" }}
                />
            </Stack>
        </>
    );
};
