
import { useApp, isAdminToolsEnabled } from '../../../lib/app/App';
import { useLocation, useNavigate } from "@reach/router";

import Button from "../common/Button";
import React from "react";

export function AdminMakeIncompleteQuestionnaireButtonComponent () {

    const { api, useData } = useApp();

    const location = useLocation();
    const navigate = useNavigate();
    const questionnaireData = useData("Assessment.Questionnaire");

    if (!isAdminToolsEnabled()) {
        return null;
    }

    return (
        <Button
            actionMode="once"
            size='xs'
            backgroundColor={"red"}
            onClick={async ({ resetButton }) => {

                await api.Assessment.Questionnaire.DevMakeIncomplete.mutate();
                await questionnaireData.refetch();
                if (location.search) {
                    navigate(location.pathname);
                }
                resetButton()
            }}
        >
            Make Incomplete
        </Button>
    );
}
