import React, { createContext, useContext, useState, useEffect } from "react";
import { navigate } from "@reach/router";
import { useApp } from "./App";
import { getCookie, deleteCookie } from "../util"

const ROLES = {
    admin: "Rellie Admin",
    teacher: "Teacher",
    parent: "Parent",
    student: "Student",
};

type ContextType = {
    ROLES: typeof ROLES;
    config?: {
        [key: string]: any;
    };
    // selectedRole?: string;
    // selectRole?: (role: string, doNavigate?: boolean) => void;
};

const defaultContext = {
    ROLES,
};

const AdminContext = createContext<ContextType>(defaultContext);

export const AdminProvider = ({ children }) => {

    const { useData, getUnlockPreviewToken, getEnvironment } = useApp();

    const unlockPreviewToken = getUnlockPreviewToken();
    const userData = useData("User");
    const config = userData.data?.admin || (unlockPreviewToken && {}) || null;

    // const [selectedRole, setSelectedRole] = useState(
    //     window.localStorage.getItem("re-admin-selected-role") || "admin",
    // );

    // function selectRole (role, doNavigate = true) {
    //     if (role.toUpperCase() === role) {
    //         throw new Error(
    //             `TMP: 'role' was provided in uppercase and needs to be lowercase`,
    //         );
    //     }

    //     if (!ROLES[role]) {
    //         throw new Error(`Unknown role '${role}'!`);
    //     }
    //     window.localStorage.setItem("re-admin-selected-role", role);
    //     setSelectedRole(role);
    //     if (doNavigate) {
    //         navigate(`/${role}/dashboard`);
    //     }
    // }
    
    useEffect(() => {        
        if (getEnvironment() === 'production') {
            var script = document.createElement('script');
            script.type = 'text/javascript';
            script.innerText = `
                window.smartlook||(function(d) {
                var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
                var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
                c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);
                })(document);
                smartlook('init', 'd3923988add6bcb3c83377c4dcf4425e4c658e91', { region: 'eu' });
            `;
            document.getElementsByTagName('head')[0].appendChild(script);
        }
    }, [true])

    useEffect(() => {
        if (window.localStorage.getItem("re-is-public-device") === "1") {
            if (getCookie("re-user-last-login-email")) {
                deleteCookie("re-user-last-login-email")
            }
        }
    }, [userData.data?.session?.Id])

    const context = {
        ...defaultContext,
        config,
        // selectedRole,
        // selectRole,
    };

    return (
        <AdminContext.Provider value={context}>
            {children}
        </AdminContext.Provider>
    );
};

export const useAdminContext = () => useContext(AdminContext);
