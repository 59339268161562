import React, { FunctionComponent } from "react";
import {
    Box,
    Button,
    Flex,
    HStack,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    VStack,
} from "@chakra-ui/react";
import { Searchbar } from "../../components/common/Searchbar";
import { RouteComponentProps } from "@reach/router";
import { AddAdminModal } from "../../components/admin/AddAdminModal";
import { useQueryClient } from "@tanstack/react-query";
import { useApp } from "../../../lib/app/App";

export const AdminInternalUsersPage: FunctionComponent<
    RouteComponentProps
> = () => {
    const { api, useData, webappOrigin, formatRequestUrl } = useApp();

    const queryClient = useQueryClient();

    const licenses = useData("Admin.AdminLicenses");

    async function onSendInvite(data) {
        let response = await api.Admin.AdminLicenses.Add.mutate({
            ...data,
            webappOrigin,
            registrationBaseUrl: formatRequestUrl(
                "api",
                "/api/user/register/from-email",
            ),
        });

        if (response.error) {
            return response;
        }

        queryClient.invalidateQueries({ queryKey: ["Admin.AdminLicenses"] });

        return true;
    }

    async function onResendInvite(id) {
        await api.Admin.AdminLicenses.ResendInvitation.mutate({
            id,
            webappOrigin,
            registrationBaseUrl: formatRequestUrl(
                "api",
                "/api/user/register/from-email",
            ),
        });
    }

    if (licenses.isLoading || !licenses.data || !licenses.data.length) {
        return <div>Loading...</div>;
    }

    return (
        <VStack alignItems={"flex-start"} spacing={"19px"}>
            <Searchbar />
            <VStack textAlign="start">
                <Text as={"h1"} fontSize={"24px"} fontWeight={"700"}>
                    Internal Rellie Administration
                </Text>
                <Text w={"100%"} fontSize={"14px"} color={"#5F6368"}>
                    Admin User Management
                </Text>
            </VStack>
            <VStack
                m={0}
                bg={"rgba(19, 106, 255, 0.03)"}
                borderRadius={"20px"}
                p={"17px"}
                w={"100%"}
                spacing={"18px"}
            >
                <Text
                    fontSize={"16px"}
                    color={"#032E59"}
                    alignSelf={"start"}
                    lineHeight={"20px"}
                    fontWeight={"700"}
                >
                    Admin Licenses
                </Text>
                <HStack w={"100%"} justifyContent={"space-between"}>
                    <HStack spacing={"55px"}>
                        {/* <Text fontSize={"14px"}>Available: 50</Text>
                        <Text fontSize={"14px"}>Total: 100</Text> */}
                    </HStack>
                    <HStack>
                        <AddAdminModal
                            title="Add Admin"
                            onSendInvite={onSendInvite}
                        />
                    </HStack>
                </HStack>
                <TableContainer mt={"18px"}>
                    <Table
                        variant="simple"
                        bg={"#FFFFFF"}
                        borderRadius={"16px"}
                        sx={{
                            "& th": {
                                color: "#5F6368",
                                fontSize: "10px",
                                textTransform: "capitalize",
                            },
                            "& td": {
                                fontSize: "12px",
                            },
                        }}
                    >
                        <Thead>
                            <Tr>
                                <Th>First Name</Th>
                                <Th>Last Name</Th>
                                <Th>Email</Th>
                                <Th>Status</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {licenses.data.map((license) => (
                                <Tr key={license.Id}>
                                    <Td>{license.FirstName}</Td>
                                    <Td>{license.LastName}</Td>
                                    <Td>{license.Email}</Td>
                                    <Td>
                                        <Flex placeItems={"center"} gap={3}>
                                            {license.registrationStatus ==
                                                "REGISTERED" && (
                                                <>
                                                    <Box
                                                        display={"inline-block"}
                                                        width="10px"
                                                        height="10px"
                                                        borderRadius="full"
                                                        backgroundColor="green.500"
                                                    />
                                                    Registered
                                                </>
                                            )}
                                            {license.registrationStatus ==
                                                "INVITED" && (
                                                <>
                                                    <Box
                                                        display={"inline-block"}
                                                        width="10px"
                                                        height="10px"
                                                        borderRadius="full"
                                                        backgroundColor="orange.500"
                                                    />
                                                    Invited
                                                    <Button
                                                        color={"#0051DB"}
                                                        borderRadius={"41px"}
                                                        height={"20px"}
                                                        fontSize={"12px"}
                                                        variant="outline"
                                                        borderColor={"#0051DB"}
                                                        onClick={() =>
                                                            onResendInvite(
                                                                license.Id,
                                                            )
                                                        }
                                                    >
                                                        Resend Registration
                                                    </Button>
                                                </>
                                            )}
                                        </Flex>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </VStack>
        </VStack>
    );
};
