import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Controller } from "react-hook-form";
import { Center, useColorModeValue, Icon } from "@chakra-ui/react";
import { AiFillFileAdd } from "react-icons/ai";
import React from "react";

const Dropzone = ({ onChange, value }) => {
    const onDrop = useCallback(
        (acceptedFiles) => {
            onChange(acceptedFiles[0]);
        },
        [onChange],
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            "image/png": [".png"],
            "application/pdf": [".pdf"],
        },
        maxFiles: 1,
        multiple: false,
    });

    const dropText = isDragActive
        ? "Drop the files here ..."
        : value
        ? `File selected: ${value.name}`
        : "Drag file here, or click to select files";

    const activeBg = useColorModeValue("gray.100", "gray.600");
    const borderColor = useColorModeValue(
        isDragActive ? "teal.300" : "gray.300",
        isDragActive ? "teal.500" : "gray.500",
    );

    return (
        <Center
            p={10}
            cursor="pointer"
            bg={isDragActive ? activeBg : "transparent"}
            _hover={{ bg: activeBg }}
            transition="background-color 0.2s ease"
            borderRadius={4}
            border="3px dashed"
            borderColor={borderColor}
            {...getRootProps()}
        >
            <input {...getInputProps()} />
            <Icon as={AiFillFileAdd} mr={2} />
            <p>{dropText}</p>
        </Center>
    );
};

export function DropzoneComponent({ control, name }) {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { onChange, value } }) => (
                <Dropzone value={value} onChange={onChange} />
            )}
        />
    );
}
