import React, { FunctionComponent } from "react";
import { RouteComponentProps, useParams, navigate } from "@reach/router";
import { Heading, VStack, HStack, Text, Box, Select, Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Button,
 } from "@chakra-ui/react";
import { Loading } from "../../components/common/Loading";
import { useApp } from "../../../lib/app/App";
import { RellieSelect } from "../../components/common/RellieSelect";

export const AdminQaAnswersScoringPage: FunctionComponent<
    RouteComponentProps
> = () => {

    const { isAdmin, useData, formatRequestUrl } = useApp();

    const params = useParams();
   
    const assessmentsData = useData("Admin.QA.Assessments");

    const selectedAssessment = params.assessmentId || "";
    const selectedStrategyType = params.strategyType || "parent";

    function onSelectAssessment (assessmentId) {
        navigate(`/admin/qa/answers-scoring/${assessmentId}/${selectedStrategyType}`);
    }

    function onSelectStrategyType (strategyType) {
        navigate(`/admin/qa/answers-scoring/${selectedAssessment}/${strategyType}`);
    }

    const reportData = useData("Admin.QA.AnswersScoringReport", {
        Id_Assessment: parseInt(selectedAssessment) || -1,
        StrategyType: selectedStrategyType
    });

    function onDownload () {
        const url = formatRequestUrl('api', `/api/admin/qa/answers-scoring-download/${selectedAssessment}/${selectedStrategyType}`);
        window.open(url);
    }

    if (!isAdmin) {
        return null;
    }

    if (
        !assessmentsData.data ||
        !reportData.data
    ) {
        return <Loading/>;
    }

    return (
        <VStack alignItems={"flex-start"} spacing={"52px"}>
            <Box marginTop={"50px;"}>
                <Heading size={"md"} color={"#032E59"} mb="15px">
                    Quality Assurance for Assessment Answers Scoring
                </Heading>

                <VStack alignItems={"start"}>
                    <HStack justifyContent={"space-between"} mb="15px">
                        <RellieSelect
                            value={selectedAssessment}
                            placeholder={"SELECT ASSESSMENT"}
                            onChangeHandler={onSelectAssessment}
                            options={
                                assessmentsData.data.map((assessmentData) => ({
                                    label: `${assessmentData.Id} - Grade: ${assessmentData.Grade} - Student: ${assessmentData.Id_Student} - ${assessmentData.StudentName}`,
                                    value: assessmentData.Id
                                }))
                            }
                        />
                        <RellieSelect
                            value={selectedStrategyType}
                            placeholder={"SELECT STRATEGY TYPE"}
                            onChangeHandler={onSelectStrategyType}
                            options={
                                [
                                    { label: "Parent Strategies", value: "parent" },
                                    { label: "Teacher Strategies", value: "teacher" },
                                ]
                            }
                        />

                        { (selectedAssessment && selectedStrategyType) && (
                            <Button
                                color="#032E59"
                                variant="outline"
                                borderColor="#0051DB"
                                borderRadius={"41px"}
                                height={"20px"}
                                fontSize={"12px"}
                                onClick={onDownload}
                            >
                                Download CSV
                            </Button>
                        ) }
                    </HStack>

                    {(selectedAssessment === "" && (
                        <Text>Select an assessment to see the report.</Text>
                    )) || (
                        <TableContainer mt={"18px"}>
                            <Table
                                variant="simple"
                                bg={"#FFFFFF"}
                                borderRadius={"16px"}
                                size="sm"
                                sx={{
                                    "& th": {
                                        color: "#5F6368",
                                        fontSize: "10px",
                                        textTransform: "capitalize",
                                    },
                                    "& td": {
                                        fontSize: "12px",
                                    },
                                }}
                            >
                                <Thead>
                                    <Tr>
                                        <Th></Th>
                                        <Th colSpan="2">
                                            <Box bgColor={"#ececec"} p="3px">Question</Box>
                                        </Th>
                                        <Th colSpan="3">
                                            <Box bgColor={"#ececec"} p="3px">Answer</Box>
                                        </Th>
                                        <Th colSpan="2">
                                            <Box bgColor={"#ececec"} p="3px">Strategy</Box>
                                        </Th>
                                    </Tr>
                                    <Tr>
                                        <Th>Cognitive Area</Th>
                                        <Th>ID</Th>
                                        <Th>Text</Th>
                                        <Th>ID</Th>
                                        {/* <Th>Order</Th> */}
                                        <Th>Score</Th>
                                        <Th>Text</Th>
                                        <Th>IDs</Th>
                                        <Th>Texts</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {reportData.data.map((record, i) => (
                                        <Tr key={i} 
                                            sx={{
                                                "& td": (record.isLastAnswer && {
                                                    borderBottomColor: "black"
                                                }) || {}
                                            }}
                                        >
                                            <Td>
                                                <Box
                                                    w="150px"
                                                    whiteSpace={"normal"}
                                                >
                                                    {record.Cognitive}
                                                </Box>
                                            </Td>
                                            <Td>{record.Id_Question}</Td>
                                            <Td>
                                                <Box
                                                    w="300px"
                                                    whiteSpace={"normal"}
                                                >
                                                    {record.QuestionText}
                                                </Box>
                                            </Td>
                                            <Td
                                                backgroundColor={(record.Student_Answer && "#f6f6f6") || "inherit"}
                                            >{record.Optional_Answer}</Td>
                                            {/* <Td
                                                backgroundColor={(record.Student_Answer && "#f6f6f6") || "inherit"}
                                            >{record.Q_Order}</Td> */}
                                            <Td
                                                backgroundColor={(record.Student_Answer && "#f6f6f6") || "inherit"}
                                            >{record.Score}</Td>
                                            <Td
                                                backgroundColor={(record.Student_Answer && "#f6f6f6") || "inherit"}
                                            >
                                                <Box
                                                    w="200px"
                                                    whiteSpace={"normal"}
                                                >
                                                    {record.AnswerText}
                                                </Box>
                                            </Td>
                                            <Td>
                                                <Box
                                                    w="60px"
                                                    whiteSpace={"normal"}
                                                >
                                                    {record.CombinedIdStrategies}
                                                </Box>
                                            </Td>
                                            <Td>
                                                <Box
                                                    w="600px"
                                                    whiteSpace={"normal"}
                                                >
                                                    {record.TeacherStrategies || record.ParentStrategies}
                                                </Box>
                                            </Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    )}
                </VStack>

            </Box>
        </VStack>
    );
};
