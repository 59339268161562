import {
    Card,
    CardBody,
    GridItem,
    Heading,
    Switch,
    VStack,
    Button,
    useDisclosure,
} from "@chakra-ui/react";
import React, { FunctionComponent } from "react";
import { ProgressButton } from "../common/ProgressButton";
import { ShareReportWithSchoolModal } from "../parent/ShareReportWithSchoolModal";
import { isAdminToolsEnabled } from "../../../lib/app/App";

type PermissionsProps = {
    permissions: any;
};

export const Permissions: FunctionComponent<PermissionsProps> = ({
    permissions,
}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const permissionHandlers = {};
    permissions.forEach((permission) => {
        permissionHandlers[permission.name] = permission.onChange;
    });

    return (
        <>
            <ShareReportWithSchoolModal
                isOpen={isOpen}
                onClose={onClose}
                onConfirm={async () => {
                    await permissionHandlers["SHARE_WITH_SCHOOL"]();
                }}
            />
            <GridItem>
                <VStack alignItems={"flex-start"}>
                    <Heading size={"sm"} color={"#032E59"}>
                        Permissions
                    </Heading>
                    <Card
                        w={"100%"}
                        maxW={"100%"}
                        bg={"#FFFFFF"}
                        borderRadius={"16px"}
                        overflow={"auto"}
                    >
                        {permissions.map((permission, i) => (
                            <CardBody
                                key={i}
                                display={"flex"}
                                justifyContent={"space-between"}
                            >
                                {permission.label}

                                {(permission.name === "SHARE_WITH_SCHOOL" && (
                                    <>
                                        {isAdminToolsEnabled() && (
                                            <Button
                                                size="xs"
                                                backgroundColor={"red"}
                                                onClick={permission.onChange}
                                            >
                                                Toggle
                                            </Button>
                                        )}
                                        <Switch
                                            colorScheme="green"
                                            isChecked={permission.isChecked}
                                            disabled={!!permission.isChecked}
                                            onChange={onOpen}
                                        />
                                    </>
                                )) || (
                                    <ProgressButton
                                        getTag={({ onClick }) => (
                                            <Switch
                                                colorScheme="green"
                                                isChecked={permission.isChecked}
                                                onChange={onClick}
                                            />
                                        )}
                                        onClick={async ({ resetButton }) => {
                                            await permission.onChange();
                                            resetButton();
                                        }}
                                    />
                                )}
                            </CardBody>
                        ))}
                    </Card>
                </VStack>
            </GridItem>
        </>
    );
};
