import React from "react";
import {
    VStack,
    Text,
    Link,
    Image,
    Grid,
    GridItem,
    IconButton,
    HStack,
    Icon,
} from "@chakra-ui/react";
import { Link as ReachLink } from "@reach/router";
import logoSmall from "../../assets/logov2.svg";
import { ImFacebook, ImLinkedin2, ImYoutube } from "react-icons/im";

export const Footer = () => {
    return (
        <VStack w={"100%"}>
            <Grid
                bg={"#032E59"}
                w={"100%"}
                height={{ base: "auto" }}
                color={"#FFFFFF"}
                justifyContent={"center"}
                alignItems={"start"}
                paddingTop={"62px"}
                gap={{ base: "13px", lg: "37px" }}
                as={"footer"}
                maxW={"100%"}
                templateColumns={{
                    base: "repeat(2, 1fr)",
                    lg: "repeat(6, 1fr)",
                }}
                paddingLeft={{ base: "20px", lg: "50px" }}
                paddingRight={{ base: "20px", lg: "50px" }}
                paddingBottom={{ base: "20px", lg: "50px" }}
            >
                <GridItem
                    w="100%"
                    display={"flex"}
                    justifyContent={"flex-start"}
                >
                    <Image
                        src={logoSmall}
                        alt="logo"
                        minW={{ lg: "51px", base: "73px" }}
                        minH={{ lg: "45px", base: "64px" }}
                    />
                </GridItem>
                <VStack
                    alignItems={"start"}
                    justifyContent={"start"}
                    order={{ base: 3, lg: 1 }}
                >
                    <Text color={"#FFFC82"}>Rellie</Text>
                    <Link href="https://relliecom.wpcomstaging.com/about-us/">
                        About us
                    </Link>
                    <Link href="https://relliecom.wpcomstaging.com/about-us/#why-blue">
                        Why Rellie
                    </Link>
                    <Link href="https://relliecom.wpcomstaging.com/#meet-charlie">
                        Case Studies
                    </Link>
                </VStack>
                <VStack alignItems={"start"} order={{ base: 5, lg: 2 }}>
                    <Text color={"#FFFC82"}>How it works</Text>
                    <Link
                        href={"https://rellie.com/how-it-works/#how-it-works"}
                    >
                        How Rellie Works
                    </Link>
                </VStack>
                <VStack alignItems={"start"} order={{ base: 6, lg: 3 }}>
                    <Text color={"#FFFC82"}>Support</Text>
                    <Link href="https://rellie.com/rellie-trust-centre/">
                        Trust Centre
                    </Link>
                    <Link href={"https://rellie.com/contact-us/"}>
                        Contact Us
                    </Link>
                    <Link href={"https://rellie.com/troubleshooting-faq/"}>
                        Troubleshooting FAQ
                    </Link>
                </VStack>

                <VStack alignItems={"start"} order={{ base: 2, lg: 5 }}>
                    <Link
                        href="https://relliecom.wpcomstaging.com/investors/"
                        color={"#FFFC82"}
                    >
                        Investors
                    </Link>
                    <Link as={ReachLink} to="/home" color={"#FFFC82"}>
                        News
                    </Link>
                    <Link
                        href="https://relliecom.wpcomstaging.com/careers/"
                        color={"#FFFC82"}
                    >
                        Careers
                    </Link>
                    <Link
                        href="https://relliecom.wpcomstaging.com/terms-conditions/"
                        color={"#FFFC82"}
                    >
                        Terms & Conditions
                    </Link>
                    <Link
                        href="https://relliecom.wpcomstaging.com/privacy-policy/"
                        color={"#FFFC82"}
                    >
                        Privacy Policy
                    </Link>
                </VStack>

                <GridItem
                    colSpan={{ base: 2, lg: 6 }}
                    order={{ base: 4, lg: 6 }}
                    maxW={"100%"}
                    borderTop={"1px solid #2A4C6F"}
                >
                    <HStack mt={"12px"} spacing={"10px"}>
                        <IconButton
                            as={"a"}
                            w={"20px"}
                            h={"20px"}
                            minW={"20px"}
                            minH={"20px"}
                            target={"_blank"}
                            bg={"rgba(19, 106, 255, 1)"}
                            borderRadius={"50%"}
                            icon={
                                <Icon
                                    w={"12px"}
                                    h={"12px"}
                                    minW={"12px"}
                                    minH={"12px"}
                                    as={ImLinkedin2}
                                    color={"rgba(3, 46, 89, 1)"}
                                />
                            }
                            aria-label={"Linkedin"}
                            href={"https://www.linkedin.com/company/rellie/"}
                        />
                        <IconButton
                            as={"a"}
                            target={"_blank"}
                            bg={"rgba(19, 106, 255, 1)"}
                            borderRadius={"50%"}
                            w={"20px"}
                            h={"20px"}
                            minW={"20px"}
                            minH={"20px"}
                            icon={
                                <Icon
                                    w={"12px"}
                                    h={"12px"}
                                    minW={"12px"}
                                    minH={"12px"}
                                    as={ImYoutube}
                                    color={"rgba(3, 46, 89, 1)"}
                                />
                            }
                            aria-label={"Youtube"}
                            href={"https://www.youtube.com/@rellielearn"}
                        />
                        <IconButton
                            as={"a"}
                            target={"_blank"}
                            bg={"rgba(19, 106, 255, 1)"}
                            borderRadius={"50%"}
                            w={"20px"}
                            h={"20px"}
                            minW={"20px"}
                            minH={"20px"}
                            icon={
                                <Icon
                                    w={"12px"}
                                    h={"12px"}
                                    minW={"12px"}
                                    minH={"12px"}
                                    as={ImFacebook}
                                    color={"rgba(3, 46, 89, 1)"}
                                />
                            }
                            aria-label={"Facebook"}
                            href={"https://www.facebook.com/rellielearn"}
                        />
                    </HStack>
                </GridItem>
            </Grid>
        </VStack>
    );
};
