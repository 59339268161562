import React, { FunctionComponent, useRef } from "react";
import { RouteComponentProps, useParams, navigate } from "@reach/router";
import { Heading, VStack, HStack, Text, Box, Select, Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Input,
    Button,
 } from "@chakra-ui/react";
import { Loading } from "../../components/common/Loading";
import { useApp } from "../../../lib/app/App";
import { RellieSelect } from "../../components/common/RellieSelect";

export const AdminQaAssessmentPage: FunctionComponent<
    RouteComponentProps
> = () => {

    const assessmentIdEl = useRef()

    const { isAdmin, useData, formatRequestUrl } = useApp();

    const params = useParams();
   
    const selectedAssessment = params.assessmentId || "";
   
    function onLoadReport () {
        navigate(`/admin/qa/assessment/${assessmentIdEl.current.value}`);
    }

    const reportData = useData("Admin.QA.AssessmentReport", {
        Id_Assessment: parseInt(selectedAssessment) || -1,
    });

    if (!isAdmin) {
        return null;
    }

    if (
        !reportData.data
    ) {
        return <Loading/>;
    }

    return (
        <VStack alignItems={"flex-start"} spacing={"52px"}>
            <Box marginTop={"50px;"}>
                <Heading size={"md"} color={"#032E59"} mb="15px">
                    Assessment Internal Report
                </Heading>

                <VStack alignItems={"start"}>
                    <HStack justifyContent={"space-between"} mb="15px">
                        <Input
                            ref={assessmentIdEl}
                            name="assessmentId"
                            isRequired={true}
                            size="md"
                        />
                        <Button
                            color="#032E59"
                            variant="outline"
                            borderColor="#0051DB"
                            borderRadius={"41px"}
                            height={"20px"}
                            fontSize={"12px"}
                            paddingLeft="30px"
                            paddingRight="30px"
                            onClick={onLoadReport}
                        >
                            Load Internal Report
                        </Button>
                    </HStack>

                    {(selectedAssessment === "" && (
                        <Text>Specify an assessment ID to see the report.</Text>
                    )) || (
                        <code style={{fontSize: "0.7rem"}}><pre>
                            {JSON.stringify(reportData.data.internalReport, null, 4)}
                        </pre></code>
                    )}
                </VStack>
            </Box>
        </VStack>
    );
};
