import { useApp, isAdminToolsEnabled } from "./App";

import {
    Box,
    Button,
    HStack,
    Menu,
    MenuButton,
    MenuItemOption,
    MenuList,
} from "@chakra-ui/react";
import { FiChevronDown } from "react-icons/fi";
import React from "react";

export function AdminSelectAPIBackendComponent (props) {

    const { API_BACKEND_ENVIRONMENTS, useApiBackend } = useApp();

    const [selectedApiBackendEnvironment, setSelectedApiBackendEnvironment] =
        useApiBackend();

    if (!isAdminToolsEnabled()) {
        return null;
    }
    
    return (
        <Box {...props}>
            <HStack>
                <Box color="red">
                    <b>API:</b>
                </Box>
                <Box fontSize="14" color="black">
                    <Menu>
                        <MenuButton
                            size="xs"
                            as={Button}
                            rightIcon={<FiChevronDown />}
                            backgroundColor="red"
                        >
                            {selectedApiBackendEnvironment}
                        </MenuButton>
                        <MenuList>
                            {Object.keys(API_BACKEND_ENVIRONMENTS).map(
                                (backend) => {
                                    return (
                                        <MenuItemOption
                                            key={backend}
                                            value={backend}
                                            onClick={() => {
                                                setSelectedApiBackendEnvironment(
                                                    backend,
                                                );
                                            }}
                                        >
                                            {backend}
                                        </MenuItemOption>
                                    );
                                },
                            )}
                        </MenuList>
                    </Menu>
                </Box>
            </HStack>
        </Box>
    );
}
