import React from "react";
import {
    HStack,
    VStack,
    Box,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Button,
    chakra,
} from "@chakra-ui/react";
import * as z from "zod";
import { InputForm } from "../common/InputForm";
import { useForm } from "react-hook-form";
import { isNil } from "lodash";
import { School } from "../../pages/Goverment/GovernmentLicenses";
import { zodResolver } from "@hookform/resolvers/zod";

const schema = z.object({
    schoolName: z.string().nonempty("School Name is required"),
    adminFirstName: z.string().nonempty("Administrator First Name is required"),
    adminLastName: z.string().nonempty("Administrator Last Name is required"),
    numberOfLicenses: z.coerce.number().refine((val) => val > 0, {
        message: "Number of Licenses is required and greater than 0",
    }),
    administratorEmail: z
        .string()
        .email()
        .nonempty("Administrator Email is required"),
    quoteNumber: z.string().optional(),
});

type ExtendedSchool = School & {
    quoteNumber?: string;
};

type AddSchoolProps =
    | {
          handleAddSchool: (data: Omit<ExtendedSchool, "id">) => void;
          handleEditSchool?: never;
          isOpen: boolean;
          onClose: () => void;
          isAdmin?: boolean;
          defaultValues?: never;
          type: "SCHOOL" | "SCHOOL_BOARD" | "GOVERNMENT";
      }
    | {
          handleEditSchool: (data: ExtendedSchool) => void;
          handleAddSchool?: never;
          defaultValues: ExtendedSchool;
          isOpen: boolean;
          onClose: () => void;
          isAdmin?: boolean;
          type: "SCHOOL" | "SCHOOL_BOARD" | "GOVERNMENT";
      };
export const SchoolModal = ({
    defaultValues,
    isOpen,
    onClose,
    isAdmin = false,
    handleEditSchool,
    handleAddSchool,
    type,
}: AddSchoolProps) => {
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm({
        resolver: zodResolver(schema),
        defaultValues: {
            schoolName: defaultValues?.name,
            adminFirstName: defaultValues?.adminFirstName,
            adminLastName: defaultValues?.adminLastName,
            numberOfLicenses: defaultValues?.licensesTotal,
            administratorEmail: defaultValues?.adminEmail,
            quoteNumber: defaultValues?.quoteNumber,
        },
        shouldUnregister: true,
    });

    console.log("type", type);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent maxW="724px">
                <ModalHeader
                    mt="14px"
                    fontFamily="Nunito"
                    fontStyle="normal"
                    fontWeight="700"
                    fontSize="16px"
                    lineHeight="20px"
                    color="#032E59"
                >
                    {isNil(defaultValues) ? "Add" : "Edit"} License Recipient
                    <Text
                        fontFamily="Nunito"
                        fontStyle="normal"
                        fontWeight="400"
                        fontSize="14px"
                        lineHeight="24px"
                    >
                        Enter the information below.
                    </Text>
                </ModalHeader>
                <chakra.form
                    onSubmit={handleSubmit((data) => {
                        const newSchool = {
                            name: data.schoolName,
                            adminFirstName: data.adminFirstName,
                            adminLastName: data.adminLastName,
                            adminEmail: data.administratorEmail,
                            licensesTotal: data.numberOfLicenses,
                            status: "pending",
                        };

                        if (!isNil(defaultValues)) {
                            handleEditSchool({
                                ...newSchool,
                                id: defaultValues.id,
                            });
                        } else {
                            handleAddSchool(newSchool);
                        }
                        onClose();
                    })}
                >
                    <ModalBody color={"black"}>
                        <VStack w="100%">
                            <HStack w="100%" gap="20px">
                                <Box w="50%" minHeight="75px">
                                    <Text
                                        w={"100%"}
                                        textAlign={"left"}
                                        fontSize={"12px"}
                                        fontWeight={"400"}
                                        lineHeight={"16px"}
                                        mb={"11px"}
                                    >
                                        {type === "SCHOOL_BOARD"
                                            ? "School Board Name"
                                            : "School Name"}
                                    </Text>
                                    <InputForm
                                        register={register}
                                        name={"schoolName"}
                                        placeholder={
                                            type === "SCHOOL_BOARD"
                                                ? "Enter the School Board Name"
                                                : "Enter the school name"
                                        }
                                        height={"30px"}
                                        width={"100%"}
                                        error={
                                            errors?.schoolName?.message as
                                                | string
                                                | undefined
                                        }
                                        rest={{
                                            border: "1px solid #ACCBFF",
                                            borderColor: "#ACCBFF",
                                            borderRadius: "37px",
                                            bg: "#fff",
                                            mx: "0px",
                                            fontSize: "16px",
                                            textAlign: "left",
                                        }}
                                    />
                                </Box>
                                {isAdmin && (
                                    <Box w="50%" minHeight="75px">
                                        <Text
                                            w={"100%"}
                                            textAlign={"left"}
                                            fontSize={"12px"}
                                            fontWeight={"400"}
                                            lineHeight={"16px"}
                                            mb={"11px"}
                                        >
                                            Rellie Quote Number
                                        </Text>
                                        <InputForm
                                            register={register}
                                            name={"quoteNumber"}
                                            placeholder={"Enter quote number"}
                                            height={"30px"}
                                            width={"100%"}
                                            error={
                                                errors?.quoteNumber?.message as
                                                    | string
                                                    | undefined
                                            }
                                            rest={{
                                                border: "1px solid #ACCBFF",
                                                borderColor: "#ACCBFF",
                                                borderRadius: "37px",
                                                bg: "#fff",
                                                mx: "0px",
                                                fontSize: "16px",
                                                textAlign: "left",
                                            }}
                                        />
                                    </Box>
                                )}
                            </HStack>
                            <HStack w="100%" gap="20px">
                                <Box w="50%" minHeight="75px">
                                    <Text
                                        w={"100%"}
                                        textAlign={"left"}
                                        fontSize={"12px"}
                                        fontWeight={"400"}
                                        lineHeight={"16px"}
                                        mb={"11px"}
                                    >
                                        Administrator First Name
                                    </Text>
                                    <InputForm
                                        register={register}
                                        type={"name"}
                                        name={"adminFirstName"}
                                        placeholder={"Enter first name"}
                                        height={"30px"}
                                        width={"100%"}
                                        error={
                                            errors?.adminFirstName?.message as
                                                | string
                                                | undefined
                                        }
                                        rest={{
                                            border: "1px solid #ACCBFF",
                                            borderColor: "#ACCBFF",
                                            borderRadius: "37px",
                                            bg: "#fff",
                                            mx: "0px",
                                            fontSize: "16px",
                                            textAlign: "left",
                                        }}
                                    />
                                </Box>
                                <Box w="50%" minHeight="75px">
                                    <Text
                                        w={"100%"}
                                        textAlign={"left"}
                                        fontSize={"12px"}
                                        fontWeight={"400"}
                                        lineHeight={"16px"}
                                        mb={"11px"}
                                    >
                                        Administrator Last name
                                    </Text>
                                    <InputForm
                                        register={register}
                                        type={"name"}
                                        name={"adminLastName"}
                                        placeholder={"Enter last name"}
                                        height={"30px"}
                                        width={"100%"}
                                        error={
                                            errors?.adminLastName?.message as
                                                | string
                                                | undefined
                                        }
                                        rest={{
                                            border: "1px solid #ACCBFF",
                                            borderColor: "#ACCBFF",
                                            borderRadius: "37px",
                                            bg: "#fff",
                                            mx: "0px",
                                            fontSize: "16px",
                                            textAlign: "left",
                                        }}
                                    />
                                </Box>
                            </HStack>
                            <HStack w="100%" gap="20px">
                                <Box w="50%" minHeight="75px">
                                    <Text
                                        w={"100%"}
                                        textAlign={"left"}
                                        fontSize={"12px"}
                                        fontWeight={"400"}
                                        lineHeight={"16px"}
                                        mb={"11px"}
                                    >
                                        Number of Licenses
                                    </Text>
                                    <InputForm
                                        register={register}
                                        type={"number"}
                                        name={"numberOfLicenses"}
                                        placeholder={"500"}
                                        height={"30px"}
                                        width={"100%"}
                                        error={
                                            errors?.numberOfLicenses
                                                ?.message as string | undefined
                                        }
                                        rest={{
                                            border: "1px solid #ACCBFF",
                                            borderColor: "#ACCBFF",
                                            borderRadius: "37px",
                                            bg: "#fff",
                                            mx: "0px",
                                            fontSize: "16px",
                                            textAlign: "left",
                                        }}
                                    />
                                    <Text
                                        w={"100%"}
                                        textAlign={"right"}
                                        fontSize={"10px"}
                                        fontWeight={"400"}
                                        lineHeight={"16px"}
                                    >
                                        Licenses available: 20,000
                                    </Text>
                                </Box>
                                <Box w="50%" minHeight="75px">
                                    <Text
                                        w={"100%"}
                                        textAlign={"left"}
                                        fontSize={"12px"}
                                        fontWeight={"400"}
                                        lineHeight={"16px"}
                                        mb={"11px"}
                                    >
                                        Administrator Email
                                    </Text>
                                    <InputForm
                                        register={register}
                                        type={"email"}
                                        name={"administratorEmail"}
                                        placeholder={"Enter email"}
                                        height={"30px"}
                                        width={"100%"}
                                        error={
                                            errors?.administratorEmail
                                                ?.message as string | undefined
                                        }
                                        rest={{
                                            border: "1px solid #ACCBFF",
                                            borderColor: "#ACCBFF",
                                            borderRadius: "37px",
                                            bg: "#fff",
                                            mx: "0px",
                                            fontSize: "16px",
                                            textAlign: "left",
                                        }}
                                    />
                                </Box>
                            </HStack>
                        </VStack>
                    </ModalBody>
                    <ModalFooter justifyContent="center" gap={"20px"}>
                        <Button
                            width={"115px"}
                            borderRadius={"37px"}
                            variant="outline"
                            border={"1px solid #ACCBFF"}
                            color={"black"}
                            onClick={() => {
                                onClose();
                            }}
                            fontSize={"14px"}
                        >
                            <Text>Cancel</Text>
                        </Button>
                        <Button
                            width={"115px"}
                            bg={"#136AFF"}
                            borderRadius={"37px"}
                            color={"white"}
                            type={"submit"}
                            fontSize={"14px"}
                        >
                            {defaultValues ? "Save" : "Add"} Recipient
                        </Button>
                    </ModalFooter>
                </chakra.form>
            </ModalContent>
        </Modal>
    );
};
