import React, { FunctionComponent, useState } from "react";
import {
    Text,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Button,
    chakra,
    Heading,
    Icon,
    HStack,
} from "@chakra-ui/react";
import { InputForm } from "../common/InputForm";
import { useForm } from "react-hook-form";
import { PiShare } from "react-icons/pi";
import { RiParentLine } from "react-icons/ri";
import { ProgressButton } from "../common/ProgressButton";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

type Props = {
    studentName: string;
    ParentEmail: string;
    ParentFirstName: string;
    ParentLastName: string;
    onSubmit: any;
};

const schema = z.object({
    ParentEmail: z.string().email(),
    ParentFirstName: z.string().nonempty(),
    ParentLastName: z.string().nonempty(),
});

export const ShareReportWithParentModal: FunctionComponent<Props> = ({
    studentName,
    ParentEmail,
    ParentFirstName,
    ParentLastName,
    onSubmit,
}) => {
    const {
        register,
        formState: { errors },
        handleSubmit,
        getValues,
        reset,
    } = useForm({
        resolver: zodResolver(schema),
        shouldUnregister: false,
        defaultValues: {
            ParentEmail: ParentEmail || undefined,
            ParentFirstName: ParentFirstName || undefined,
            ParentLastName: ParentLastName || undefined,
        },
    });
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [step, setStep] = useState(1);

    const onConfirm = async (data) => {
        if (step === 1) {
            return setStep(2);
        }
        await onSubmit(data);
        onClose();
    };

    return (
        <>
            <Button
                ml={"20px"}
                color={"white"}
                borderRadius={"60px"}
                w={"140px"}
                h={"24px"}
                bg={"#136AFF"}
                leftIcon={<PiShare />}
                fontSize={"12px"}
                onClick={() => {
                    reset();
                    setStep(1);
                    onOpen();
                }}
            >
                Share with Parent
            </Button>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent p={5} maxW="640px" alignItems={"center"}>
                    <ModalHeader mt="14px" textAlign={"center"}>
                        <Icon
                            fontSize={"46px"}
                            as={RiParentLine}
                            color={"#136AFF"}
                        />
                        <Heading
                            fontStyle="normal"
                            fontWeight="700"
                            fontSize="26px"
                            lineHeight="20px"
                            color="#032E59"
                        >
                            Share report with parent?
                        </Heading>
                        <Text
                            fontFamily="Nunito"
                            fontStyle="normal"
                            fontWeight="400"
                            fontSize="16px"
                            lineHeight="24px"
                            mt={"22px"}
                        >
                            {step === 1 ? (
                                <>
                                    {(ParentEmail && (
                                        <>
                                            Please verify the parent name and
                                            email address and hit Share.
                                        </>
                                    )) || (
                                        <>
                                            Please enter the parent name and
                                            email address and hit Share
                                        </>
                                    )}
                                </>
                            ) : (
                                <>
                                    You are about to send the student report for{" "}
                                    {studentName} to the parent email &nbsp;
                                    <b>
                                        ({getValues("ParentFirstName")}{" "}
                                        {getValues("ParentLastName")}){" "}
                                        {getValues("ParentEmail")}
                                    </b>
                                    .
                                </>
                            )}
                        </Text>
                    </ModalHeader>
                    <chakra.form>
                        <ModalBody
                            color={"black"}
                            display={"flex"}
                            flexDirection={"column"}
                            alignItems={"center"}
                            textAlign={"center"}
                            gap={"22px"}
                            maxW="450px"
                        >
                            {step === 1 && (
                                <>
                                    <HStack>
                                        <InputForm
                                            register={register}
                                            type={"name"}
                                            name={"ParentFirstName"}
                                            placeholder={"Parent First Name"}
                                            height={"30px"}
                                            error={
                                                errors?.ParentFirstName
                                                    ?.message as
                                                    | string
                                                    | undefined
                                            }
                                            rest={{
                                                maxWidth: "340px",
                                                border: "1px solid #ACCBFF",
                                                borderColor: "#ACCBFF",
                                                borderRadius: "37px",
                                                bg: "rgba(172, 203, 255, 0.20)",
                                                mx: "0px",
                                                fontSize: "14px",
                                                textAlign: "left",
                                            }}
                                        />
                                        <InputForm
                                            register={register}
                                            type={"name"}
                                            name={"ParentLastName"}
                                            placeholder={"Parent Last Name"}
                                            height={"30px"}
                                            error={
                                                errors?.ParentLastName
                                                    ?.message as
                                                    | string
                                                    | undefined
                                            }
                                            rest={{
                                                maxWidth: "340px",
                                                border: "1px solid #ACCBFF",
                                                borderColor: "#ACCBFF",
                                                borderRadius: "37px",
                                                bg: "rgba(172, 203, 255, 0.20)",
                                                mx: "0px",
                                                fontSize: "14px",
                                                textAlign: "left",
                                            }}
                                        />
                                    </HStack>
                                    <InputForm
                                        register={register}
                                        type={"name"}
                                        name={"ParentEmail"}
                                        placeholder={"Parent Email"}
                                        height={"30px"}
                                        error={
                                            errors?.ParentEmail?.message as
                                                | string
                                                | undefined
                                        }
                                        rest={{
                                            minWidth: "356px",
                                            border: "1px solid #ACCBFF",
                                            borderColor: "#ACCBFF",
                                            borderRadius: "37px",
                                            bg: "rgba(172, 203, 255, 0.20)",
                                            mx: "0px",
                                            fontSize: "14px",
                                            textAlign: "left",
                                        }}
                                    />
                                </>
                            )}
                            <Text>
                                <strong>
                                    {step === 1 ? (
                                        <>
                                            {(ParentEmail && (
                                                <>
                                                    This action cannot be
                                                    undone.
                                                </>
                                            )) || (
                                                <>
                                                    This action cannot be undone
                                                    and the parent email will be
                                                    entered into the profile for
                                                    this student.
                                                </>
                                            )}
                                        </>
                                    ) : (
                                        <>This action cannot be undone.</>
                                    )}
                                </strong>
                            </Text>
                            <Text>
                                Click{" "}
                                <Text
                                    as={"span"}
                                    fontWeight={700}
                                    color={"#0051DB"}
                                >
                                    {step === 1 ? <>Share</> : <>Confirm</>}
                                </Text>{" "}
                                to send report via email or
                                <strong> Cancel </strong>
                                to return.
                            </Text>
                        </ModalBody>
                        <ModalFooter justifyContent="center">
                            <Button
                                w="100px"
                                h="30px"
                                bg="#fff"
                                border="1px solid #ACCBFF"
                                borderRadius="37px"
                                color="#032E59"
                                fontSize="14px"
                                fontWeight="400"
                                lineHeight="16px"
                                mr="10px"
                                onClick={onClose}
                            >
                                Cancel
                            </Button>

                            {(step === 1 && (
                                <Button
                                    w="100px"
                                    h="30px"
                                    bg="#136AFF"
                                    borderRadius="37px"
                                    color="#fff"
                                    fontSize="14px"
                                    fontWeight="400"
                                    lineHeight="16px"
                                    onClick={handleSubmit(onConfirm)}
                                >
                                    Share
                                </Button>
                            )) || (
                                <ProgressButton
                                    getTag={({ onClick }) => (
                                        <Button
                                            w="100px"
                                            h="30px"
                                            bg="#136AFF"
                                            borderRadius="37px"
                                            color="#fff"
                                            fontSize="14px"
                                            fontWeight="400"
                                            lineHeight="16px"
                                            onClick={onClick}
                                        >
                                            Confirm
                                        </Button>
                                    )}
                                    onClick={async ({ resetButton }) => {
                                        await handleSubmit(onConfirm)();

                                        resetButton();
                                    }}
                                />
                            )}
                        </ModalFooter>
                    </chakra.form>
                </ModalContent>
            </Modal>
        </>
    );
};
