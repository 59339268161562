
import {
    Box,
    Button,
    HStack,
    Menu,
    MenuButton,
    MenuItemOption,
    MenuList,
} from "@chakra-ui/react";
import React from "react";
import { isAdminToolsEnabled } from './App';

export function AdminFillFormButton ({ onClick }) {

    if (!isAdminToolsEnabled()) {
        return null;
    }

    return (
        <Button
            size="xs"
            color="black"
            backgroundColor={"red"}
            onClick={onClick}
        >
            Autofill
        </Button>
    );
}
