import { LinkedStudents } from "../../components/profile/LinkedStudents";
import { Permissions } from "../../components/profile/Permissions";
import React, { FC, useEffect, useState } from "react";
import type { RouteComponentProps } from "@reach/router";
import { Grid, Text, VStack } from "@chakra-ui/react";
import { Searchbar } from "../../components/common/Searchbar";
import { useApp } from "../../../lib/app/App";
import { ProfileHeader } from "../../components/profile/ProfileHeader";
import { Loading } from "../../components/common/Loading";
import { YourInfo } from "../../components/profile/YourInfo";

export const ParentProfilePage: FC<RouteComponentProps> = () => {
    const { useData, useSave, api } = useApp();
    const userData = useData("User");

    const profilePermissionsData = useData("Parent.ProfilePermissions");

    // const updateUserData = useSave("User");
    const [name, setName] = useState("");
    const [language, setLanguage] = useState("English");

    const studentsData = useData("Parent.Students");

    useEffect(() => {
        if (userData.isFetched && userData.data?.user) {
            setName(
                `${userData.data.user.FirstName} ${userData.data.user.LastName}`,
            );
        }
    }, [userData.isFetched, userData.data?.user]);

    const handleSubmit = (e) => {
        // e.preventDefault();
        // updateUserData.mutate({
        //     ...userData.data.user,
        //     FirstName: name.split(" ")[0],
        //     LastName: name.split(" ")[1],
        //     Language: language,
        // });
    };

    if (
        !userData.isFetched ||
        !userData.data?.user ||
        !userData.data?.login ||
        !profilePermissionsData.isFetched ||
        !profilePermissionsData.data ||
        !studentsData.isFetched ||
        !studentsData.data
    ) {
        return <Loading />;
    }

    const permissions = [];

    if (
        typeof profilePermissionsData.data["SHARE_WITH_SCHOOL"] !== "undefined"
    ) {
        permissions.push({
            name: "SHARE_WITH_SCHOOL",
            label: "Share reports with school",
            isChecked: profilePermissionsData.data["SHARE_WITH_SCHOOL"],
            onChange: async () => {
                await api.Parent.ProfilePermissions.ToggleShareWithTeacher.mutate(
                    {
                        ShareWithTeacher:
                            !profilePermissionsData.data["SHARE_WITH_SCHOOL"],
                    },
                );
                profilePermissionsData.refetch();
            },
        });
    }

    return (
        <VStack w={"100%"} spacing={"17px"}>
            <Searchbar />
            <ProfileHeader
                user={userData.data?.user}
                role={userData.data?.login?.role}
            />

            <Text
                as={"span"}
                color={"#032E59"}
                fontWeight={"700"}
                w={"100%"}
                maxW={"850px"}
                mt={"21px"}
            >
                PROFILE
            </Text>
            <Grid
                templateColumns={"repeat(1, 1fr)"}
                gap={"33.5px"}
                w={"100%"}
                maxW={"850px"}
                bg={"rgba(19, 106, 255, 0.03)"}
                padding={"14px 20px"}
                borderRadius={"20px"}
            >
                <YourInfo
                    name={name}
                    setName={setName}
                    language={language}
                    setLanguage={setLanguage}
                    email={userData.data.user.Email}
                    role={userData.data.login.role}
                />
                {(permissions.length && (
                    <Permissions permissions={permissions} />
                )) ||
                    ""}
                <LinkedStudents students={studentsData.data} />
                {/* <PaymentInformation /> */}
            </Grid>
            {/* <HStack justifyContent={"flex-end"} w={"100%"} maxW={"850px"}>
                <Button
                    border={"1px solid #ACCBFF"}
                    borderRadius={"37px"}
                    variant="ghost"
                    color={"#5F6368"}
                    fontSize={"12px"}
                    minW={"83px"}
                >
                    Cancel
                </Button>
                <Button
                    minW={"110px"}
                    fontSize={"12px"}
                    bg={"#0051DB"}
                    borderRadius={"37px"}
                    variant="solid"
                    color={"#fff"}
                    onClick={handleSubmit}
                >
                    Save changes
                </Button>
            </HStack> */}
        </VStack>
    );
};
