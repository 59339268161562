import React, { FunctionComponent } from "react";
import { CircularProgress, CircularProgressLabel } from "@chakra-ui/react";

type Props = {
    value: number;
    label?: string;
    children?: React.ReactNode;
    color?: string;
};

export const CircleProgress: FunctionComponent<Props> = ({
    value,
    label,
    children,
    color = "#1CCF8C",
}) => {
    return (
        <CircularProgress
            value={value}
            color={color}
            size={{ base: "58px", lg: "100px" } as unknown as string}
            capIsRound
            trackColor="rgba(0, 0, 0, 0.1)"
        >
            <CircularProgressLabel
                color={"#8A8A8A"}
                fontSize={{ base: "14px", lg: "20px" }}
                fontWeight={"300px"}
                display={"flex"}
                alignItems="center"
                justifyContent="center"
            >
                {children ? children : label}
            </CircularProgressLabel>
        </CircularProgress>
    );
};
