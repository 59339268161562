import React, { FC } from "react";
import { Box, Button, Center, Heading, Text, VStack } from "@chakra-ui/react";

import { RouteComponentProps, navigate } from "@reach/router";
import { IoWarning } from "react-icons/io5";

const NotFoundPage:FC<RouteComponentProps> = () => {
    const handleGoHome = () => {
        navigate("/");
    };
    return (
        <Center h="100vh" bg="gray.50">
        <VStack
            bg={"white"}
            w={"100%"}
            minH={"385px"}
            maxW={"471px"}
            p={"30px"}
            boxShadow={"lg"}
            borderRadius={"xl"}
            spacing={"20px"}
            align="center"
            justify="center"
        >
            <Box as={IoWarning} boxSize={20} color="red.500" />
            <Heading
                textAlign={"center"}
                w={"100%"}
                fontSize={"2xl"}
                lineHeight={"1.2"}
            >
                Page Not Found
            </Heading>

            <Text
                fontSize={"lg"}
                color="gray.700"
                fontWeight={"medium"}
                px={14}
                textAlign={"center"}
            >
                Sorry, we couldn't find the page you're looking for.

            </Text>

            <Button
                w="170px"
                h="40px"
                bg="#136AFF"
                border="1px solid #ACCBFF"
                borderRadius="37px"
                color="#fff"
                fontSize="14px"
                fontWeight="400"
                lineHeight="16px"
                onClick={handleGoHome}
            >
                Home
            </Button>
        </VStack>
    </Center>
    );
};

export default NotFoundPage;
