import { Button, FlexProps, Icon, Link } from "@chakra-ui/react";
import { IconType } from "react-icons";
import React, { ReactNode } from "react";
import { Link as ReachLink } from "@reach/router";

interface NavItemProps extends FlexProps {
    icon: IconType;
    children: ReactNode;
    to: string;
    onClick?: () => void;
}

export const NavItem = ({ icon, children, to, onClick }: NavItemProps) => {
    if (onClick) {
        return (
            <Button
                variant={"link"}
                onClick={onClick}
                display="flex"
                alignItems="center"
                padding="10px"
                borderRadius="30px 0px 0px 30px"
                textDecoration="none"
                marginLeft="20px"
                color={"white"}
                fontWeight={"400"}
            >
                {icon && (
                    <Icon
                        mr="4"
                        fontSize="16"
                        _groupHover={{
                            color: "white",
                        }}
                        color={"#ACCBFF"}
                        as={icon}
                    />
                )}
                {children}
            </Button>
        );
    }

    return (
        <Link
            to={to}
            as={ReachLink}
            getProps={({ isCurrent }) => {
                return {
                    style: {
                        display: "flex",
                        alignItems: "center",
                        background: isCurrent ? "rgba(0, 0, 0, 0.29)" : "none",
                        padding: "10px",
                        borderRadius: "30px 0px 0px 30px",
                        marginLeft: "20px",
                        borderRight: isCurrent ? "5px solid #FF4DD1" : "none",
                        cursor: "pointer",
                        _hover: {
                            background: "rgba(0, 0, 0, 0.29)",
                        },
                        _focus: {
                            boxShadow: "none",
                        },
                    },
                };
            }}
        >
            {icon && (
                <Icon
                    mr="4"
                    fontSize="16"
                    _groupHover={{
                        color: "white",
                    }}
                    color={"#ACCBFF"}
                    as={icon}
                />
            )}
            {children}
        </Link>
    );
};
