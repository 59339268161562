import {
    Card,
    CardBody,
    GridItem,
    Heading,
    Box,
    Icon,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Tr,
    VStack,
    Spacer,
} from "@chakra-ui/react";
import _ from "lodash";
import React, { FunctionComponent } from "react";
import { IoPerson } from "react-icons/io5";
import { AiOutlinePlus } from "react-icons/ai";

type PermissionProps = {
    students: any;
};
export const LinkedStudents: FunctionComponent<PermissionProps> = ({students}) => {
    return (
        <GridItem>
            <VStack alignItems={"flex-start"}>
                <Heading size={"sm"} color={"#032E59"}>
                    Linked Students
                </Heading>
                <Card
                    w={"100%"}
                    maxW={"100%"}
                    bg={"#FFFFFF"}
                    borderRadius={"16px"}
                    overflow={"auto"}
                >
                    <CardBody>
                        <TableContainer>
                            <Table variant="unstyled">
                                <Tbody>
                                    {students.map((student, i) => (
                                        <Tr
                                            key={i}
                                            borderBottom={"0.5px dashed #AAAAAA"}
                                            fontSize={"10px"}
                                            color={"#5F6368"}
                                        >
                                            <Td
                                                display={"flex"}
                                                alignItems={"center"}
                                                justifyContent={"start"}
                                                borderRadius={"60px"}
                                                gap={"18px"}
                                            >
                                                <Box
                                                    bg={(i%2) ? "#D2F5E8" : "#FFE8F9"}
                                                    borderRadius={"50%"}
                                                    w={"36px"}
                                                    h={"36px"}
                                                    display={"flex"}
                                                    alignItems={"center"}
                                                    justifyContent={"center"}
                                                >
                                                    <Icon
                                                        fontSize="20"
                                                        _groupHover={{
                                                            color: "white",
                                                        }}
                                                        color={(i%2) ? "#1CCF8C" : "#FF4DD1"}
                                                        as={IoPerson}
                                                    />
                                                </Box>
                                                <Text
                                                    fontSize={"14px"}
                                                    color={"#000000"}
                                                >
                                                    {`${student.FirstName || ''} ${student.LastName || ''}`}
                                                </Text>
                                            </Td>
                                            {/* <Td
                                                w={"10%"}
                                                textAlign={"end"}
                                                color={"#032E59"}
                                            >
                                                <Text as={"button"}>Unlink</Text>
                                            </Td> */}
                                        </Tr>
                                    ))}
                                    
                                    {/* <Tr
                                        borderBottom={"0.5px dashed #AAAAAA"}
                                        fontSize={"10px"}
                                        color={"#5F6368"}
                                    >
                                        <Td
                                            display={"flex"}
                                            alignItems={"center"}
                                            justifyContent={"start"}
                                            borderRadius={"60px"}
                                            gap={"18px"}
                                        >
                                            <Box
                                                bg={"#EFF6FF"}
                                                borderRadius={"50%"}
                                                w={"36px"}
                                                h={"36px"}
                                                display={"flex"}
                                                alignItems={"center"}
                                                justifyContent={"center"}
                                                cursor={"pointer"}
                                            >
                                                <Icon
                                                    fontSize="20"
                                                    _groupHover={{
                                                        color: "white",
                                                    }}
                                                    color={"#136AFF"}
                                                    as={AiOutlinePlus}
                                                />
                                            </Box>
                                            <Text
                                                fontSize={"14px"}
                                                color={"#000000"}
                                            >
                                                Add linked user
                                            </Text>
                                        </Td>
                                        <Spacer />
                                    </Tr> */}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </CardBody>
                </Card>
            </VStack>
        </GridItem>
    );
};
