import React, { FunctionComponent, useState } from "react";
import { CognitiveArea } from "../../components/common/CognitiveArea";
import {
    Link as ReachLink,
    RouteComponentProps,
    useNavigate,
    useParams,
} from "@reach/router";
import {
    Heading,
    HStack,
    VStack,
    Text,
    Icon,
    Button,
    Badge,
    Box,
    RadioGroup,
    Stack,
    Radio    
} from "@chakra-ui/react";
import { Loading } from "../../components/common/Loading";
import { MdOutlineKeyboardBackspace } from "react-icons/md";
import { Searchbar } from "../../components/common/Searchbar";
import { useApp } from "../../../lib/app/App";
import { RellieSelect } from "../../components/common/RellieSelect";
import { StrategySelector } from "../Teacher/StrategySelector";
import { StrategyReportElement } from "../../components/StrategyReportElement";
import { isEmpty } from "lodash";
import {
    handleColorPercentage,
} from "../Teacher/TeacherClassReport";

export const ParentReportsPage: FunctionComponent<RouteComponentProps> = () => {
    const { useData } = useApp();
    const navigate = useNavigate();
    const params = useParams();

    const studentsData = useData("Parent.Students");

    async function onSelectStudent(assessmentId) {
        await navigate(
            `/parent/reports/${assessmentId}/${selectedCognitiveArea.value}`,
        );
    }

    // console.log("studentsData.data", studentsData.data);

    const selectedStudentData =
        studentsData.data?.find((student) => {
            const assessmentId = student.AssessmentId;
            return assessmentId === parseInt(params.assessmentId);
        }) ?? null;

    // TODO: Do not make this request if no student selected.
    const reportData = useData("Parent.Report", {
        Id_Assessment: selectedStudentData?.AssessmentId || -1,
    });

    // console.log("reportData.data", reportData.data);

    const allCognitiveAreas = reportData.data?.report ?? [];

    const menuItemsForCognitiveAreas = [
        {
            id: "AllStrategies",
            label: "All Strategies",
        },
        ...allCognitiveAreas,
    ]?.map((cognitiveArea) => ({
        label: cognitiveArea.label,
        value: cognitiveArea.id,
    }));

    const selectedCognitiveArea =
        allCognitiveAreas.find(
            (item) => `${item.id}` === params.selectedCognitiveArea,
        ) ?? params.selectedCognitiveArea;

    // console.log("selectedCognitiveArea", selectedCognitiveArea);

    if (!params.assessmentId && studentsData.data && studentsData.data.length) {
        navigate(
            `/parent/reports/${studentsData.data[0].AssessmentId}/FullReport`,
        );
        return null;
    }

    if (studentsData.isLoading || !studentsData.data) return <Loading />;

    const renderContent = () => {
        if (reportData.isLoading || !reportData.data) return null;

        if (!reportData.data.report.length) {
            return (
                <Text>
                    The questionnaire must be completed before a report can be
                    generated.
                </Text>
            );
        }

        if (selectedCognitiveArea === "FullReport") {
            return (
                <VStack
                    spacing={"13px"}
                    bg={"rgba(19, 106, 255, 0.03)"}
                    borderRadius={"20px"}
                    padding={"20px 15px"}
                >
                    <VStack
                        borderRadius={"20px"}
                        border={"1px solid #E1ECFF"}
                        bg={"#fff"}
                        w={"100%"}
                        padding={"19px 17px 13px 17px"}
                    >
                        <Heading
                            size="md"
                            color={"#032E59"}
                            display={"flex"}
                            alignItems={"center"}
                            w={"100%"}
                            fontSize={"16px"}
                        >
                            Interests
                        </Heading>

                        <HStack
                            spacing={"10px"}
                            alignItems={"flex-start"}
                            alignSelf={"flex-start"}
                        >
                            {reportData.data.interests.map((interest, i) => (
                                <Badge
                                    key={i}
                                    borderRadius={"40px"}
                                    variant="outline"
                                    colorScheme="brand.blue300"
                                    p={"2px 16px"}
                                    color={"black"}
                                    lineHeight={"20px"}
                                >
                                    {interest.AnswerText}
                                </Badge>
                            ))}
                        </HStack>
                    </VStack>

                    {reportData.data.report.map((cognitiveArea) => {
                        return (
                            <CognitiveArea
                                key={cognitiveArea.id}
                                cognitiveArea={cognitiveArea}
                                description={
                                    cognitiveArea.firstStrtegy?.description ||
                                    ""
                                }
                                skills={cognitiveArea.skills}
                                colorScheme={cognitiveArea.colorScheme}
                                onStrategyViewMore={(cognitiveAreaId) => {
                                    navigate(
                                        `/parent/reports/${params.assessmentId}/${cognitiveAreaId}`,
                                    );
                                }}
                            />
                        );
                    })}
                </VStack>
            );
        }

        return (
            <VStack spacing={"17px"} w={"100%"}>
                <HStack width={"100%"} justifyContent={"space-between"}>
                    <Heading
                        marginTop={"28px"}
                        marginBottom={"22px"}
                        fontSize={"20px"}
                        color={"#032E59"}
                        alignSelf={"flex-start"}
                    >
                        Strategies for success
                    </Heading>
                    <Button
                        as={ReachLink}
                        to={`/parent/reports/${selectedStudentData?.AssessmentId}/FullReport`}
                        leftIcon={
                            <Icon
                                color={"#136AFF"}
                                as={MdOutlineKeyboardBackspace}
                            />
                        }
                        color={"#032E59"}
                        bg={"#FFFFFF"}
                        height={"22px"}
                        borderRadius={"60px"}
                        fontSize={"10px"}
                    >
                        Back to Report
                    </Button>
                </HStack>
                <StrategySelector
                    isCognitiveAreaActive={(cognitiveAreaId) => {
                        return (
                            `${cognitiveAreaId}` ===
                            params.selectedCognitiveArea
                        );
                    }}
                    isViewAllActive={
                        `AllStrategies` === params.selectedCognitiveArea
                    }
                    shouldShowViewAll={true}
                    cognitiveAreas={reportData.data.report}
                    onViewAllClick={() => {
                        navigate(
                            `/parent/reports/${selectedStudentData?.AssessmentId}/AllStrategies`,
                        );
                    }}
                    onCognitiveAreaClick={(id) => {
                        navigate(
                            `/parent/reports/${selectedStudentData?.AssessmentId}/${id}`,
                        );
                    }}
                />

                {selectedCognitiveArea === "AllStrategies" ? (
                    <>
                        {reportData.data.report.map((cognitiveArea) => {
                            return (
                                <VStack
                                    key={cognitiveArea.id}
                                    spacing={"13px"}
                                    w={"100%"}
                                    p={"30px"}
                                    maxW={"100%"}
                                >
                                    <StrategyReportElement
                                        cognitiveId={cognitiveArea.id}
                                        strategies={cognitiveArea.strategies}
                                        title={cognitiveArea.label}
                                        percentage={cognitiveArea.percentage}
                                        colorScheme={handleColorPercentage(cognitiveArea.percentage, true)}
                                    />
                                </VStack>
                            );
                        })}
                    </>
                ) : (
                    <VStack
                        spacing={"13px"}
                        w={"100%"}
                        p={"30px"}
                        maxW={"100%"}
                    >
                        <StrategyReportElement
                            cognitiveId={selectedCognitiveArea.id}
                            strategies={selectedCognitiveArea.strategies}
                            title={selectedCognitiveArea.label}
                            percentage={selectedCognitiveArea.percentage}
                            colorScheme={handleColorPercentage(selectedCognitiveArea.percentage, true)}
                        />
                    </VStack>
                )}
            </VStack>
        );
    };

    return (
        <>
            <VStack alignItems={"start"}>
                <Searchbar />
                <HStack spacing={"48px"} w={"100%"}>
                    <RellieSelect
                        value={selectedStudentData?.AssessmentId}
                        placeholder={"SELECT CHILD"}
                        onChangeHandler={onSelectStudent}
                        options={studentsData.data.map((student) => {
                            return {
                                label: student.FirstName,
                                value: student.AssessmentId,
                            };
                        })}
                    />

                    {reportData.data?.report.length &&
                        selectedCognitiveArea === "FullReport" && (
                            <RellieSelect
                                value={selectedCognitiveArea.value}
                                placeholder={"STRATEGIES"}
                                onChangeHandler={async (cognitiveArea) => {
                                    await navigate(
                                        `/parent/reports/${selectedStudentData.AssessmentId}/${cognitiveArea}`,
                                    );
                                }}
                                options={menuItemsForCognitiveAreas}
                            />
                        )}
                </HStack>

                {renderContent()}
                {/* {!isEmpty(reportData.data?.report) && (
                    <StrategySelector
                        isCognitiveAreaActive={(cognitiveAreaId) => {
                            return (
                                `${cognitiveAreaId}` ===
                                params.selectedCognitiveArea
                            );
                        }}
                        isViewAllActive={
                            `AllStrategies` === params.selectedCognitiveArea
                        }
                        shouldShowViewAll={true}
                        cognitiveAreas={reportData.data.report}
                        onViewAllClick={() => {
                            navigate(
                                `/parent/reports/${selectedStudentData?.AssessmentId}/AllStrategies`,
                            );
                        }}
                        onCognitiveAreaClick={(id) => {
                            navigate(
                                `/parent/reports/${selectedStudentData?.AssessmentId}/${id}`,
                            );
                        }}
                    />
                )} */}
            </VStack>
        </>
    );
};
