import React, { FunctionComponent } from "react";
import {
    HStack,
    VStack,
    Box,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Button,
    chakra,
} from "@chakra-ui/react";
import * as z from "zod";
import { InputForm } from "../common/InputForm";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { EntitlementType } from "../../pages/Admin/Entitlements";
import { DropzoneComponent } from "../common/DropzoneComponent";

// infer the zod schema from EntitlementType
const EntitlementTypeSchema = z.object({
    quoteNumber: z.string(),
    clientType: z.string(),
    clientName: z.string(),
    licenses: z.number(),
    contractTerm: z.string(),
    startDate: z.string(),
    endDate: z.string(),
    daysRemaining: z.number(),
    quoteDownloaded: z.boolean(),
});

interface AddAdminModalProps {
    onAddCallback: Function;
    defaultValues?: EntitlementType;
    isOpen: boolean;
    onClose: () => void;
}

export const AddEntitlementModal: FunctionComponent<AddAdminModalProps> = ({
    onAddCallback,
    defaultValues,
    isOpen,
    onClose,
}) => {
    const {
        register,
        formState: { errors },
        handleSubmit,
        control,
    } = useForm<EntitlementType>({
        defaultValues,
        resolver: zodResolver(EntitlementTypeSchema),
        shouldUnregister: false,
    });

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent maxW="724px">
                <ModalHeader
                    mt="14px"
                    fontFamily="Nunito"
                    fontStyle="normal"
                    fontWeight="700"
                    fontSize="16px"
                    lineHeight="20px"
                    color="#032E59"
                >
                    Add Entitlement
                    <Text
                        fontFamily="Nunito"
                        fontStyle="normal"
                        fontWeight="400"
                        fontSize="14px"
                        lineHeight="24px"
                    >
                        Enter the information below.
                    </Text>
                </ModalHeader>
                <chakra.form
                    onSubmit={handleSubmit((data) => {
                        onAddCallback(data);
                        onClose();
                    })}
                >
                    <ModalBody color="black">
                        <VStack w="100%">
                            <HStack w="100%" gap="20px"></HStack>
                            <HStack w="100%" gap="20px">
                                <Box w="50%" minHeight="75px">
                                    <Text
                                        w={"100%"}
                                        textAlign={"left"}
                                        fontSize={"12px"}
                                        fontWeight={"400"}
                                        lineHeight={"16px"}
                                        mb={"11px"}
                                    >
                                        Client Type
                                    </Text>
                                    <InputForm
                                        register={register}
                                        type={"name"}
                                        name={"clientType"}
                                        placeholder={"Client type"}
                                        height={"30px"}
                                        width={"100%"}
                                        error={
                                            errors?.clientType?.message as
                                                | string
                                                | undefined
                                        }
                                        rest={{
                                            border: "1px solid #ACCBFF",
                                            borderColor: "#ACCBFF",
                                            borderRadius: "37px",
                                            bg: "#fff",
                                            mx: "0px",
                                            fontSize: "14px",
                                            textAlign: "left",
                                        }}
                                    />
                                </Box>
                                <Box w="50%" minHeight="75px">
                                    <Text
                                        w={"100%"}
                                        textAlign={"left"}
                                        fontSize={"12px"}
                                        fontWeight={"400"}
                                        lineHeight={"16px"}
                                        mb={"11px"}
                                    >
                                        Contract Term
                                    </Text>
                                    <InputForm
                                        register={register}
                                        name={"contractTerm"}
                                        placeholder={"Enter contract term"}
                                        height={"30px"}
                                        width={"100%"}
                                        error={
                                            errors?.contractTerm?.message as
                                                | string
                                                | undefined
                                        }
                                        rest={{
                                            border: "1px solid #ACCBFF",
                                            borderColor: "#ACCBFF",
                                            borderRadius: "37px",
                                            bg: "#fff",
                                            mx: "0px",
                                            fontSize: "14px",
                                            textAlign: "left",
                                        }}
                                    />
                                </Box>
                            </HStack>
                            <HStack w="100%" gap="20px">
                                <Box w="50%" minHeight="75px">
                                    <Text
                                        w={"100%"}
                                        textAlign={"left"}
                                        fontSize={"12px"}
                                        fontWeight={"400"}
                                        lineHeight={"16px"}
                                        mb={"11px"}
                                    >
                                        Client Name
                                    </Text>
                                    <InputForm
                                        register={register}
                                        name={"clientName"}
                                        placeholder={"Enter client name"}
                                        height={"30px"}
                                        width={"100%"}
                                        error={
                                            errors?.clientName?.message as
                                                | string
                                                | undefined
                                        }
                                        rest={{
                                            border: "1px solid #ACCBFF",
                                            borderColor: "#ACCBFF",
                                            borderRadius: "37px",
                                            bg: "#fff",
                                            mx: "0px",
                                            fontSize: "14px",
                                            textAlign: "left",
                                        }}
                                    />
                                </Box>
                                <Box w="50%" minHeight="75px">
                                    <Text
                                        w={"100%"}
                                        textAlign={"left"}
                                        fontSize={"12px"}
                                        fontWeight={"400"}
                                        lineHeight={"16px"}
                                        mb={"11px"}
                                    >
                                        Licenses
                                    </Text>
                                    <InputForm
                                        register={register}
                                        name={"licenses"}
                                        placeholder={"Enter licenses"}
                                        height={"30px"}
                                        width={"100%"}
                                        error={
                                            errors?.licenses?.message as
                                                | string
                                                | undefined
                                        }
                                        rest={{
                                            border: "1px solid #ACCBFF",
                                            borderColor: "#ACCBFF",
                                            borderRadius: "37px",
                                            bg: "#fff",
                                            mx: "0px",
                                            fontSize: "14px",
                                            textAlign: "left",
                                        }}
                                    />
                                </Box>
                            </HStack>
                            <HStack w="100%" gap="20px">
                                <Box w="50%" minHeight="75px">
                                    <Text
                                        w={"100%"}
                                        textAlign={"left"}
                                        fontSize={"12px"}
                                        fontWeight={"400"}
                                        lineHeight={"16px"}
                                        mb={"11px"}
                                    >
                                        Start Date
                                    </Text>
                                    <InputForm
                                        type={"date"}
                                        register={register}
                                        name={"startDate"}
                                        placeholder={"Enter start date"}
                                        height={"30px"}
                                        width={"100%"}
                                        error={
                                            errors?.startDate?.message as
                                                | string
                                                | undefined
                                        }
                                        rest={{
                                            border: "1px solid #ACCBFF",
                                            borderColor: "#ACCBFF",
                                            borderRadius: "37px",
                                            bg: "#fff",
                                            mx: "0px",
                                            fontSize: "14px",
                                            textAlign: "left",
                                        }}
                                    />
                                </Box>

                                <Box w="50%" minHeight="75px">
                                    <Text
                                        w={"100%"}
                                        textAlign={"left"}
                                        fontSize={"12px"}
                                        fontWeight={"400"}
                                        lineHeight={"16px"}
                                        mb={"11px"}
                                    >
                                        End Date
                                    </Text>
                                    <InputForm
                                        type={"date"}
                                        register={register}
                                        name={"endDate"}
                                        placeholder={"Enter end date"}
                                        height={"30px"}
                                        width={"100%"}
                                        error={
                                            errors?.endDate?.message as
                                                | string
                                                | undefined
                                        }
                                        rest={{
                                            border: "1px solid #ACCBFF",
                                            borderColor: "#ACCBFF",
                                            borderRadius: "37px",
                                            bg: "#fff",
                                            mx: "0px",
                                            fontSize: "14px",
                                            textAlign: "left",
                                        }}
                                    />
                                </Box>
                            </HStack>
                            <VStack>
                                <Text
                                    w={"100%"}
                                    textAlign={"left"}
                                    fontSize={"12px"}
                                    fontWeight={"400"}
                                    lineHeight={"16px"}
                                    mb={"11px"}
                                >
                                    Rellie Quote
                                </Text>
                                <DropzoneComponent
                                    control={control}
                                    name={"quoteNumber"}
                                />
                            </VStack>
                        </VStack>
                    </ModalBody>
                    <ModalFooter justifyContent="center" gap={"20px"}>
                        <Button
                            width={"115px"}
                            borderRadius={"37px"}
                            variant="outline"
                            border={"1px solid #ACCBFF"}
                            color={"black"}
                            onClick={onClose}
                            fontSize={"14px"}
                        >
                            <Text>Cancel</Text>
                        </Button>
                        <Button
                            width={"115px"}
                            bg={"#136AFF"}
                            borderRadius={"37px"}
                            color={"white"}
                            type={"submit"}
                            fontSize={"14px"}
                        >
                            Send Invite
                        </Button>
                    </ModalFooter>
                </chakra.form>
            </ModalContent>
        </Modal>
    );
};
