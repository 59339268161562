import React, { FunctionComponent } from "react";
import { Badge, Button, HStack, Text, VStack, Box } from "@chakra-ui/react";
import { CircleProgress } from "../../components/common/CircleProgress";

import { handleColorPercentage, handleTextPercentage } from "./TeacherClassReport";
type Props = {
    cognitiveAreas: {
        id?: number;
        Id?: number;
        label: string;
        percentage?: string;
    }[];
    onCognitiveAreaClick: (cognitiveAreaId: number) => void;
    shouldShowViewAll?: boolean;
    onViewAllClick?: () => void;
    isViewAllActive?: boolean;
    isCognitiveAreaActive: (cognitiveAreaId: number) => boolean;
};

export const StrategySelector: FunctionComponent<Props> = ({
    cognitiveAreas,
    shouldShowViewAll = false,
    onViewAllClick,
    onCognitiveAreaClick,
    isViewAllActive,
    isShowPercentage = false,
    isCognitiveAreaActive,
}) => {
    return (
        <HStack maxW={"100%"} flexWrap={"wrap"} rowGap={"10px"}>
            {cognitiveAreas.map((cognitiveArea, i) => {
                const cognitiveAreaId = cognitiveArea.Id ?? cognitiveArea.id;
                const isActive = isCognitiveAreaActive(cognitiveAreaId);

                cognitiveArea.percentage = Math.round(cognitiveArea.percentage)

                return (

                    <VStack key={`${cognitiveArea.label}-${i}`}>
                        <Button
                            px={"9px"}
                            bg={"#fff"}
                            color={"#032E59"}
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"space-between"}
                            gap={"10px"}
                            borderRadius={"40px"}
                            fontSize={"12px"}
                            border={isActive ? "1px solid #136AFF" : "none"}
                            onClick={() => {
                                onCognitiveAreaClick(cognitiveAreaId);
                            }}
                        >
                            <Text fontWeight={isActive ? "700" : "600"}>
                                {cognitiveArea.label}
                            </Text>
                            <Badge
                                borderRadius={"50%"}
                                color={"#032E59"}
                                w={"15px"}
                                h={"15px"}
                                bg={handleColorPercentage(
                                    cognitiveArea.percentage,
                                    false,
                                )}
                            />
                        </Button>

                        { isShowPercentage && (
                            <Box
                                bg={"#FFFFFF"}
                                // h={"141px"}
                                // w={"172px"}
                                padding={"5px"}
                                borderRadius={"16px"}
                                border={isActive ? "1px solid #136AFF" : "1px solid #E1ECFF"}
                                display={"flex"}
                                flexDirection={"column"}
                                alignItems={"center"}
                                justifyContent={"center"}
                                onClick={() => {
                                    onCognitiveAreaClick(cognitiveAreaId);
                                }}
                                sx={{
                                    ":hover": {
                                        cursor: "pointer"
                                    }
                                }}
                            >
                                <CircleProgress
                                    color={handleColorPercentage(
                                        cognitiveArea.percentage,
                                        false
                                    )}
                                    value={cognitiveArea.percentage}
                                >
                                    {`${cognitiveArea.percentage}%`}
                                </CircleProgress>
                                <Text fontSize={"8px"}>
                                    {handleTextPercentage(
                                        cognitiveArea.percentage
                                    )}
                                </Text>
                            </Box>
                        ) }

                    </VStack>
                );
            })}

            {shouldShowViewAll && (
                <Button
                    px={"9px"}
                    bg={"#fff"}
                    color={"#032E59"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    gap={"10px"}
                    borderRadius={"40px"}
                    fontSize={"12px"}
                    border={isViewAllActive ? "1px solid #136AFF" : "none"}
                    onClick={onViewAllClick}
                    minW={"87px"}
                >
                    <Text
                        w={"100%"}
                        fontWeight={isViewAllActive ? "700" : "600"}
                    >
                        View All
                    </Text>
                </Button>
            )}
        </HStack>
    );
};
