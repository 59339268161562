import React, { FunctionComponent, useState } from "react";
import {
    Box,
    Button,
    HStack,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    VStack,
    useDisclosure,
    Flex,
    Tooltip,
    Icon,
} from "@chakra-ui/react";
import { Searchbar } from "../../components/common/Searchbar";
import { RouteComponentProps } from "@reach/router";

import { BsPlusCircleFill } from "react-icons/bs";
import { DeletePopup } from "../../components/common/DeletePopup";
import { AddEntitlementModal } from "../../components/admin/AddEntitlementModal";
import { HiOutlinePencil, HiOutlineTrash } from "react-icons/hi";

export type EntitlementType = {
    quoteNumber: string;
    clientType: string;
    clientName: string;
    licenses: number;
    contractTerm: string;
    startDate: string;
    endDate: string;
    daysRemaining: number;
    quoteDownloaded: boolean;
};
export const EntitlementsPage: FunctionComponent<RouteComponentProps> = () => {
    const [isDeleteOpen, setDeleteOpen] = useState(false);
    const [selectedEntitlement, setSelectedEntitlement] =
        useState<EntitlementType>(null);
    const {
        isOpen: isOpenEditModal,
        onOpen: onOpenEditModal,
        onClose: onCloseEditModal,
    } = useDisclosure();

    const entitlements = [
        {
            quoteNumber: "RQ1234",
            clientType: "Teacher",
            clientName: "John Doe",
            licenses: 10,
            contractTerm: "1 year",
            startDate: "2022-01-01",
            endDate: "2022-12-31",
            daysRemaining: 365,
            quoteDownloaded: true,
        },
        {
            quoteNumber: "RQ5678",
            clientType: "School",
            clientName: "XYZ School",
            licenses: 50,
            contractTerm: "2 years",
            startDate: "2022-01-01",
            endDate: "2023-12-31",
            daysRemaining: 730,
            quoteDownloaded: false,
        },
    ];

    const handleClose = () => {
        setDeleteOpen(false);
    };

    const renderAddModal = () => {
        if (isOpenEditModal) {
            return (
                <AddEntitlementModal
                    isOpen={isOpenEditModal}
                    onClose={onCloseEditModal}
                    defaultValues={selectedEntitlement}
                    onAddCallback={() => {
                        //TODO implement add
                        onCloseEditModal();
                        setSelectedEntitlement(null);
                    }}
                />
            );
        }
    };

    return (
        <VStack alignItems={"flex-start"} spacing={"19px"}>
            <Searchbar />
            <Box alignSelf={"start"} color={"#032E59"} fontWeight={"700"}>
                <Text fontSize={"26px"}> Internal Rellie Administration</Text>

                <Text fontSize={"16px"}>License Management</Text>
            </Box>
            <VStack
                m={0}
                bg={"rgba(19, 106, 255, 0.03)"}
                borderRadius={"20px"}
                p={"17px"}
                w={"100%"}
                spacing={"18px"}
            >
                <HStack w={"100%"}>
                    <Text
                        fontSize={"16px"}
                        color={"#032E59"}
                        alignSelf={"start"}
                        lineHeight={"20px"}
                        fontWeight={"700"}
                    >
                        Entitlements
                    </Text>
                </HStack>
                <HStack w={"100%"} justifyContent={"space-between"}>
                    <HStack>
                        <>
                            <Button
                                bg={"#136AFF"}
                                leftIcon={
                                    <BsPlusCircleFill
                                        color={"#FFFFFF"}
                                        size="14px"
                                    />
                                }
                                color={"#FFFFFF"}
                                borderRadius={"41px"}
                                height={"22px"}
                                fontSize={"12px"}
                                sx={{
                                    "& path": {
                                        stroke: "#FFFFFF",
                                    },
                                }}
                                onClick={() => {
                                    setSelectedEntitlement(null);
                                    onOpenEditModal();
                                }}
                            >
                                Add Entitlement
                            </Button>
                            {renderAddModal()}
                        </>
                    </HStack>
                </HStack>
                <TableContainer mt={"18px"}>
                    <Table
                        variant="simple"
                        bg={"#FFFFFF"}
                        borderRadius={"16px"}
                        sx={{
                            "& th": {
                                color: "#5F6368",
                                fontSize: "10px",
                                textTransform: "capitalize",
                            },
                            "& td": {
                                fontSize: "12px",
                            },
                        }}
                    >
                        <Thead>
                            <Tr>
                                <Th>Rellie Quote #</Th>
                                <Th>Client Type</Th>
                                <Th>Client name</Th>
                                <Th>Licenses</Th>
                                <Th>Contract term</Th>
                                <Th>Start date</Th>
                                <Th>End date</Th>
                                <Th>Days remanining until renewal</Th>
                                <Th>Quote downloaded</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {entitlements.map((entitlement) => (
                                <Tr key={entitlement.quoteNumber}>
                                    <Td>{entitlement.quoteNumber}</Td>
                                    <Td>{entitlement.clientType}</Td>
                                    <Td>{entitlement.clientName}</Td>
                                    <Td>{entitlement.licenses}</Td>
                                    <Td>{entitlement.contractTerm}</Td>
                                    <Td>{entitlement.startDate}</Td>
                                    <Td>{entitlement.endDate}</Td>
                                    <Td>{entitlement.daysRemaining}</Td>
                                    <Td>
                                        {entitlement.quoteDownloaded
                                            ? "Yes"
                                            : "No"}
                                    </Td>
                                    <Td>
                                        <Flex placeItems={"center"} gap={3}>
                                            <Tooltip
                                                label="Edit"
                                                aria-label="Edit"
                                                bg={"#F1F3F4"}
                                                borderRadius={"3px"}
                                                fontSize={"10px"}
                                                color={"black"}
                                                placement={"top-end"}
                                            >
                                                <span>
                                                    <Icon
                                                        ml={"5px"}
                                                        width={"10px"}
                                                        height={"10px"}
                                                        size={"10px"}
                                                        stroke={"#0051DB"}
                                                        borderColor={"#0051DB"}
                                                        as={HiOutlinePencil}
                                                        cursor={"pointer"}
                                                        onClick={() => {
                                                            onOpenEditModal();
                                                            setSelectedEntitlement(
                                                                entitlement,
                                                            );
                                                        }}
                                                    />
                                                </span>
                                            </Tooltip>

                                            <Tooltip
                                                label="Delete"
                                                aria-label="Delete"
                                                bg={"#F1F3F4"}
                                                borderRadius={"3px"}
                                                fontSize={"10px"}
                                                color={"black"}
                                                placement={"top-end"}
                                            >
                                                <span>
                                                    <Icon
                                                        ml={"5px"}
                                                        width={"10px"}
                                                        height={"10px"}
                                                        size={"10px"}
                                                        stroke={"#0051DB"}
                                                        borderColor={"#0051DB"}
                                                        as={HiOutlineTrash}
                                                        cursor={"pointer"}
                                                        onClick={() => {
                                                            setDeleteOpen(true);
                                                            setSelectedEntitlement(
                                                                entitlement,
                                                            );
                                                        }}
                                                    />
                                                </span>
                                            </Tooltip>
                                        </Flex>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </VStack>

            {selectedEntitlement && (
                <DeletePopup
                    isOpen={isDeleteOpen}
                    onClose={handleClose}
                    onDelete={() => {
                        //TODO implement delete
                        handleClose();
                    }}
                    confirmationText={`Are you sure you want to delete entitlement ${selectedEntitlement.clientName}?`}
                />
            )}
        </VStack>
    );
};
