import React, { ReactNode, useEffect } from "react";
import { RouteComponentProps, useLocation } from "@reach/router";
import {
    Box,
    useColorModeValue,
    Drawer,
    DrawerContent,
    useDisclosure,
} from "@chakra-ui/react";
import { Sidebar } from "./Sidebar";
import { MobileNav } from "./MobileNav";
import { FiTrendingUp } from "react-icons/fi";
import { BsPerson, BsBoxArrowLeft } from "react-icons/bs";
import { MdOutlineDashboard } from "react-icons/md";
import { TbAward } from "react-icons/tb";

import { onLogout, useApp } from "../../../lib/app/App";

type DashboardLayoutProps = {
    children: ReactNode;
    typeLinks:
        | "sidebarAdminLinks"
        | "sidebarTeacherLinks"
        | "sidebarParentLinks"
        | "sidebarGovernmentLinks"
        | "sidebarStudentLinks"
        | "sidebarSchoolLinks";
};

const sidebarAdminLinks = [
    { name: "Dashboard", icon: MdOutlineDashboard, url: "dashboard" },
    { name: "Licenses", icon: TbAward, url: "licenses" },
    { name: "Entitlements/Orders", icon: TbAward, url: "entitlements" },
    { name: "Admin Users", icon: TbAward, url: "users/admin" },
    { name: "Data - Editor", icon: TbAward, url: "data/editor" },
    { name: "QA - Reports & Exports", icon: TbAward, url: "qa/reports-exports" },
    { name: "QA - Answers Scoring", icon: TbAward, url: "qa/answers-scoring" },
    { name: "QA - Assessment", icon: TbAward, url: "qa/assessment" },
    { name: "Errors", icon: TbAward, url: "errors" },
    { name: "Tools", icon: TbAward, url: "tools" },
    {
        name: "Preview - SchoolBoard",
        icon: TbAward,
        url: "/schoolboard/dashboard",
    },
    {
        name: "Preview - School",
        icon: TbAward,
        url: "/school/dashboard",
    },
    {
        name: "Preview - Government",
        icon: TbAward,
        url: "/government/dashboard",
    },
    { name: "Profile", icon: TbAward, url: "profile" },
    {
        name: "Logout",
        icon: BsBoxArrowLeft,
        onClick: onLogout,
    },
];

const sidebarTeacherLinks = [
    { name: "Dashboard", icon: MdOutlineDashboard, url: "dashboard" },
    { name: "Reports", icon: FiTrendingUp, url: "reports" },
    { name: "Licenses", icon: TbAward, url: "licenses" },
    { name: "Profile", icon: BsPerson, url: "profile" },
    {
        name: "Logout",
        icon: BsBoxArrowLeft,
        onClick: onLogout,
    },
];

const sidebarParentLinks = [
    { name: "Dashboard", icon: MdOutlineDashboard, url: "dashboard" },
    { name: "Reports", icon: FiTrendingUp, url: "reports" },
    { name: "Profile", icon: BsPerson, url: "profile" },
    {
        name: "Logout",
        icon: BsBoxArrowLeft,
        onClick: onLogout,
    },
];

const sidebarGovernmentLinks = [
    { name: "Dashboard", icon: MdOutlineDashboard, url: "dashboard" },
    // { name: "Reports", icon: FiTrendingUp, url: "reports" },
    { name: "Licenses", icon: TbAward, url: "licenses" },
    { name: "Profile", icon: BsPerson, url: "profile" },
    {
        name: "Logout",
        icon: BsBoxArrowLeft,
        onClick: onLogout,
    },
];

const sidebarStudentLinks = [
    { name: "Dashboard", icon: MdOutlineDashboard, url: "dashboard" },
    { name: "Profile", icon: BsPerson, url: "profile" },
    {
        name: "Logout",
        icon: BsBoxArrowLeft,
        onClick: onLogout,
    },
];

const sidebarSchoolLinks = [
    { name: "Dashboard", icon: MdOutlineDashboard, url: "dashboard" },
    // { name: "Reports", icon: FiTrendingUp, url: "reports" },
    { name: "Licenses", icon: TbAward, url: "licenses" },
    { name: "Profile", icon: BsPerson, url: "profile" },
    {
        name: "Logout",
        icon: BsBoxArrowLeft,
        onClick: onLogout,
    },
];

const LINKS = {
    sidebarAdminLinks,
    sidebarTeacherLinks,
    sidebarParentLinks,
    sidebarGovernmentLinks,
    sidebarStudentLinks,
    sidebarSchoolLinks,
};

export function AdminLayout({
    children,
    typeLinks,
}: DashboardLayoutProps & RouteComponentProps) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const location = useLocation();

    const { isAdmin } = useApp();

    const finalLinks = LINKS[typeLinks];

    useEffect(() => {
        onClose();
    }, [location]);

    let isProduction = window.location.origin === "https://pal.rellie.com";

    return (
        <Box minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
            <Sidebar
                links={finalLinks}
                onClose={() => onClose}
                display={{ base: "none", md: "block" }}
            />
            <Drawer
                autoFocus={false}
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                returnFocusOnClose={false}
                onOverlayClick={onClose}
                size="full"
            >
                <DrawerContent>
                    <Sidebar links={finalLinks} onClose={onClose} />
                </DrawerContent>
            </Drawer>
            {/* mobilenav */}
            <MobileNav display={{ base: "flex", md: "none" }} onOpen={onOpen} />
            <Box
                ml={{ base: 0, md: "265px" }}
                px="48px"
                minH={"100vh"}
                position={"relative"}
                paddingBottom="50px"
            >
                {isProduction && isAdmin && (
                    <Box
                        position={"absolute"}
                        top="0px"
                        width="80%"
                        textAlign={"center"}
                        marginTop="10px"
                        border="2px solid red"
                        backgroundColor={"#ffcdcd"}
                        fontWeight={"bold"}
                    >
                        <b>THIS IS PRODUCTION DATA!</b>
                    </Box>
                )}
                {children}
            </Box>
        </Box>
    );
}
