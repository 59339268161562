import React, { useState } from "react";
import { Button as ChakraButton, Icon } from "@chakra-ui/react";
import { BsHourglassBottom } from "react-icons/bs";


export default function Button (props) {

    const { actionMode, ...rest } = props;
    
    const [actioned, setActioned] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    if (isDisabled) {
        rest.disabled = true
    }

    return (
        <ChakraButton
            {...rest}
            onClick={(event) => {

                if (actionMode === 'once') {
                    setActioned(true);
                    setIsDisabled(true)
                }

                props.onClick({
                    event,
                    resetButton: () => {
                        setActioned(false);
                        setIsDisabled(false)
                    }
                })
            }}
        >
            { actioned && (
                <Icon
                    width={"14px"}
                    height={"14px"}
                    color={"#AAAAAA"}
                    as={BsHourglassBottom}
                />
            ) || (props.children)}
        </ChakraButton>
    )
}
