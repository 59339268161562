import React, { FC, ReactNode } from "react";
import { Redirect, RouteComponentProps, Router } from "@reach/router";
import { ChakraProvider, extendTheme, Box, Flex } from "@chakra-ui/react";
import { ParentProfilePage } from "./pages/Parent/ParentProfile";
import "@fontsource/nunito/400.css";
import { ParentDashboardPage } from "./pages/Parent/ParentDashboard";
import { ParentReportsPage } from "./pages/Parent/ParentReports";
import { AdminLayout } from "./components/common/AdminLayout";
import { Loading } from "./components/common/Loading";
import { TeacherStudentReportPage } from "./pages/Teacher/TeacherStudentReport";
import { TeacherDashboardPage } from "./pages/Teacher/TeacherDashboard";
import { TeacherProfilePage } from "./pages/Teacher/TeacherProfile";
import { TeacherClassReportPage } from "./pages/Teacher/TeacherClassReport";
import { TeacherLicensesPage } from "./pages/Teacher/TeacherLicenses";
import { GovernmentDashboardPage } from "./pages/Goverment/GovernmentDashboard";
import { GovernmentProfilePage } from "./pages/Goverment/GovernmentProfile";
import { GovernmentLicensesPage } from "./pages/Goverment/GovernmentLicenses";
import { SchoolBoardDashboardPage } from "./pages/SchoolBoard/SchoolBoardDashboard";
import { SchoolBoardProfilePage } from "./pages/SchoolBoard/SchoolBoardProfile";
import { SchoolBoardLicensesPage } from "./pages/SchoolBoard/SchoolBoardLicenses";
import { AdminDashboardPage } from "./pages/Admin/AdminDashboard";
import { AdminQaReportsExportsPage } from "./pages/Admin/AdminQaReportsExports";
import { AdminDataEditorPage } from "./pages/Admin/AdminDataEditor";
import { AdminQaAnswersScoringPage } from "./pages/Admin/AdminQaAnswersScoring";
import { AdminQaAssessmentPage } from "./pages/Admin/AdminQaAssessment";
import { RegisterPasswordPage } from "./pages/Register/RegisterPassword";
// import { RegisterGoogleOAuthPage } from "./pages/Register/RegisterGoogleOAuth";

import { ErrorPage } from "./pages/Error";

import { StudentDashboardPage } from "./pages/Student/StudentDashboard";
import { StudentProfilePage } from "./pages/Student/StudentProfile";

import { Login } from "./pages/Login";
import { AssessmentQuestionnairePage } from "./pages/Assessment/AssessmentQuestionnaire";
import { AdminLicensesPage } from "./pages/Admin/AdminLicenses";
import { AdminInternalUsersPage } from "./pages/Admin/AdminInternalUsers";
import { AdminErrorsPage } from "./pages/Admin/AdminErrors";
import { AdminToolsPage } from "./pages/Admin/AdminTools";
import { AdminProfilePage } from "./pages/Admin/AdminProfile";

import { App as Application, AppLoginProtected } from "../lib/app/App";
import NotFoundPage from "./pages/404";
import { SchoolDashboardPage } from "./pages/School/SchoolDashboard";
import { SchoolProfilePage } from "./pages/School/SchoolProfile";
import { SchoolLicensesPage } from "./pages/School/SchoolLicenses";
import { EntitlementsPage } from "./pages/Admin/Entitlements";

const brandColors = {
    brand: {
        blue: { 500: "#136AFF" },
        blue400: { 500: "#00BEFF" },
        blue300: { 500: "#ACCBFF" },
        pink: { 500: "#FF4DD1" },
        green: { 500: "#1CCF8C" },
        yellow: { 500: "#FBBC04" },
        orange: { 500: "#F56806" },
        purple: { 500: "#8257FF" },
    },
};
export function hexToRGBA(hex: string, opacity: number) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r}, ${g}, ${b}, ${opacity})`;
}

export function getColor(coloScheme: string) {
    const colors = coloScheme.split(".");

    const finalColor = colors.reduce((color, level) => {
        if (!color.hasOwnProperty(level)) {
            return null;
        }

        return color[level];
    }, brandColors);

    return finalColor[500];
}

const theme = extendTheme({
    fonts: {
        heading: `'Nunito', sans-serif`,
        body: `'Nunito', sans-serif`,
    },
    colors: brandColors,
});

type Props = {
    children: ReactNode;
} & RouteComponentProps;

const PagesWrapper: FC<Props> = ({ children }) => {
    if (/^\/(admin|internal)\//.test(window.location.pathname)) {
        return <>{children}</>;
    }

    return <Box>{children}</Box>;
};

function LoadingPage() {
    return (
        <Flex mt="150px;">
            <Loading />
        </Flex>
    );
}

const NotFoundRedirect: React.FC<RouteComponentProps> = () => (
    <Redirect to="/404" noThrow />
);

export function App() {
    return (
        <ChakraProvider resetCSS theme={theme}>
            <Application LoadingPage={LoadingPage} ErrorPage={ErrorPage}>
                <Router>
                    <ErrorPage path="/error" />

                    <AppLoginProtected path="/">
                        <AdminLayout
                            path="/admin"
                            typeLinks={"sidebarAdminLinks"}
                        >
                            <AdminDashboardPage path="/dashboard" />
                            <AdminLicensesPage path="/licenses" />
                            <EntitlementsPage path="/entitlements" />
                            <AdminInternalUsersPage path="/users/admin" />
                            <AdminDataEditorPage path="/data/editor" />
                            <AdminErrorsPage path="/errors" />
                            <AdminToolsPage path="/tools" />
                            <AdminDataEditorPage path="/data/editor/:datasetName" />
                            <AdminQaReportsExportsPage path="/qa/reports-exports" />
                            <AdminQaReportsExportsPage path="/qa/reports-exports/:reportType" />
                            <AdminQaAnswersScoringPage path="/qa/answers-scoring" />
                            <AdminQaAnswersScoringPage path="/qa/answers-scoring/:assessmentId/:strategyType" />
                            <AdminQaAssessmentPage path="/qa/assessment" />
                            <AdminQaAssessmentPage path="/qa/assessment/:assessmentId" />
                            <AdminProfilePage path="/profile" />
                            <NotFoundRedirect default />
                        </AdminLayout>

                        <RegisterPasswordPage path="/register" />
                        {/* <RegisterPasswordPage path="/register/password/*" /> */}
                        {/* <RegisterGoogleOAuthPage path="/register/googleOAuth" /> */}
                        <Login path="/" />

                        <AdminLayout
                            path="/teacher"
                            typeLinks={"sidebarTeacherLinks"}
                        >
                            <TeacherDashboardPage path="/dashboard" />
                            <TeacherProfilePage path="/profile" />
                            <TeacherClassReportPage path="/reports" />
                            <TeacherClassReportPage path="/reports/:administratorTeacherLicenseId" />
                            <TeacherClassReportPage path="/reports/:administratorTeacherLicenseId/strategies" />
                            <TeacherStudentReportPage path="/reports/:administratorTeacherLicenseId/:assessmentId" />
                            <TeacherStudentReportPage path="/reports/:administratorTeacherLicenseId/:assessmentId/:studentReportType" />
                            <TeacherLicensesPage path="/licenses" />
                            <NotFoundRedirect default />
                        </AdminLayout>

                        <AdminLayout
                            path="/parent"
                            typeLinks={"sidebarParentLinks"}
                        >
                            <ParentDashboardPage path="/dashboard" />
                            <ParentProfilePage path="/profile" />
                            <ParentReportsPage path="/reports" />
                            <ParentReportsPage path="/reports/:assessmentId/:selectedCognitiveArea" />
                            <NotFoundRedirect default />
                        </AdminLayout>

                        <AdminLayout
                            path="/student"
                            typeLinks={"sidebarStudentLinks"}
                        >
                            <StudentDashboardPage path="/dashboard" />
                            <StudentProfilePage path="/profile" />
                            <NotFoundRedirect default />
                        </AdminLayout>

                        <AdminLayout
                            path="/schoolboard"
                            typeLinks={"sidebarGovernmentLinks"}
                        >
                            <SchoolBoardDashboardPage path="/dashboard" />
                            <SchoolBoardProfilePage path="/profile" />
                            <SchoolBoardLicensesPage path="/licenses" />
                            <NotFoundRedirect default />
                        </AdminLayout>

                        <AdminLayout
                            path="/school"
                            typeLinks={"sidebarSchoolLinks"}
                        >
                            <SchoolDashboardPage path="/dashboard" />
                            <SchoolProfilePage path="/profile" />
                            <SchoolLicensesPage path="/licenses" />
                            <NotFoundRedirect default />
                        </AdminLayout>

                        <AdminLayout
                            path="/government"
                            typeLinks={"sidebarGovernmentLinks"}
                        >
                            <GovernmentDashboardPage path="/dashboard" />
                            <GovernmentProfilePage path="/profile" />
                            <GovernmentLicensesPage path="/licenses" />
                            <NotFoundRedirect default />
                        </AdminLayout>

                        <PagesWrapper path="/assessment/:assessmentId">
                            <AssessmentQuestionnairePage path="/questionnaire/*" />
                            <NotFoundRedirect default />
                        </PagesWrapper>

                        <NotFoundRedirect default />
                    </AppLoginProtected>

                    <NotFoundPage path="/404" />
                    <NotFoundRedirect default />
                </Router>
            </Application>
        </ChakraProvider>
    );
}
