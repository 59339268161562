import { Box, Heading, HStack, Icon, VStack, Text } from "@chakra-ui/react";
import React, { FunctionComponent } from "react";
import _ from "lodash";
import { IoPerson } from "react-icons/io5";
import { User } from "../../../types/user";
type ProfileHeaderProps = {
    user: User;
    role: string;
};

export const ProfileHeader: FunctionComponent<ProfileHeaderProps> = ({
    user,
    role,
}) => {
    return (
        <HStack spacing={"25px"} w={"100%"} maxW={"1130px"}>
            <Box
                bg={"#FFFFFF"}
                borderRadius={"50%"}
                w={"109px"}
                h={"109px"}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"center"}
            >
                <Icon
                    fontSize="62"
                    _groupHover={{
                        color: "white",
                    }}
                    color={"#136AFF"}
                    as={IoPerson}
                />
            </Box>
            <VStack alignItems={"flex-start"}>
                <Heading size={"md"} color={"#032E59"}>
                    {user.FirstName} {user.LastName}
                </Heading>
                <Text color={"#032E59"}>{_.capitalize(role)} Account</Text>
            </VStack>
        </HStack>
    );
};
