import {
    Card,
    CardBody,
    GridItem,
    Heading,
    Input,
    Link,
    Spacer,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Tr,
    VStack,
} from "@chakra-ui/react";
import _ from "lodash";
import React, { FunctionComponent, useState } from "react";
import { User } from "../../../types/user";

type YourInfoProps = {
    name: string;
    setName: (name: string) => void;
    language: string;
    setLanguage: (language: string) => void;
    email: string;
    role: string;
};

export const YourInfo: FunctionComponent<YourInfoProps> = ({
    name,
    setName,
    language,
    setLanguage,
    email,
    role,
}) => {
    const [isEditingName, setIsEditingName] = useState(false);
    const [isEditingLanguage, setIsEditingLanguage] = useState(false);

    const handleEditName = () => {
        setIsEditingName(true);
    };

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setName(e.target.value);
    };

    const handleNameBlur = () => {
        setIsEditingName(false);
    };

    const handleEditLanguage = () => {
        setIsEditingLanguage(true);
    };

    const handleLanguageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLanguage(e.target.value);
    };

    const handleLanguageBlur = () => {
        setIsEditingLanguage(false);
    };

    return (
        <GridItem
            overflow={{
                base: "auto",
                lg: "hidden",
            }}
        >
            <VStack alignItems={"flex-start"}>
                <Heading size={"sm"} color={"#032E59"}>
                    Your Info
                </Heading>
                <Card w={"100%"} bg={"#FFFFFF"} borderRadius={"16px"}>
                    <CardBody>
                        <TableContainer>
                            <Table variant="unstyled">
                                <Tbody>
                                    <Tr
                                        borderBottom={"0.5px dashed #AAAAAA"}
                                        fontSize={"10px"}
                                        color={"#5F6368"}
                                    >
                                        <Td w={"10%"}>Full Name</Td>
                                        <Td
                                            display={"flex"}
                                            alignItems={"center"}
                                            justifyContent={"space-between"}
                                            borderRadius={"60px"}
                                        >
                                            {/* {isEditingName ? (
                                                <>
                                                    <Input
                                                        value={name}
                                                        onChange={
                                                            handleNameChange
                                                        }
                                                        maxW={"90%"}
                                                        onBlur={handleNameBlur}
                                                    />
                                                    <Text
                                                        as={"button"}
                                                        color={"#032E59"}
                                                        onClick={handleEditName}
                                                    >
                                                        Save
                                                    </Text>
                                                </>
                                            ) : ( */}
                                                <>
                                                    <Text
                                                        fontSize={"14px"}
                                                        color={"#000000"}
                                                    >
                                                        {name}
                                                    </Text>
                                                    {/* <Text
                                                        as={"button"}
                                                        color={"#032E59"}
                                                        onClick={handleEditName}
                                                    >
                                                        Edit 
                                                    </Text> */}
                                                </>
                                            {/* )} */}
                                        </Td>
                                        <Td w={"10%"}>Email</Td>
                                        <Td
                                            display={"flex"}
                                            alignItems={"center"}
                                            justifyContent={"st"}
                                            borderRadius={"60px"}
                                        >
                                            <Text
                                                fontSize={"14px"}
                                                color={"#000000"}
                                            >
                                                {email}
                                            </Text>
                                            <Spacer />
                                        </Td>
                                    </Tr>
{/* 
                                    <Tr
                                        borderBottom={"0.5px dashed #AAAAAA"}
                                        fontSize={"10px"}
                                        color={"#5F6368"}
                                    >
                                        <Td w={"10%"}>Password</Td>
                                        <Td
                                            display={"flex"}
                                            alignItems={"center"}
                                            justifyContent={"space-between"}
                                            borderRadius={"60px"}
                                        >
                                            <Text
                                                fontSize={"14px"}
                                                color={"#000000"}
                                            >
                                                ***********
                                            </Text>
                                            <Text
                                                as={"button"}
                                                color={"#032E59"}
                                                ml={"10px"}
                                            >
                                                Change Password
                                            </Text>
                                        </Td>
                                        <Td w={"10%"}>Account Type</Td>
                                        <Td
                                            display={"flex"}
                                            alignItems={"center"}
                                            justifyContent={"space-between"}
                                            borderRadius={"60px"}
                                        >
                                            <Text
                                                fontSize={"14px"}
                                                color={"#000000"}
                                            >
                                                {_.capitalize(role)}
                                            </Text>
                                        </Td>
                                    </Tr>
                                    <Tr
                                        borderBottom={"0.5px dashed #AAAAAA"}
                                        fontSize={"10px"}
                                        color={"#5F6368"}
                                    >
                                        <Td w={"10%"}>Setup</Td>
                                        <Td
                                            display={"flex"}
                                            borderRadius={"60px"}
                                            alignItems={"center"}
                                            justifyContent={"space-between"}
                                        >
                                            <Link color={"#032E59"}>
                                                Two-Factor Authentication (2FA)
                                            </Link>
                                        </Td>
                                        <Td w={"10%"}>Language</Td>
                                        <Td
                                            display={"flex"}
                                            alignItems={"center"}
                                            justifyContent={"space-between"}
                                            borderRadius={"60px"}
                                        >
                                            {isEditingLanguage ? (
                                                <>
                                                    <Input
                                                        value={language}
                                                        onChange={
                                                            handleLanguageChange
                                                        }
                                                        maxW={"90%"}
                                                        onBlur={
                                                            handleLanguageBlur
                                                        }
                                                    />
                                                    <Text
                                                        as={"button"}
                                                        color={"#032E59"}
                                                        onClick={
                                                            handleEditLanguage
                                                        }
                                                    >
                                                        Save
                                                    </Text>
                                                </>
                                            ) : (
                                                <>
                                                    <Text
                                                        fontSize={"14px"}
                                                        color={"#000000"}
                                                    >
                                                        {language}
                                                    </Text>
                                                    <Text
                                                        as={"button"}
                                                        color={"#032E59"}
                                                        onClick={
                                                            handleEditLanguage
                                                        }
                                                    >
                                                        Edit
                                                    </Text>
                                                </>
                                            )}
                                        </Td>
                                    </Tr> */}
                                </Tbody>
                            </Table>
                        </TableContainer>
                    </CardBody>
                </Card>
            </VStack>
        </GridItem>
    );
};
