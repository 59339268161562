import React, { FunctionComponent, useState } from "react";
import {
    Box,
    Button,
    Flex,
    HStack,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    VStack,
    Tooltip,
    Icon,
    useDisclosure,
} from "@chakra-ui/react";
import { Searchbar } from "../../components/common/Searchbar";
import { RouteComponentProps } from "@reach/router";
import { Import } from "../../components/popup/Import";
import { BsFillCheckCircleFill, BsPlusCircleFill } from "react-icons/bs";
import { SchoolModal } from "../../components/popup/SchoolModal";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { HiOutlinePencil } from "react-icons/hi";
import { HiOutlineTrash } from "react-icons/hi2";
import { ProgressButton } from "../../components/common/ProgressButton";
import { DeletePopup } from "../../components/common/DeletePopup";

export const mockSchools = [
    {
        id: 1,
        name: "School",
        adminFirstName: "Jordan",
        adminLastName: "Kemp",
        adminEmail: "username@gmail.com",
        licensesTotal: 100,
        status: "REGISTERED",
        allowRegistrationInvite: true,
    },
    {
        id: 2,
        name: "School",
        adminFirstName: "Jordan",
        adminLastName: "Kemp",
        adminEmail: "username@gmail.com",
        licensesTotal: 100,
        status: "REGISTERED",
        allowRegistrationInvite: false,
    },
    {
        id: 3,
        name: "School",
        adminFirstName: "Jordan",
        adminLastName: "Kemp",
        adminEmail: "username@gmail.com",
        licensesTotal: 100,
        status: "REGISTERED",
        allowRegistrationInvite: true,
    },
    {
        id: 4,
        name: "School",
        adminFirstName: "Jordan",
        adminLastName: "Kemp",
        adminEmail: "username@gmail.com",
        licensesTotal: 100,
        status: "ADDED",
        allowRegistrationInvite: false,
    },
    {
        id: 5,
        name: "School",
        adminFirstName: "Jordan",
        adminLastName: "Kemp",
        adminEmail: "username@gmail.com",
        licensesTotal: 100,
        status: "ADDED",
        allowRegistrationInvite: true,
    },
    {
        id: 6,
        name: "School",
        adminFirstName: "Jordan",
        adminLastName: "Kemp",
        adminEmail: "username@gmail.com",
        licensesTotal: 100,
        status: "pending",
        allowRegistrationInvite: false,
    },
    {
        id: 7,
        name: "School",
        adminFirstName: "Jordan",
        adminLastName: "Kemp",
        adminEmail: "username@gmail.com",
        licensesTotal: 100,
        status: "ADDED",
        allowRegistrationInvite: true,
    },
    {
        id: 8,
        name: "School",
        adminFirstName: "Jordan",
        adminLastName: "Kemp",
        adminEmail: "username@gmail.com",
        licensesTotal: 100,
        status: "active",
        allowRegistrationInvite: false,
    },
    {
        id: 9,
        name: "School",
        adminFirstName: "Jordan",
        adminLastName: "Kemp",
        adminEmail: "username@gmail.com",
        licensesTotal: 100,
        status: "pending",
        allowRegistrationInvite: true,
    },
    {
        id: 10,
        name: "School",
        adminFirstName: "Jordan",
        adminLastName: "Kemp",
        adminEmail: "username@gmail.com",
        licensesTotal: 100,
        status: "active",
        allowRegistrationInvite: false,
    },
];

export const mockSchoolsBoards = [
    {
        id: 1,
        name: "School Board",
        adminFirstName: "Jordan",
        adminLastName: "Kemp",
        adminEmail: "username@gmail.com",
        licensesTotal: 100,
        status: "REGISTERED",
        allowRegistrationInvite: true,
    },
    {
        id: 2,
        name: "School Board",
        adminFirstName: "Jordan",
        adminLastName: "Kemp",
        adminEmail: "username@gmail.com",
        licensesTotal: 100,
        status: "REGISTERED",
        allowRegistrationInvite: false,
    },
    {
        id: 3,
        name: "School Board",
        adminFirstName: "Jordan",
        adminLastName: "Kemp",
        adminEmail: "username@gmail.com",
        licensesTotal: 100,
        status: "REGISTERED",
        allowRegistrationInvite: true,
    },
    {
        id: 4,
        name: "School Board",
        adminFirstName: "Jordan",
        adminLastName: "Kemp",
        adminEmail: "username@gmail.com",
        licensesTotal: 100,
        status: "ADDED",
        allowRegistrationInvite: false,
    },
    {
        id: 5,
        name: "School Board",
        adminFirstName: "Jordan",
        adminLastName: "Kemp",
        adminEmail: "username@gmail.com",
        licensesTotal: 100,
        status: "ADDED",
        allowRegistrationInvite: true,
    },
    {
        id: 6,
        name: "School Board",
        adminFirstName: "Jordan",
        adminLastName: "Kemp",
        adminEmail: "username@gmail.com",
        licensesTotal: 100,
        status: "pending",
        allowRegistrationInvite: false,
    },
    {
        id: 7,
        name: "School Board",
        adminFirstName: "Jordan",
        adminLastName: "Kemp",
        adminEmail: "username@gmail.com",
        licensesTotal: 100,
        status: "ADDED",
        allowRegistrationInvite: true,
    },
    {
        id: 8,
        name: "School Board",
        adminFirstName: "Jordan",
        adminLastName: "Kemp",
        adminEmail: "username@gmail.com",
        licensesTotal: 100,
        status: "ADDED",
        allowRegistrationInvite: false,
    },
    {
        id: 9,
        name: "School Board",
        adminFirstName: "Jordan",
        adminLastName: "Kemp",
        adminEmail: "username@gmail.com",
        licensesTotal: 100,
        status: "pending",
        allowRegistrationInvite: true,
    },
    {
        id: 10,
        name: "School Board",
        adminFirstName: "Jordan",
        adminLastName: "Kemp",
        adminEmail: "username@gmail.com",
        licensesTotal: 100,
        status: "REGISTERED",
        allowRegistrationInvite: false,
    },
];

export interface School {
    id: number;
    name: string;
    adminFirstName: string;
    adminLastName: string;
    adminEmail: string;
    licensesTotal: number;
    status: string;
    allowRegistrationInvite?: boolean;
}

export const fetchSchools = async () => {
    await new Promise((resolve) => setTimeout(resolve, 500));
    return mockSchools;
};
export const fetchSchoolBoards = async () => {
    await new Promise((resolve) => setTimeout(resolve, 500));
    return mockSchoolsBoards;
};

export const updateSchool = async (updatedSchool) => {
    const index = mockSchools.findIndex(
        (school) => school.id === updatedSchool.id,
    );
    if (index !== -1) {
        mockSchools[index] = updatedSchool;
    }
    return mockSchools;
};

export const deleteSchool = async (id) => {
    const index = mockSchools.findIndex((school) => school.id === id);
    if (index !== -1) {
        mockSchools.splice(index, 1);
    }
    return mockSchools;
};

export const addSchool = async (newSchool) => {
    const maxId = Math.max(...mockSchools.map((school) => school.id));
    newSchool.id = maxId + 1;
    mockSchools.push(newSchool);
    return mockSchools;
};

export const GovernmentLicensesPage: FunctionComponent<
    RouteComponentProps
> = () => {
    const onImport = (data) => {
        console.log(data);
    };
    const [isDeleteOpen, setDeleteOpen] = useState(false);
    const [selectedSchool, setSelectedSchool] = useState(undefined);
    const [errors, setErrors] = useState({});
    const queryClient = useQueryClient();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const {
        data: schoolsData,
        isLoading,
        isError,
    } = useQuery<School[], Error, School[]>(
        ["schools-boards"],
        fetchSchoolBoards,
        {
            staleTime: Infinity,
        },
    );

    const updateMutation = useMutation(updateSchool, {
        onSuccess: (newSchools) => {
            // Update the data in the cache
            queryClient.setQueryData(["school"], newSchools);
        },
    });

    const deleteMutation = useMutation(deleteSchool, {
        onSuccess: (newSchools) => {
            // Update the data in the cache
            queryClient.setQueryData(["schools"], newSchools);
        },
    });

    const addMutation = useMutation(addSchool, {
        onSuccess: (newSchools) => {
            queryClient.setQueryData(["schools"], newSchools);
        },
    });

    const handleAddSchool = (school: School) => {
        addMutation.mutate(school);
    };

    const handleUpdateSchool = (updatedSchool: School) => {
        updateMutation.mutate(updatedSchool);
    };

    const handleEditClick = (updatedSchool: School) => {
        // Validate data
        try {
            handleUpdateSchool(updatedSchool);

            // Clear errors
            setErrors((prevErrors) => {
                const newErrors = { ...prevErrors };
                delete newErrors[updatedSchool.id];
                return newErrors;
            });
        } catch (error) {
            setErrors({
                ...errors,
                [updatedSchool.id]: error.formErrors.fieldErrors,
            });
        }
    };

    const handleDeleteClick = (school) => {
        setSelectedSchool(school);
        setDeleteOpen(true);
    };

    const handleClose = () => {
        setDeleteOpen(false);
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isError) {
        return <div>Error: Unable to fetch schools data</div>;
    }

    return (
        <VStack alignItems={"flex-start"} spacing={"19px"}>
            <Searchbar />
            <VStack textAlign="start">
                <Text as={"h1"} fontSize={"24px"} fontWeight={"700"}>
                    Ministry / State / Federal Government
                </Text>
                <Text w={"100%"} fontSize={"14px"} color={"#5F6368"}>
                    License Management
                </Text>
            </VStack>
            <VStack
                bg={"rgba(19, 106, 255, 0.03)"}
                borderRadius={"20px"}
                p={"17px"}
                w={"100%"}
                spacing={"18px"}
                mt={"10px"}
            >
                <Text
                    fontSize={"16px"}
                    color={"#032E59"}
                    alignSelf={"start"}
                    lineHeight={"20px"}
                    fontWeight={"700"}
                >
                    Licenses
                </Text>
                <HStack w={"100%"} justifyContent={"space-between"}>
                    <HStack spacing={"55px"}>
                        <Text fontSize={"14px"}>Available: 50</Text>
                        <Text fontSize={"14px"}>Total: 100</Text>
                    </HStack>
                    <HStack>
                        <Import onImport={onImport} />
                        <Button
                            bg={"#136AFF"}
                            leftIcon={
                                <BsPlusCircleFill
                                    color={"#FFFFFF"}
                                    size="14px"
                                />
                            }
                            color={"#FFFFFF"}
                            borderRadius={"41px"}
                            height={"22px"}
                            fontSize={"12px"}
                            sx={{
                                "& path": {
                                    stroke: "#FFFFFF",
                                },
                            }}
                            onClick={onOpen}
                        >
                            Add School Board
                        </Button>
                        {isOpen && (
                            <SchoolModal
                                type={"SCHOOL_BOARD"}
                                onClose={() => {
                                    setSelectedSchool(null);
                                    onClose();
                                }}
                                isOpen={isOpen}
                                {...(selectedSchool
                                    ? {
                                          handleEditSchool: handleEditClick,
                                          defaultValues: selectedSchool,
                                      }
                                    : {
                                          handleAddSchool: handleAddSchool,
                                      })}
                            />
                        )}
                    </HStack>
                </HStack>
                <TableContainer mt={"18px"}>
                    <Table
                        variant="simple"
                        bg={"#FFFFFF"}
                        borderRadius={"16px"}
                        sx={{
                            "& th": {
                                color: "#5F6368",
                                fontSize: "10px",
                                textTransform: "capitalize",
                            },
                            "& td": {
                                fontSize: "12px",
                            },
                        }}
                    >
                        <Thead>
                            <Tr>
                                <Th>School Board</Th>
                                <Th>Admin Firstname</Th>
                                <Th>Admin Lastname</Th>
                                <Th>School Board Id</Th>
                                <Th>Admin Email</Th>
                                <Th>LicensesTotal</Th>
                                <Th>Registration Status</Th>
                                <Th></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {schoolsData.map((school) => (
                                <Tr key={school.id}>
                                    <Td>{school.name}</Td>
                                    <Td>{school.adminFirstName}</Td>
                                    <Td>{school.adminLastName}</Td>
                                    <Td>{school.id}</Td>
                                    <Td>{school.adminEmail}</Td>
                                    <Td>{school.licensesTotal}</Td>
                                    <Td>
                                        {school.status === "REGISTERED" && (
                                            <Icon
                                                color={"#1B998B"}
                                                as={BsFillCheckCircleFill}
                                            />
                                        )}
                                        {school.status !== "REGISTERED" && (
                                            <>
                                                {(school?.allowRegistrationInvite && (
                                                    <ProgressButton
                                                        style={
                                                            school.status ===
                                                            "ADDED"
                                                                ? "primary"
                                                                : "secondary"
                                                        }
                                                        label={
                                                            school.status ===
                                                            "ADDED"
                                                                ? "Send"
                                                                : "Re-send"
                                                        }
                                                        onClick={() => {
                                                            //TODO implement
                                                        }}
                                                    />
                                                )) || (
                                                    <Box
                                                        display={"inline-block"}
                                                        width="10px"
                                                        height="10px"
                                                        borderRadius="full"
                                                        backgroundColor="orange.500"
                                                    />
                                                )}
                                            </>
                                        )}
                                    </Td>

                                    <Td>
                                        <Flex placeItems={"center"} gap={3}>
                                            <Tooltip
                                                label="Edit"
                                                aria-label="Edit"
                                                bg={"#F1F3F4"}
                                                borderRadius={"3px"}
                                                fontSize={"10px"}
                                                color={"black"}
                                                placement={"top-end"}
                                            >
                                                <span>
                                                    <Icon
                                                        ml={"5px"}
                                                        width={"10px"}
                                                        height={"10px"}
                                                        size={"10px"}
                                                        stroke={"#0051DB"}
                                                        borderColor={"#0051DB"}
                                                        as={HiOutlinePencil}
                                                        cursor={"pointer"}
                                                        onClick={() => {
                                                            setSelectedSchool(
                                                                school,
                                                            );
                                                            onOpen();
                                                        }}
                                                    />
                                                </span>
                                            </Tooltip>

                                            <Tooltip
                                                label="Delete"
                                                aria-label="Delete"
                                                bg={"#F1F3F4"}
                                                borderRadius={"3px"}
                                                fontSize={"10px"}
                                                color={"black"}
                                                placement={"top-end"}
                                            >
                                                <span>
                                                    <Icon
                                                        ml={"5px"}
                                                        width={"10px"}
                                                        height={"10px"}
                                                        size={"10px"}
                                                        stroke={"#0051DB"}
                                                        borderColor={"#0051DB"}
                                                        as={HiOutlineTrash}
                                                        cursor={"pointer"}
                                                        onClick={() => {
                                                            handleDeleteClick(
                                                                school,
                                                            );
                                                        }}
                                                    />
                                                </span>
                                            </Tooltip>
                                        </Flex>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </VStack>

            {selectedSchool && (
                <DeletePopup
                    isOpen={isDeleteOpen}
                    onClose={handleClose}
                    onDelete={() => {
                        deleteMutation.mutate(selectedSchool.id);
                        handleClose();
                    }}
                    confirmationText={`Are you sure you want to delete license ${selectedSchool.name} ${selectedSchool.id}?`}
                />
            )}
        </VStack>
    );
};
