import React, { FunctionComponent, useEffect, useState } from "react";
import {
    HStack,
    VStack,
    Box,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Button,
    chakra,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from "@chakra-ui/react";
import * as z from "zod";
import { InputForm } from "../common/InputForm";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useApp } from "../../../lib/app/App";
import { AdminFillFormButton } from "../../../lib/app/AdminFillFormButton";
import { SimpleSelect } from "../common/SimpleSelect";
import { isNil } from "lodash";

const schema = z.object({
    // clientType: z.string().nonempty("Client Name is required"),
    firstName: z.string().nonempty("Name is required"),
    lastName: z.string().nonempty("Last Name is required"),
    numberOfLicenses: z.preprocess(
        (v) => parseInt(z.string().parse(v), 10) || 0,
        z.number().min(1, "Number of Licenses is required"),
    ),
    email: z.string().email().nonempty("Email is required"),
    grade: z.preprocess(
        (v) => parseInt(z.string().parse(v), 10) || 0,
        z.number().min(1, "Grade is required"),
    ),
    class: z.string().nonempty("Class is required"),
    opt_teacher_login_mode: z
        .string()
        .nonempty("Authentication mode is required"),
    opt_teacher_email_action_mode: z
        .string()
        .nonempty("Email action mode is required"),
    opt_teacher_twofa_mode: z
        .string()
        .nonempty("2FA mode is required"),
    opt_parent_login_mode: z
        .string()
        .nonempty("Authentication mode is required"),
    opt_parent_email_action_mode: z
        .string()
        .nonempty("Email action mode is required"),
    opt_parent_twofa_mode: z
        .string()
        .nonempty("2FA mode is required"),
    opt_student_login_mode: z
        .string()
        .nonempty("Authentication mode is required"),
    opt_student_email_action_mode: z
        .string()
        .nonempty("Email action mode is required"),
    opt_student_twofa_mode: z
        .string()
        .nonempty("2FA mode is required"),

    quoteNumber: z.string().optional(),
});
type Teacher = {
    id: number;
    type: "TEACHER";
    registrationStatus: string;
    allowRegistrationInvite: boolean;
    firstName: string;
    lastName: string;
    email: string;
    licensesMaxCount: number;
    licensesUsedCount: number;
    grade: number;
    class: string;
    Id_User: number;
    createdByName: string;
    opt_teacher_login_mode: string;
    opt_teacher_email_action_mode: string;
    opt_teacher_twofa_mode: string;
    opt_parent_login_mode: string;
    opt_parent_email_action_mode: string;
    opt_parent_twofa_mode: string;
    opt_student_login_mode: string;
    opt_student_email_action_mode: string;
    opt_student_twofa_mode: string;
    quoteNumber?: string;
};

interface AddClientModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSave: Function;
    defaultValues?: Teacher;
}

const AUTH_MODES = [
    {
        label: "Any",
        value: "ANY",
    },
    {
        label: "Password",
        value: "PASSWORD",
    },
    {
        label: "Google OAuth",
        value: "GOOGLE_OAUTH",
    },
];

const EMAIL_ACTION_MODES = [
    {
        label: "Button",
        value: "BUTTON",
    },
    {
        label: "Instructions",
        value: "INSTRUCTIONS",
    },
];

const TWOFA_MODES = [
    {
        label: "Email",
        value: "EMAIL",
    },
    {
        label: "Disabled",
        value: "DISABLED",
    },
];

export const TeacherModal: FunctionComponent<AddClientModalProps> = ({
    isOpen,
    onClose,
    onSave,
    defaultValues,
}) => {
    const { useData } = useApp();

    const userData = useData("User");

    function onAutofill() {
        const randomId = Math.floor(Math.random() * 9000 + 1000);
        const values = {
            firstName: `${
                userData.data.user.FirstName.split(" ")[0]
            } - Teacher ${randomId}`,
            lastName: `${userData.data.user.LastName.split(" ")[0]}`,
            email:
                (userData.data.user.Email &&
                    userData.data.user.Email.replace(
                        /(\+[^@]+)?@/,
                        `+teacher-${randomId}@`,
                    )) ||
                "",
            grade: "7",
            class: "C1",
            quoteNumber: "21000",
            numberOfLicenses: "25",
            opt_teacher_login_mode: "ANY",
            opt_teacher_email_action_mode: "BUTTON",
            opt_teacher_twofa_mode: "EMAIL",
            opt_parent_login_mode: "ANY",
            opt_parent_email_action_mode: "BUTTON",
            opt_parent_twofa_mode: "EMAIL",
            opt_student_login_mode: "ANY",
            opt_student_email_action_mode: "BUTTON",
            opt_student_twofa_mode: "EMAIL"
        };

        (Object.keys(values) as Array<keyof typeof values>).forEach((name) => {
            setValue(name, values[name]);
        });
    }

    const [feedback, setFeedback] = useState(null);

    const {
        register,
        formState: { errors },
        handleSubmit,
        setValue,
        watch,
    } = useForm({
        resolver: zodResolver(schema),
        shouldUnregister: true,
        defaultValues: {
            firstName: defaultValues?.firstName,
            lastName: defaultValues?.lastName,
            numberOfLicenses: `${defaultValues?.licensesMaxCount}`,
            email: defaultValues?.email,
            grade: `${defaultValues?.grade}`,
            class: defaultValues?.class,
            opt_teacher_login_mode: defaultValues?.opt_teacher_login_mode,
            opt_teacher_email_action_mode:
                defaultValues?.opt_teacher_email_action_mode,
            opt_teacher_twofa_mode: defaultValues?.opt_teacher_twofa_mode,
            opt_parent_login_mode: defaultValues?.opt_parent_login_mode,
            opt_parent_email_action_mode:
                defaultValues?.opt_parent_email_action_mode,
            opt_parent_twofa_mode: defaultValues?.opt_parent_twofa_mode,
            opt_student_login_mode: defaultValues?.opt_student_login_mode,
            opt_student_email_action_mode:
                defaultValues?.opt_student_email_action_mode,
            opt_student_twofa_mode: defaultValues?.opt_student_twofa_mode,
            quoteNumber: defaultValues?.quoteNumber,
        },
    });

    const watchedFields = watch();

    useEffect(() => {
        if (!isNil(feedback)) {
            setFeedback(null);
        }

        // We only allow disabeling of 2FA for Google oAuth login
        for (const type of [
            'teacher',
            'parent',
            'student'
        ]) {
            if (watchedFields[`opt_${type}_login_mode`] === 'GOOGLE_OAUTH') {
                if (watchedFields[`opt_${type}_twofa_mode`] !== 'DISABLED') {
                    setValue(`opt_${type}_twofa_mode`, 'DISABLED')
                }
            } else {
                if (watchedFields[`opt_${type}_twofa_mode`] !== 'EMAIL') {
                    setValue(`opt_${type}_twofa_mode`, 'EMAIL')
                }
            }
        }

    }, [JSON.stringify(Object.values(watchedFields)), feedback]);

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent maxW="724px">
                <ModalHeader
                    mt="14px"
                    fontFamily="Nunito"
                    fontStyle="normal"
                    fontWeight="700"
                    fontSize="16px"
                    lineHeight="20px"
                    color="#032E59"
                >
                    {defaultValues ? "Edit" : "Add"} Client
                    <Text
                        fontFamily="Nunito"
                        fontStyle="normal"
                        fontWeight="400"
                        fontSize="14px"
                        lineHeight="24px"
                    >
                        Enter the information below.
                    </Text>
                    <AdminFillFormButton onClick={onAutofill} />
                </ModalHeader>
                <chakra.form
                    onSubmit={handleSubmit(async (data) => {
                        const feedback = await onSave(data);
                        if (feedback === true) {
                            onClose();
                        } else {
                            setFeedback(feedback);
                        }
                    })}
                >
                    <ModalBody color="black">
                        <VStack w="100%">
                            <HStack w="100%" gap="20px">
                                <Box w="50%" minHeight="75px">
                                    <Text
                                        w={"100%"}
                                        textAlign={"left"}
                                        fontSize={"12px"}
                                        fontWeight={"400"}
                                        lineHeight={"16px"}
                                        mb={"11px"}
                                    >
                                        Client Type
                                    </Text>
                                    <Text
                                        fontFamily="Nunito"
                                        fontStyle="normal"
                                        fontWeight="400"
                                        fontSize="14px"
                                        lineHeight="24px"
                                    >
                                        Teacher
                                    </Text>
                                </Box>
                                <Box w="50%" minHeight="75px">
                                    <Text
                                        w={"100%"}
                                        textAlign={"left"}
                                        fontSize={"12px"}
                                        fontWeight={"400"}
                                        lineHeight={"16px"}
                                        mb={"11px"}
                                    >
                                        Rellie Quote Number
                                    </Text>
                                    <InputForm
                                        register={register}
                                        name={"quoteNumber"}
                                        placeholder={"Enter quote number"}
                                        height={"30px"}
                                        width={"100%"}
                                        error={
                                            errors?.quoteNumber?.message as
                                                | string
                                                | undefined
                                        }
                                        rest={{
                                            border: "1px solid #ACCBFF",
                                            borderColor: "#ACCBFF",
                                            borderRadius: "37px",
                                            bg: "#fff",
                                            mx: "0px",
                                            fontSize: "16px",
                                            textAlign: "left",
                                        }}
                                    />
                                </Box>
                            </HStack>
                            <HStack w="100%" gap="20px">
                                <Box w="50%" minHeight="75px">
                                    <Text
                                        w={"100%"}
                                        textAlign={"left"}
                                        fontSize={"12px"}
                                        fontWeight={"400"}
                                        lineHeight={"16px"}
                                        mb={"11px"}
                                    >
                                        Teacher First Name
                                    </Text>
                                    <InputForm
                                        register={register}
                                        type={"name"}
                                        name={"firstName"}
                                        placeholder={"First name"}
                                        height={"30px"}
                                        width={"100%"}
                                        error={
                                            errors?.firstName?.message as
                                                | string
                                                | undefined
                                        }
                                        rest={{
                                            border: "1px solid #ACCBFF",
                                            borderColor: "#ACCBFF",
                                            borderRadius: "37px",
                                            bg: "#fff",
                                            mx: "0px",
                                            fontSize: "14px",
                                            textAlign: "left",
                                        }}
                                    />
                                </Box>

                                <Box w="50%" minHeight="75px">
                                    <Text
                                        w={"100%"}
                                        textAlign={"left"}
                                        fontSize={"12px"}
                                        fontWeight={"400"}
                                        lineHeight={"16px"}
                                        mb={"11px"}
                                    >
                                        Teacher Last Name
                                    </Text>
                                    <InputForm
                                        register={register}
                                        type={"name"}
                                        name={"lastName"}
                                        placeholder={"Last name"}
                                        height={"30px"}
                                        width={"100%"}
                                        error={
                                            errors?.lastName?.message as
                                                | string
                                                | undefined
                                        }
                                        rest={{
                                            border: "1px solid #ACCBFF",
                                            borderColor: "#ACCBFF",
                                            borderRadius: "37px",
                                            bg: "#fff",
                                            mx: "0px",
                                            fontSize: "14px",
                                            textAlign: "left",
                                        }}
                                    />
                                </Box>
                            </HStack>
                            <HStack w="100%" gap="20px">
                                <Box w="50%" minHeight="75px">
                                    <Text
                                        w={"100%"}
                                        textAlign={"left"}
                                        fontSize={"12px"}
                                        fontWeight={"400"}
                                        lineHeight={"16px"}
                                        mb={"11px"}
                                    >
                                        Number of Licenses
                                    </Text>
                                    <InputForm
                                        register={register}
                                        type={"number"}
                                        name={"numberOfLicenses"}
                                        placeholder={"25"}
                                        height={"30px"}
                                        width={"100%"}
                                        error={
                                            errors?.numberOfLicenses
                                                ?.message as string | undefined
                                        }
                                        rest={{
                                            border: "1px solid #ACCBFF",
                                            borderColor: "#ACCBFF",
                                            borderRadius: "37px",
                                            bg: "#fff",
                                            mx: "0px",
                                            fontSize: "14px",
                                            textAlign: "left",
                                        }}
                                    />
                                    {/* <Text
                                            w={"100%"}
                                            textAlign={"right"}

                                            fontSize={"10px"}
                                            fontWeight={"400"}
                                            lineHeight={"16px"}
                                        >
                                            Licenses available: 20,000
                                        </Text> */}
                                </Box>
                                <Box w="50%" minHeight="75px">
                                    <Text
                                        w={"100%"}
                                        textAlign={"left"}
                                        fontSize={"12px"}
                                        fontWeight={"400"}
                                        lineHeight={"16px"}
                                        mb={"11px"}
                                    >
                                        Teacher Email
                                    </Text>
                                    <InputForm
                                        register={register}
                                        type={"email"}
                                        name={"email"}
                                        placeholder={"Enter email"}
                                        height={"30px"}
                                        width={"100%"}
                                        error={
                                            errors?.email?.message as
                                                | string
                                                | undefined
                                        }
                                        rest={{
                                            border: "1px solid #ACCBFF",
                                            borderColor: "#ACCBFF",
                                            borderRadius: "37px",
                                            bg: "#fff",
                                            mx: "0px",
                                            fontSize: "14px",
                                            textAlign: "left",
                                            isDisabled: (defaultValues?.registrationStatus === 'REGISTERED'),
                                        }}
                                    />
                                </Box>
                            </HStack>
                            <HStack w="100%" gap="20px">
                                <Box w="50%" minHeight="75px">
                                    <Text
                                        w={"100%"}
                                        textAlign={"left"}
                                        fontSize={"12px"}
                                        fontWeight={"400"}
                                        lineHeight={"16px"}
                                        mb={"11px"}
                                    >
                                        Grade
                                    </Text>
                                    <InputForm
                                        register={register}
                                        type={"number"}
                                        name={"grade"}
                                        placeholder={"7"}
                                        height={"30px"}
                                        width={"100%"}
                                        error={
                                            errors?.grade?.message as
                                                | string
                                                | undefined
                                        }
                                        rest={{
                                            border: "1px solid #ACCBFF",
                                            borderColor: "#ACCBFF",
                                            borderRadius: "37px",
                                            bg: "#fff",
                                            mx: "0px",
                                            fontSize: "14px",
                                            textAlign: "left",
                                        }}
                                    />
                                </Box>
                                <Box w="50%" minHeight="75px">
                                    <Text
                                        w={"100%"}
                                        textAlign={"left"}
                                        fontSize={"12px"}
                                        fontWeight={"400"}
                                        lineHeight={"16px"}
                                        mb={"11px"}
                                    >
                                        Class
                                    </Text>
                                    <InputForm
                                        register={register}
                                        type={"name"}
                                        name={"class"}
                                        placeholder={"Class"}
                                        height={"30px"}
                                        width={"100%"}
                                        error={
                                            errors?.class?.message as
                                                | string
                                                | undefined
                                        }
                                        rest={{
                                            border: "1px solid #ACCBFF",
                                            borderColor: "#ACCBFF",
                                            borderRadius: "37px",
                                            bg: "#fff",
                                            mx: "0px",
                                            fontSize: "14px",
                                            textAlign: "left",
                                        }}
                                    />
                                </Box>
                            </HStack>

                            <HStack w="100%" gap="20px">
                                <TableContainer>
                                    <Table
                                        variant="simple"
                                        bg={"#FFFFFF"}
                                        borderRadius={"16px"}
                                        size="md"
                                    >
                                        <Thead>
                                            <Tr>
                                                <Th>Role</Th>
                                                <Th>Authentication Mode</Th>
                                                <Th>Email Action Mode</Th>
                                                <Th>2FA Mode</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            <Tr>
                                                <Td>Teacher</Td>
                                                <Td>
                                                    <SimpleSelect
                                                        register={register}
                                                        name="opt_teacher_login_mode"
                                                        placeholder="Select Mode"
                                                        error={
                                                            errors
                                                                ?.opt_teacher_login_mode
                                                                ?.message as
                                                                | string
                                                                | undefined
                                                        }
                                                        options={AUTH_MODES}
                                                        rest={{
                                                            isDisabled: (defaultValues?.registrationStatus === 'REGISTERED'),
                                                        }}
                                                    />
                                                </Td>
                                                <Td>
                                                    <SimpleSelect
                                                        register={register}
                                                        name="opt_teacher_email_action_mode"
                                                        placeholder="Select Mode"
                                                        error={
                                                            errors
                                                                ?.opt_teacher_email_action_mode
                                                                ?.message as
                                                                | string
                                                                | undefined
                                                        }
                                                        options={
                                                            EMAIL_ACTION_MODES
                                                        }
                                                        rest={{
                                                            isDisabled: (defaultValues?.registrationStatus === 'REGISTERED'),
                                                        }}
                                                    />
                                                </Td>
                                                <Td>
                                                    <SimpleSelect
                                                        register={register}
                                                        name="opt_teacher_twofa_mode"
                                                        placeholder="Select Mode"
                                                        error={
                                                            errors
                                                                ?.opt_teacher_twofa_mode
                                                                ?.message as
                                                                | string
                                                                | undefined
                                                        }
                                                        options={TWOFA_MODES}
                                                        rest={{
                                                            isDisabled: true,
                                                        }}
                                                    />
                                                </Td>
                                            </Tr>
                                            <Tr>
                                                <Td>Parent</Td>
                                                <Td>
                                                    <SimpleSelect
                                                        register={register}
                                                        name="opt_parent_login_mode"
                                                        placeholder="Select Mode"
                                                        error={
                                                            errors
                                                                ?.opt_parent_login_mode
                                                                ?.message as
                                                                | string
                                                                | undefined
                                                        }
                                                        options={AUTH_MODES}
                                                    />
                                                </Td>
                                                <Td>
                                                    <SimpleSelect
                                                        register={register}
                                                        name="opt_parent_email_action_mode"
                                                        placeholder="Select Mode"
                                                        error={
                                                            errors
                                                                ?.opt_parent_email_action_mode
                                                                ?.message as
                                                                | string
                                                                | undefined
                                                        }
                                                        options={
                                                            EMAIL_ACTION_MODES
                                                        }
                                                    />
                                                </Td>
                                                <Td>
                                                    <SimpleSelect
                                                        register={register}
                                                        name="opt_parent_twofa_mode"
                                                        placeholder="Select Mode"
                                                        error={
                                                            errors
                                                                ?.opt_parent_twofa_mode
                                                                ?.message as
                                                                | string
                                                                | undefined
                                                        }
                                                        options={TWOFA_MODES}
                                                        rest={{
                                                            isDisabled: true,
                                                        }}
                                                    />
                                                </Td>

                                            </Tr>
                                            <Tr>
                                                <Td>Student</Td>
                                                <Td>
                                                    <SimpleSelect
                                                        register={register}
                                                        name="opt_student_login_mode"
                                                        placeholder="Select Mode"
                                                        error={
                                                            errors
                                                                ?.opt_student_login_mode
                                                                ?.message as
                                                                | string
                                                                | undefined
                                                        }
                                                        options={AUTH_MODES}
                                                    />
                                                </Td>
                                                <Td>
                                                    <SimpleSelect
                                                        register={register}
                                                        name="opt_student_email_action_mode"
                                                        placeholder="Select Mode"
                                                        error={
                                                            errors
                                                                ?.opt_student_email_action_mode
                                                                ?.message as
                                                                | string
                                                                | undefined
                                                        }
                                                        options={
                                                            EMAIL_ACTION_MODES
                                                        }
                                                    />
                                                </Td>
                                                <Td>
                                                    <SimpleSelect
                                                        register={register}
                                                        name="opt_student_twofa_mode"
                                                        placeholder="Select Mode"
                                                        error={
                                                            errors
                                                                ?.opt_student_twofa_mode
                                                                ?.message as
                                                                | string
                                                                | undefined
                                                        }
                                                        options={TWOFA_MODES}
                                                        rest={{
                                                            isDisabled: true,
                                                        }}
                                                    />
                                                </Td>
                                            </Tr>
                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            </HStack>

                            {feedback?.error?.code ===
                                "TEACHER_FOR_GRADE_AND_CLASS_EXISTS" && (
                                <Text
                                    w={"100%"}
                                    textAlign={"left"}
                                    color={"#FF0000"}
                                    fontSize={"12px"}
                                    fontWeight={"400"}
                                    lineHeight={"16px"}
                                    mb={"11px"}
                                >
                                    This teacher already exists with the same
                                    email + grade + class combination!
                                </Text>
                            )}
                        </VStack>
                    </ModalBody>
                    <ModalFooter justifyContent="center" gap={"20px"}>
                        <Button
                            width={"115px"}
                            borderRadius={"37px"}
                            variant="outline"
                            border={"1px solid #ACCBFF"}
                            color={"black"}
                            onClick={() => {
                                onClose();
                                setFeedback(null);
                            }}
                            fontSize={"14px"}
                        >
                            <Text>Cancel</Text>
                        </Button>
                        <Button
                            width={"115px"}
                            bg={"#136AFF"}
                            borderRadius={"37px"}
                            color={"white"}
                            type={"submit"}
                            fontSize={"14px"}
                        >
                            {defaultValues ? "Save" : "Add"} Teacher
                        </Button>
                    </ModalFooter>
                </chakra.form>
            </ModalContent>
        </Modal>
    );
};
