import React, { FunctionComponent, useState } from "react";
import {
    Box,
    Button,
    HStack,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    VStack,
    useDisclosure,
    Icon,
    Tooltip,
    Flex,
} from "@chakra-ui/react";
import { Searchbar } from "../../components/common/Searchbar";
import { RouteComponentProps } from "@reach/router";
import { Import } from "../../components/popup/Import";
import { BsFillCheckCircleFill, BsPlusCircleFill } from "react-icons/bs";
import { RiErrorWarningFill } from "react-icons/ri";
import { useQuery } from "@tanstack/react-query";
import { ProgressButton } from "../../components/common/ProgressButton";
import { TeacherModal } from "../../components/popup/TeacherModal";
import { HiOutlinePencil, HiOutlineTrash } from "react-icons/hi";
import { DeletePopup } from "../../components/common/DeletePopup";

const mockTeachers = [
    {
        id: 1,
        name: "Jordan",
        lastName: "Kemp",
        email: "username@gmail.com",
        licensesTotal: 100,
        status: "REGISTERED",
        allowRegistrationInvite: true,
    },
    {
        id: 2,
        name: "Jordan",
        lastName: "Kemp",
        email: "username@gmail.com",
        licensesTotal: 100,
        status: "REGISTERED",
        allowRegistrationInvite: true,
    },
    {
        id: 3,
        name: "Jordan",
        lastName: "Kemp",
        email: "username@gmail.com",
        licensesTotal: 100,
        status: "REGISTERED",
        allowRegistrationInvite: false,
    },
    {
        id: 4,
        name: "Jordan",
        lastName: "Kemp",
        email: "username@gmail.com",
        licensesTotal: 89,
        status: "ADDED",
        allowRegistrationInvite: false,
    },
    {
        id: 5,
        name: "Jordan",
        lastName: "Kemp",
        email: "username@gmail.com",
        licensesTotal: 50,
        status: "ADDED",
        allowRegistrationInvite: false,
    },
    {
        id: 6,
        name: "Jordan",
        lastName: "Kemp",
        email: "username@gmail.com",
        licensesTotal: 50,
        status: "pending",
        allowRegistrationInvite: true,
    },
    {
        id: 7,
        name: "Jordan",
        lastName: "Kemp",
        email: "username@gmail.com",
        licensesTotal: 100,
        status: "ADDED",
        allowRegistrationInvite: false,
    },
    {
        id: 8,
        name: "Jordan",
        lastName: "Kemp",
        email: "username@gmail.com",
        licensesTotal: 100,
        status: "ADDED",
        allowRegistrationInvite: true,
    },
    {
        id: 9,
        name: "Jordan",
        lastName: "Kemp",
        email: "username@gmail.com",
        licensesTotal: 100,
        status: "pending",
        allowRegistrationInvite: true,
    },
    {
        id: 10,
        name: "Jordan",
        lastName: "Kemp",
        email: "username@gmail.com",
        licensesTotal: 100,
        status: "active",
        allowRegistrationInvite: true,
    },
];

const fetchTeachers = async () => {
    await new Promise((resolve) => setTimeout(resolve, 500));
    return mockTeachers;
};

export const SchoolLicensesPage: FunctionComponent<
    RouteComponentProps
> = () => {
    const onImport = (data) => {
        console.log(data);
    };
    const [isDeleteOpen, setDeleteOpen] = useState(false);
    const [selectedTeacher, setSelectedTeacher] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();

    const {
        data: teachersData,
        isLoading,
        isError,
    } = useQuery(["teachers"], fetchTeachers, {
        staleTime: Infinity,
    });

    const handleAddTeacher = (teacher) => {};

    const handleEditTeacher = (teacher) => {
        //TODO implement
    };
    const handleEditClick = (school) => {
        setSelectedTeacher(school);
        onOpen();
    };

    const handleDeleteClick = (school) => {
        setSelectedTeacher(school);
        setDeleteOpen(true);
    };

    const handleClose = () => {
        setDeleteOpen(false);
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isError) {
        return <div>Error: Unable to fetch schools data</div>;
    }

    return (
        <VStack alignItems={"flex-start"} spacing={"19px"}>
            <Searchbar />
            <VStack textAlign="start">
                <Text as={"h1"} fontSize={"24px"} fontWeight={"700"}>
                    Toronto School
                </Text>
                <Text w={"100%"} fontSize={"14px"} color={"#5F6368"}>
                    License Management
                </Text>
            </VStack>
            <VStack
                m={0}
                bg={"rgba(19, 106, 255, 0.03)"}
                borderRadius={"20px"}
                p={"17px"}
                w={"100%"}
                spacing={"18px"}
            >
                <Text
                    fontSize={"16px"}
                    color={"#032E59"}
                    alignSelf={"start"}
                    lineHeight={"20px"}
                    fontWeight={"700"}
                >
                    Licenses
                </Text>
                <HStack w={"100%"} justifyContent={"space-between"}>
                    <HStack spacing={"55px"}>
                        <Text fontSize={"14px"}>Available: 50</Text>
                        <Text fontSize={"14px"}>Total: 100</Text>
                    </HStack>
                    <HStack>
                        <Import onImport={onImport} />
                        <Button
                            bg={"#136AFF"}
                            leftIcon={
                                <BsPlusCircleFill
                                    color={"#FFFFFF"}
                                    size="14px"
                                />
                            }
                            color={"#FFFFFF"}
                            borderRadius={"41px"}
                            height={"22px"}
                            fontSize={"12px"}
                            sx={{
                                "& path": {
                                    stroke: "#FFFFFF",
                                },
                            }}
                            onClick={onOpen}
                        >
                            Add Teacher
                        </Button>
                        {isOpen && (
                            <TeacherModal
                                onClose={() => {
                                    onClose();
                                    setSelectedTeacher(null);
                                }}
                                isOpen={isOpen}
                                handleAddTeacher={handleAddTeacher}
                                handleEditTeacher={handleEditTeacher}
                                defaultValues={selectedTeacher}
                            />
                        )}
                    </HStack>
                </HStack>
                <TableContainer mt={"18px"}>
                    <Table
                        variant="simple"
                        bg={"#FFFFFF"}
                        borderRadius={"16px"}
                        sx={{
                            "& th": {
                                color: "#5F6368",
                                fontSize: "10px",
                                textTransform: "capitalize",
                            },
                            "& td": {
                                fontSize: "12px",
                            },
                        }}
                    >
                        <Thead>
                            <Tr>
                                <Th>Teacher Name</Th>
                                <Th>Teacher Last Name</Th>
                                <Th>Teacher Id</Th>
                                <Th>Teacher Email</Th>
                                <Th>
                                    Licenses
                                    <br />
                                    Total
                                </Th>
                                <Th>Registration Status</Th>
                                <Th></Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {teachersData.map((teacher) => (
                                <Tr key={teacher.id}>
                                    <Td>{teacher.name}</Td>
                                    <Td>{teacher.lastName}</Td>
                                    <Td>{teacher.id}</Td>
                                    <Td>{teacher.email}</Td>
                                    <Td>{teacher.licensesTotal}</Td>
                                    <Td>
                                        {teacher.status === "REGISTERED" && (
                                            <Icon
                                                color={"#1B998B"}
                                                as={BsFillCheckCircleFill}
                                            />
                                        )}
                                        {teacher.status !== "REGISTERED" && (
                                            <>
                                                {(teacher.allowRegistrationInvite && (
                                                    <ProgressButton
                                                        style={
                                                            teacher.status ===
                                                            "ADDED"
                                                                ? "primary"
                                                                : "secondary"
                                                        }
                                                        label={
                                                            teacher.status ===
                                                            "ADDED"
                                                                ? "Send"
                                                                : "Re-send"
                                                        }
                                                        onClick={() => {
                                                            //TODO implement
                                                        }}
                                                    />
                                                )) || (
                                                    <Box
                                                        display={"inline-block"}
                                                        width="10px"
                                                        height="10px"
                                                        borderRadius="full"
                                                        backgroundColor="orange.500"
                                                    />
                                                )}
                                            </>
                                        )}
                                    </Td>

                                    <Td>
                                        <Flex placeItems={"center"} gap={3}>
                                            <Tooltip
                                                label="Edit"
                                                aria-label="Edit"
                                                bg={"#F1F3F4"}
                                                borderRadius={"3px"}
                                                fontSize={"10px"}
                                                color={"black"}
                                                placement={"top-end"}
                                            >
                                                <span>
                                                    <Icon
                                                        ml={"5px"}
                                                        width={"10px"}
                                                        height={"10px"}
                                                        size={"10px"}
                                                        stroke={"#0051DB"}
                                                        borderColor={"#0051DB"}
                                                        as={HiOutlinePencil}
                                                        cursor={"pointer"}
                                                        onClick={() => {
                                                            handleEditClick(
                                                                teacher,
                                                            );
                                                        }}
                                                    />
                                                </span>
                                            </Tooltip>

                                            <Tooltip
                                                label="Delete"
                                                aria-label="Delete"
                                                bg={"#F1F3F4"}
                                                borderRadius={"3px"}
                                                fontSize={"10px"}
                                                color={"black"}
                                                placement={"top-end"}
                                            >
                                                <span>
                                                    <Icon
                                                        ml={"5px"}
                                                        width={"10px"}
                                                        height={"10px"}
                                                        size={"10px"}
                                                        stroke={"#0051DB"}
                                                        borderColor={"#0051DB"}
                                                        as={HiOutlineTrash}
                                                        cursor={"pointer"}
                                                        onClick={() => {
                                                            handleDeleteClick(
                                                                teacher,
                                                            );
                                                        }}
                                                    />
                                                </span>
                                            </Tooltip>
                                        </Flex>
                                    </Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </TableContainer>
            </VStack>

            {selectedTeacher && (
                <DeletePopup
                    isOpen={isDeleteOpen}
                    onClose={handleClose}
                    onDelete={() => {
                        //TODO implement
                        handleClose();
                    }}
                    confirmationText={`Are you sure you want to delete license ${selectedTeacher.name}?`}
                />
            )}
        </VStack>
    );
};
