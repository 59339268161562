import React, { FunctionComponent, useState, useEffect } from "react";
import {
    HStack,
    VStack,
    Box,
    Text,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Button,
    chakra,
} from "@chakra-ui/react";
import * as z from "zod";
import { InputForm } from "../common/InputForm";
import { useForm } from "react-hook-form";
import { BsPlusCircleFill } from "react-icons/bs";
import { zodResolver } from "@hookform/resolvers/zod";

const schema = z.object({
    // clientType: z.string().nonempty("Client Name is required"),
    FirstName: z.string().nonempty("Name is required"),
    LastName: z.string().nonempty("Last Name is required"),
    Email: z.string().email().nonempty("Email is required"),
});

interface AddAdminModalProps {
    title: string;
    onSendInvite: Function;
}

export const AddAdminModal: FunctionComponent<AddAdminModalProps> = ({
    title,
    onSendInvite,
}) => {
    let defaultValues = {};

    const [feedback, setFeedback] = useState(null);

    const { isOpen, onOpen, onClose } = useDisclosure();
    const {
        register,
        formState: { errors },
        handleSubmit,
        watch,
    } = useForm({
        defaultValues,
        resolver: zodResolver(schema),
        shouldUnregister: false,
        // TODO: Set setFeedback(null) as soon as anything is edited in the form.
    });

    const watchedFields = watch();

    useEffect(() => {
        setFeedback(null);
    }, [JSON.stringify(Object.values(watchedFields))]);

    return (
        <>
            <Button
                leftIcon={<BsPlusCircleFill color={"#0051DB"} size="14px" />}
                color="#032E59"
                variant="outline"
                borderColor="#0051DB"
                borderRadius={"41px"}
                height={"20px"}
                fontSize={"12px"}
                onClick={onOpen}
            >
                {title}
            </Button>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent maxW="724px">
                    <ModalHeader
                        mt="14px"
                        fontFamily="Nunito"
                        fontStyle="normal"
                        fontWeight="700"
                        fontSize="16px"
                        lineHeight="20px"
                        color="#032E59"
                    >
                        Add Admin
                        <Text
                            fontFamily="Nunito"
                            fontStyle="normal"
                            fontWeight="400"
                            fontSize="14px"
                            lineHeight="24px"
                        >
                            Enter the information below.
                        </Text>
                    </ModalHeader>
                    <chakra.form
                        onSubmit={handleSubmit(async (data) => {
                            const feedback = await onSendInvite(data);
                            if (feedback === true) {
                                onClose();
                            } else {
                                setFeedback(feedback);
                            }
                        })}
                    >
                        <ModalBody color="black">
                            <VStack w="100%">
                                <HStack w="100%" gap="20px"></HStack>
                                <HStack w="100%" gap="20px">
                                    <Box w="50%" minHeight="75px">
                                        <Text
                                            w={"100%"}
                                            textAlign={"left"}
                                            fontSize={"12px"}
                                            fontWeight={"400"}
                                            lineHeight={"16px"}
                                            mb={"11px"}
                                        >
                                            Admin First Name
                                        </Text>
                                        <InputForm
                                            register={register}
                                            type={"name"}
                                            name={"FirstName"}
                                            placeholder={"First name"}
                                            height={"30px"}
                                            width={"100%"}
                                            error={
                                                errors?.FirstName?.message as
                                                    | string
                                                    | undefined
                                            }
                                            rest={{
                                                border: "1px solid #ACCBFF",
                                                borderColor: "#ACCBFF",
                                                borderRadius: "37px",
                                                bg: "#fff",
                                                mx: "0px",
                                                fontSize: "14px",
                                                textAlign: "left",
                                            }}
                                        />
                                    </Box>

                                    <Box w="50%" minHeight="75px">
                                        <Text
                                            w={"100%"}
                                            textAlign={"left"}
                                            fontSize={"12px"}
                                            fontWeight={"400"}
                                            lineHeight={"16px"}
                                            mb={"11px"}
                                        >
                                            Admin Last Name
                                        </Text>
                                        <InputForm
                                            register={register}
                                            type={"name"}
                                            name={"LastName"}
                                            placeholder={"Last name"}
                                            height={"30px"}
                                            width={"100%"}
                                            error={
                                                errors?.LastName?.message as
                                                    | string
                                                    | undefined
                                            }
                                            rest={{
                                                border: "1px solid #ACCBFF",
                                                borderColor: "#ACCBFF",
                                                borderRadius: "37px",
                                                bg: "#fff",
                                                mx: "0px",
                                                fontSize: "14px",
                                                textAlign: "left",
                                            }}
                                        />
                                    </Box>
                                </HStack>
                                <HStack w="100%" gap="20px">
                                    <Box w="50%" minHeight="75px">
                                        <Text
                                            w={"100%"}
                                            textAlign={"left"}
                                            fontSize={"12px"}
                                            fontWeight={"400"}
                                            lineHeight={"16px"}
                                            mb={"11px"}
                                        >
                                            Admin Email
                                        </Text>
                                        <InputForm
                                            register={register}
                                            type={"email"}
                                            name={"Email"}
                                            placeholder={"Enter email"}
                                            height={"30px"}
                                            width={"100%"}
                                            error={
                                                errors?.Email?.message as
                                                    | string
                                                    | undefined
                                            }
                                            rest={{
                                                border: "1px solid #ACCBFF",
                                                borderColor: "#ACCBFF",
                                                borderRadius: "37px",
                                                bg: "#fff",
                                                mx: "0px",
                                                fontSize: "14px",
                                                textAlign: "left",
                                            }}
                                        />
                                    </Box>
                                </HStack>
                                {feedback?.error?.code && (
                                    <Text
                                        w={"100%"}
                                        textAlign={"left"}
                                        color={"#FF0000"}
                                        fontSize={"12px"}
                                        fontWeight={"400"}
                                        lineHeight={"16px"}
                                        mb={"11px"}
                                    >
                                        {feedback?.error?.code ===
                                            "USER_WITH_EMAIL_EXISTS" && (
                                            <>
                                                A user with this email address
                                                already exists!
                                            </>
                                        )}
                                        {feedback?.error?.code ===
                                            "ADMIN_LICENSE_WITH_EMAIL_EXISTS" && (
                                            <>
                                                A admin license with this email
                                                address already exists!
                                            </>
                                        )}
                                    </Text>
                                )}
                            </VStack>
                        </ModalBody>
                        <ModalFooter justifyContent="center" gap={"20px"}>
                            <Button
                                width={"115px"}
                                borderRadius={"37px"}
                                variant="outline"
                                border={"1px solid #ACCBFF"}
                                color={"black"}
                                onClick={onClose}
                                fontSize={"14px"}
                            >
                                <Text>Cancel</Text>
                            </Button>
                            <Button
                                width={"115px"}
                                bg={"#136AFF"}
                                borderRadius={"37px"}
                                color={"white"}
                                type={"submit"}
                                fontSize={"14px"}
                            >
                                Send Invite
                            </Button>
                        </ModalFooter>
                    </chakra.form>
                </ModalContent>
            </Modal>
        </>
    );
};
