import React, { FunctionComponent, useState, useEffect } from "react";
import { RouteComponentProps, useLocation, navigate } from "@reach/router";
import {
    Flex,
    Heading,
    HStack,
    Image,
    VStack,
    Text,
    Select,
    Icon,
} from "@chakra-ui/react";
import logo from "../../assets/logo.svg";

import { Footer } from "../../components/common/Footer";

import { SpecifyEmailComponent } from "../../components/register/SpecifyEmail";
import { CreatePasswordComponent } from "../../components/register/CreatePassword";
import { UpdatePasswordComponent } from "../../components/register/UpdatePassword";
import { EnterCodeComponent } from "../../components/register/EnterCode";
// import { Welcome } from "../../registration/steps/Welcome";

import { getCookie } from "../../../lib/util";

import { useApp } from "../../../lib/app/App";
import { hashPassword } from "../../../lib/util";
import { BiSolidDownArrow } from "react-icons/bi";

export const RegisterPasswordPage: FunctionComponent<
    RouteComponentProps
> = () => {
    const { api, useAdminContext, webappOrigin } = useApp();

    const qs = new URLSearchParams(useLocation().search);

    let currentStepDefaultValue = 'CREATE_PASSWORD';
    if (qs.get("mode") === "PASSWORD_RESET") {
        currentStepDefaultValue = 'UPDATE_PASSWORD';
    }
    if (
        !qs.get("email") ||
        !qs.get("mode") ||
        !qs.get("role") ||
        !qs.get("token")
    ) {
        currentStepDefaultValue = 'SPECIFY_EMAIL';
    }

    const [currentStep, setCurrentStep] = useState(currentStepDefaultValue);
    const [enteredEmail, setEnteredEmail] = useState(null);
    const [codeFeedback, setCodeFeedback] = useState(null);
    const adminContext = useAdminContext();

    useEffect(() => {
        // NOTE: We call this just in case to make testing and complex user flows reliable.
        api.User.DeleteRegistrationToken.mutate().catch((err) => {
            console.error("Error calling User.DeleteRegistrationToken", err);
        });
    }, [true]);

    const renderContent = () => {
        switch (currentStep) {

            case 'SPECIFY_EMAIL':
                return (
                    <SpecifyEmailComponent
                        feedback={codeFeedback}
                        email={qs.get("email") || ''}
                        onContinueHandler={async ({ email }) => {

                            let response =
                                await api.User.HasRegistrationToken.mutate({
                                    email,
                                    webappOrigin,
                                });

                            if (response.redirectUrl) {
                                // NOTE: We redirect user instead of simply switching to next step in order
                                //       to use the same approach as '/register/from-email' (with qs data from email button) for consistency.
                                window.location.href = response.redirectUrl;
                                return
                            }

                            if (response.error) {
                                setCodeFeedback(response);
                                return;
                            }

                            setCodeFeedback(null);

                            setEnteredEmail(email);

                            response =
                                await api.User.SendRegistrationCodeEmail.mutate(
                                    {
                                        Email: email,
                                    },
                                );

                            if (response.verificationCode) {
                                // Used in preview mode to pre-fill the code.
                                window.localStorage.setItem(
                                    "re-preview-registration-verification-code",
                                    response.verificationCode,
                                );
                            }

                            setCurrentStep('ENTER_2FA_CODE');
                        }}
                    />
                );

            case 'ENTER_2FA_CODE':
                return (
                    <EnterCodeComponent
                        feedback={codeFeedback}
                        setCodeFeedback={setCodeFeedback}
                        enteredEmail={enteredEmail}
                        onResendCodeHandler={async () => {
                            setCodeFeedback(null);
                            const response =
                                await api.User.SendRegistrationCodeEmail.mutate(
                                    {
                                        Email: enteredEmail,
                                    },
                                );
                            if (response.verificationCode) {
                                // Used in preview mode to pre-fill the code.
                                window.localStorage.setItem(
                                    "re-preview-registration-verification-code",
                                    response.verificationCode,
                                );
                            }
                            setCodeFeedback({
                                success: {
                                    code: "VERIFICATION_CODE_RESENT",
                                },
                            });
                            setTimeout(() => {
                                setCodeFeedback(null);
                            }, 5 * 1000);
                        }}
                        onContinueHandler={async ({
                            firstDigit,
                            secondDigit,
                            thirdDigit,
                            fourthDigit,
                        }) => {

                            const response =
                                await api.User.FindRegistrationToken.mutate({
                                    webappOrigin,
                                    email: enteredEmail,
                                    verificationCode: `${firstDigit}${secondDigit}${thirdDigit}${fourthDigit}`,
                                });

                            if (response.error) {
                                setCodeFeedback(response);
                                setTimeout(() => {
                                    setCodeFeedback(null);
                                }, 5 * 1000);
                                return;
                            }

                            // NOTE: We redirect user instead of simply switching to next step in order
                            //       to use the same approach as '/register/from-email' (with qs data from email button) for consistency.
                            window.location.href = response.redirectUrl;
                        }}
                    />
                );

            case 'CREATE_PASSWORD':
                return (
                    <CreatePasswordComponent
                        feedback={codeFeedback}
                        onContinueHandler={({ password }) => {
                            (async function () {
                                setCodeFeedback(null);

                                // setPassword(password);
                                // setEnteredEmail(email);

                                const isPreview = window.localStorage.getItem(
                                    "re-unlock-preview-token",
                                );
                                if (isPreview) {
                                    window.localStorage.setItem(
                                        "re-preview-register-last-email",
                                        qs.get("email"),
                                    );
                                    window.localStorage.setItem(
                                        "re-preview-register-last-password",
                                        password,
                                    );
                                }

                                const hashedPassword = hashPassword(password);

                                const response = await api.User.Register.mutate(
                                    {
                                        password: hashedPassword,
                                        email: qs.get("email"),
                                        token: qs.get("token"),
                                    },
                                );

                                if (response.error) {
                                    setCodeFeedback(response);
                                } else if (response.success) {
                                    const { role } = response.success.login;

                                    // adminContext.selectRole(role, false);
                                    navigate(`/${role}/dashboard`);
                                } else {
                                    throw new Error(
                                        `Invalid response: ${JSON.stringify(
                                            response,
                                        )}`,
                                    );
                                }
                            })();
                        }}
                        email={qs.get("email")}
                        mode={qs.get("mode")}
                        role={qs.get("role")}
                    />
                );

            case 'UPDATE_PASSWORD':
                return (
                    <UpdatePasswordComponent
                        feedback={codeFeedback}
                        onContinueHandler={async ({ password }) => {
                            setCodeFeedback(null);

                            const hashedPassword = hashPassword(password);

                            const response =
                                await api.User.UpdatePassword.mutate({
                                    password: hashedPassword,
                                    token: qs.get("token"),
                                });

                            if (response.error) {
                                setCodeFeedback(response);
                                return;
                            }

                            return response;
                        }}
                        email={qs.get("email")}
                    />
                );
        }
    };

    return (
        <Flex
            flexDirection={"column"}
            alignItems={"center"}
            bg={"#fff"}
            minHeight={"100vh"}
            w={"100%"}
            maxW={"100%"}
            justifyContent={"space-between"}
            position={"relative"}
            overflowX={"hidden"}
        >
            <HStack
                marginTop={"38px"}
                justifyContent={"start"}
                w={"100%"}
                maxW={"887px"}
                paddingLeft={"20px"}
                paddingRight={"20px"}
            >
                <Image src={logo} alt="logo" w={"117px"} h={"40px"} />
            </HStack>

            {renderContent()}

            {currentStep >= 1 && (
                <VStack
                    zIndex="100"
                    position={"absolute"}
                    right={"-20px"}
                    w="314px"
                    h={"170px"}
                    top={"calc(20% - 85px)"}
                    border={"0.5px solid #136AFF"}
                    borderRadius={"20px"}
                    p={"22px 24px"}
                    alignItems={"flex-start"}
                    backgroundColor="#FFFFFF"
                >
                    <Heading size={"md"} color={"#032E59"}>
                        Trouble signing in?
                        <br />
                        We can help.
                    </Heading>
                    <Text color={"#032E59"}>Which issue are you facing?</Text>
                    <Select
                        icon={
                            <Icon
                                color={"#136AFF !important"}
                                fontSize={"10px"}
                                as={BiSolidDownArrow}
                            />
                        }
                        borderRadius={"37px"}
                        borderColor={"#ACCBFF"}
                        placeholder="Please select an option"
                        onChange={(e) => {
                            window.open(
                                `https://rellie.com/troubleshooting-faq#${e.target.value}`,
                            );
                        }}
                    >
                        <option value="reset">Password troubles</option>
                        <option value="verify">
                            Verification code not received
                        </option>
                        <option value="error">Error message</option>
                        <option value="forgot">
                            Forgot email used to sign in
                        </option>
                        <option value="page">Page keeps loading</option>
                        <option value="email-does-not-exist">
                            Email does not exist!
                        </option>
                    </Select>
                </VStack>
            )}
            <Footer />
        </Flex>
    );
};
