import React, { FunctionComponent } from "react";
import { FormControl, FormErrorMessage, VStack, Select } from "@chakra-ui/react";
import { UseFormRegister } from "react-hook-form";

type Props = {
    placeholder: string;
    required?: boolean;
    name: string;
    options: any;
    register: UseFormRegister<any>;
    error?: string;
    rest?: any;
};

export const SimpleSelect: FunctionComponent<Props> = ({
    placeholder,
    required = true,
    name,
    options,
    register,
    error,
    rest,
}) => {
    return (
        <FormControl isInvalid={Boolean(error)}>
            <VStack>
                <Select
                    {...(register && register(name, { required }) || {})}
                    name={name}
                    placeholder={placeholder}
                    {...rest}
                >
                    {options.map(({value, label}, i) =>
                        <option key={i} value={value}>{label}</option>
                    )}
                </Select>
                <FormErrorMessage>{error}</FormErrorMessage>
            </VStack>
        </FormControl>
    );
};
