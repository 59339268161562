import React, { FunctionComponent, useState } from "react";
import {
    AbsoluteCenter,
    Box,
    Button,
    Checkbox,
    Divider,
    Grid,
    GridItem,
    Heading,
    HStack,
    Image,
    Stack,
    Text,
    VStack,
} from "@chakra-ui/react";
import { BsCheck2, BsX } from "react-icons/bs";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { InputForm } from "../common/InputForm";
import { AdminFillFormButton } from "../../../lib/app/AdminFillFormButton";
import { Link as ReactRouterLink, useLocation } from "@reach/router";
import googleIcon from "../../assets/google-icon.png";
import { Dot } from "../common/Dot";
import { useApp } from "../../../lib/app/App";
import { ErrorMessage } from "../common/ErrorMessage"

type Props = {
    feedback?: any;
    onContinueHandler: any;
    email: string;
};
const schema = z.object({
    email: z.string().email(),
    password: z
        .string()
        .min(1, "Password is required")
        .min(8, "Password must have more than 8 characters")
        .refine(
            (value) =>
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/.test(
                    value,
                ),
            "Password must contain at least one uppercase letter, one lowercase letter, one number and one special character",
        ),
    confirmPassword: z.string().min(1, "Password confirmation is required"),
});

export const UpdatePasswordComponent: FunctionComponent<Props> = ({
    feedback,
    onContinueHandler,
    email,
}) => {
    const { search } = useLocation();
    const qs = new URLSearchParams(search);
    let finalSchema = schema;

    finalSchema = finalSchema.refine(
        (data) => data.password === data.confirmPassword,
        {
            path: ["confirmPassword"],
            message: "Passwords do not match",
        },
    );

    const {
        register,
        formState: { errors },
        getValues,
        clearErrors,
        setValue,
        watch,
        handleSubmit,
    } = useForm({
        resolver: zodResolver(finalSchema),
        defaultValues: {
            email: email || "",
            password: "",
            confirmPassword: "",
        },
    });
    const password = watch("password");

    const [updated, setUpdated] = useState(false);

    function onAutofill() {
        const isPreview = window.localStorage.getItem(
            "re-unlock-preview-token",
        );
        if (isPreview) {
            if (!getValues("password")) {
                setValue("password", "123!abc!ABC");
                setValue("confirmPassword", "123!abc!ABC");
            }
        }
    }

    return (
        <>
            <HStack
                zIndex={"2"}
                spacing={"144px"}
                mb={"80px"}
                bg={"#fff"}
                borderRadius={"20px"}
            >
                <VStack
                    bg={"rgba(19, 106, 255, 0.2)"}
                    w={"100%"}
                    maxW={"420px"}
                    p={"30px"}
                    boxShadow={"0px 4px 14px rgba(19, 106, 255, 0.2)"}
                    borderRadius={"20px"}
                    spacing={"11px"}
                >
                    <Heading
                        textAlign={"left"}
                        w={"100%"}
                        fontSize={"26px"}
                        size={"md"}
                        lineHeight={"20px"}
                        color={"#032E59"}
                    >
                        Create a new password
                    </Heading>

                    { updated && (
                        <>
                            <VStack alignItems={"flex-start"} style={{padding: "20px"}}>
                                <Text fontSize={"16px"}>
                                    Your password has been updated!
                                </Text>
                            </VStack>

                            <Text alignSelf={"center"}>
                                Back to {" "}
                                <a
                                    onClick={() => {
                                        window.location.href = '/';
                                    }}
                                    style={{ color: "#0000FF" }}
                                >
                                    login
                                </a>
                                .
                            </Text>
                        </>
                    )}
                    
                    {!updated && (
                        <>
                            <VStack alignItems={"flex-start"}>
                                <AdminFillFormButton onClick={onAutofill} />

                                <InputForm
                                    placeholder={"Enter email address"}
                                    name={"email"}
                                    required={true}
                                    register={register}
                                    height={"40px"}
                                    width={"354px"}
                                    type={"email"}
                                    rest={{
                                        fontSize: "14px",
                                        border: "1px solid #ACCBFF",
                                        borderRadius: "37px",
                                        bg: "black.100",
                                        color: "gray.800",
                                        sx: {
                                            "&[disabled],& [aria-disabled=true],&[aria-disabled]":
                                                {
                                                    opacity: 1,
                                                },
                                        },
                                        isDisabled: !!email,
                                    }}
                                    error={
                                        errors?.email?.message as
                                            | string
                                            | undefined
                                    }
                                />
                            </VStack>
                            <VStack alignItems={"flex-start"}>
                                <Text fontSize={"12px"}>Create password</Text>
                                <InputForm
                                    placeholder={"Enter password"}
                                    name={"password"}
                                    required={true}
                                    register={register}
                                    height={"40px"}
                                    width={"354px"}
                                    type={"password"}
                                    rest={{
                                        fontSize: "14px",
                                        border: "1px solid #ACCBFF",
                                        borderRadius: "37px",
                                        bg: "#ffffff",
                                    }}
                                    error={
                                        errors?.password?.message as
                                            | string
                                            | undefined
                                    }
                                />
                            </VStack>
                            <VStack
                                alignItems={"flex-start"}
                                color={"#032E59"}
                                fontSize={"9px"}
                                w={"100%"}
                            >
                                <Text>Password must contain:</Text>
                                <Grid templateColumns={"1fr 1fr"} w={"100%"}>
                                    <GridItem
                                        display={"flex"}
                                        alignItems={"center"}
                                    >
                                        {password.length >= 8 ? (
                                            <BsCheck2 color={"#1B998B"} />
                                        ) : (
                                            <BsX color={"#FF0000"} />
                                        )}
                                        At least 8 characters
                                    </GridItem>
                                    <GridItem
                                        display={"flex"}
                                        alignItems={"center"}
                                    >
                                        {/\d/.test(password) ? (
                                            <BsCheck2 color={"#1B998B"} />
                                        ) : (
                                            <BsX color={"#FF0000"} />
                                        )}
                                        At least 1 number
                                    </GridItem>
                                    <GridItem
                                        display={"flex"}
                                        alignItems={"center"}
                                    >
                                        {/[A-Z]/.test(password) ? (
                                            <BsCheck2 color={"#1B998B"} />
                                        ) : (
                                            <BsX color={"#FF0000"} />
                                        )}
                                        At least 1 uppercase letter
                                    </GridItem>
                                    <GridItem
                                        display={"flex"}
                                        alignItems={"center"}
                                    >
                                        {/[a-z]/.test(password) ? (
                                            <BsCheck2 color={"#1B998B"} />
                                        ) : (
                                            <BsX color={"#FF0000"} />
                                        )}
                                        At least 1 lowercase letter
                                    </GridItem>
                                    <GridItem
                                        display={"flex"}
                                        alignItems={"center"}
                                    >
                                        {/[^A-Za-z0-9]/.test(password) ? (
                                            <BsCheck2 color={"#1B998B"} />
                                        ) : (
                                            <BsX color={"#FF0000"} />
                                        )}
                                        At least 1 special character
                                    </GridItem>
                                </Grid>
                            </VStack>

                            <VStack alignItems={"flex-start"}>
                                <Text fontSize={"12px"}>Confirm password</Text>
                                <InputForm
                                    placeholder={"Confirm Password"}
                                    name={"confirmPassword"}
                                    required={true}
                                    register={register}
                                    height={"40px"}
                                    width={"354px"}
                                    type={"password"}
                                    rest={{
                                        fontSize: "14px",
                                        border: "1px solid #ACCBFF",
                                        borderRadius: "37px",
                                        bg: "#ffffff",
                                        onChange: () => {
                                            clearErrors("confirmPassword");
                                        },
                                    }}
                                    error={
                                        errors?.confirmPassword?.message as
                                            | string
                                            | undefined
                                    }
                                />
                            </VStack>
                            <VStack w={"100%"} alignItems={"start"}>

                                <ErrorMessage feedback={feedback}/>

                            </VStack>
                            <Button
                                bg={"#136AFF"}
                                borderRadius={"30px"}
                                width={"171px"}
                                minHeight={"40px"}
                                color={"#FFFFFF"}
                                fontWeight={"900"}
                                fontSize={"16px"}
                                lineHeight={"20px"}
                                onClick={handleSubmit(async (data) => {

                                    const response = await onContinueHandler(data);

                                    if (response?.success) {
                                        setUpdated(true);
                                    }
                                })}
                            >
                                Set New Password
                            </Button>
                        </>
                    )}
                </VStack>
            </HStack>
        </>
    );
};
