import React, { useState, useEffect } from "react";
import {
    VStack,
    HStack,
    Text,
    Box,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Button,
    Tabs,
    TabList,
    Tab,
} from "@chakra-ui/react";
import { SimpleSelect } from "../../components/common/SimpleSelect";
import { Loading } from "../../components/common/Loading";
import { useApp } from "../../../lib/app/App";
import { get } from "lodash";


export const PartyStrategyEditor = ({ editContext }) => {

    const { useData, api } = useApp();

    const editorData = useData("Admin.Data.Editor.Data", {
        editorName: 'Party_Strategy',
        Id_Strategy: editContext.recordId
    });

    const columns = [
        {
            field: "Id"
        },
        {
            label: "Grade",
            field: ["ScreeningByGrade", "Grade"],
        },
        {
            label: "ScreeningByGrade (Cognitive Area & Skill)",
            field: "Id_ScreeningByGrade",
            value: (record) => {
                return (
                    <>
                        ({get(record, ["ScreeningByGrade", "Id_CognitiveArea"])}) {get(record, ["ScreeningByGrade", "CognitiveArea", "Cognitive"])}
                        <br/>  
                        ({get(record, ["ScreeningByGrade", "Id_Skill"])}) {get(record, ["ScreeningByGrade", "Skill", "Function"])}
                    </>
                );
            }
        },
        {
            label: "Question",
            value: (record) => {
                return `(${get(record, "Id_Question")}) ${get(record, ["Question", "QuestionText"])}`;                
            }
        },
        {
            label: "Answer",
            value: (record) => {
                return `(${get(record, "Id_Answer")}) ${get(record, ["Answer", "AnswerText"])}`;                
            }
        },
    ];

    const [selectedGrade, setSelectedGrade] = useState('all');

    const [selectedId_ScreeningByGrade, setSelectedId_ScreeningByGrade] = useState(null);
    const [selectedId_Question, setSelectedId_Question] = useState(null);
    const [selectedId_Answer, setSelectedId_Answer] = useState(null);

    useEffect(() => {
        setSelectedId(null);
        setSelectedId_ScreeningByGrade(null);
        setSelectedId_Question(null);
        setSelectedId_Answer(null);
    }, [selectedGrade]);

    useEffect(() => {
        setSelectedId(null);
        setSelectedId_Question(null);
        setSelectedId_Answer(null);
    }, [selectedId_ScreeningByGrade]);

    useEffect(() => {
        setSelectedId(null);
        setSelectedId_Answer(null);
    }, [selectedId_Question]);

// console.log('selectedGrade', selectedGrade);
// console.log('selectedId_ScreeningByGrade', selectedId_ScreeningByGrade);
// console.log('selectedId_Question', selectedId_Question);
// console.log('selectedId_Answer', selectedId_Answer);


    const fieldValuesData = useData("Admin.Data.Editor.FieldValues", {
        editorName: 'Party_Strategy',
        Grade: (selectedGrade === 'all') ? 0 : parseInt(selectedGrade),
        context: {
            Id_ScreeningByGrade: parseInt(selectedId_ScreeningByGrade || '0'),
            Id_Question: parseInt(selectedId_Question || '0'),
        }
    });    

// console.log('fieldValuesData', fieldValuesData.data);

    const [lastCreatedId, setLastCreatedId] = useState(null);
    const [selectedId, setSelectedId] = useState(null);
    
    if (!editorData.data || !fieldValuesData.data) {
        return <Loading/>
    };

    const onCreate  = async () => {

        // Ensure that this combination does not yet exist.
        const found = editorData.data.filter((record) => {
            return (
                record.ScreeningByGrade.Grade === parseInt(selectedGrade) &&
                record.Id_ScreeningByGrade === parseInt(selectedId_ScreeningByGrade) &&
                record.Id_Question === parseInt(selectedId_Question) &&
                record.Id_Answer === parseInt(selectedId_Answer)
            );
        });
        if (found.length) {
            alert('This combination already exists!');
        }

        const { Id } = await api.Admin.Data.Editor.Data.CreateRecord.mutate({
            table: "Party_Strategy",
            data: {
                Id_Strategy: editContext.recordId,
                Id_ScreeningByGrade: parseInt(selectedId_ScreeningByGrade),
                Id_Question: parseInt(selectedId_Question),
                Id_Answer: parseInt(selectedId_Answer)
            }
        });
        editorData.refetch();
        setLastCreatedId(Id);
        setSelectedId_Answer(null);        
        setSelectedId(null);
    }

    const onDoubleClick = (event) => {
        const rowEl = event.target.closest("tr");
        const cellEl = event.target.closest("td");

        if (
            !rowEl || !cellEl || !rowEl.dataset || !cellEl.dataset ||
            cellEl.dataset.recordField !== 'Id'
        ) {
            return;
        }

        setSelectedId(parseInt(rowEl.dataset.recordId));
    }

    const onCancel = async () => {
        setSelectedId(null);
    }

    const onDelete = async () => {

        const { Id } = await api.Admin.Data.Editor.Data.DeleteRecord.mutate({
            table: "Party_Strategy",
            Id: selectedId,
        });
        editorData.refetch();
        setSelectedId(null);
    }

    const grades = [
        '3',
        '4',
        '5',
        '6',
        '7',
        '8',
        '9',
        '10',
    ];

    const gradeMappings = {};

    editorData.data.forEach((record) => {
        gradeMappings[`${record.ScreeningByGrade.Grade}`] = gradeMappings[`${record.ScreeningByGrade.Grade}`] || [];
        gradeMappings[`${record.ScreeningByGrade.Grade}`].push(record.Id);
    });

// console.log('editorData.data', editorData.data);    

    fieldValuesData.data.ScreeningByGrade?.forEach((record) => {
        record.label = [
            `(${get(record, ["CognitiveArea", "Id"])}) ${get(record, ["CognitiveArea", "Cognitive"])}`,
            `(${get(record, ["Skill", "Id"])}) ${get(record, ["Skill", "Function"])}`
        ].join(' - ');
        record.value = record.Id;
    });
    fieldValuesData.data.Question?.forEach((record) => {
        record.label = [
            `(${record.Id}) ${record.QuestionText}`,
        ].join(' - ');
        record.value = record.Id;
    });
    if (fieldValuesData.data.Answer) {

        const existingAnswers = {};
        editorData.data.forEach((record) => {
            if (
                record.ScreeningByGrade.Grade === parseInt(selectedGrade) &&
                record.Id_ScreeningByGrade === parseInt(selectedId_ScreeningByGrade) &&
                record.Id_Question === parseInt(selectedId_Question)
            ) {
                existingAnswers[`${record.Id_Answer}`] = true;
            }
        });

        fieldValuesData.data.Answer = fieldValuesData.data.Answer.filter((record) => {
            return !existingAnswers[`${record.Id}`];
        }).map((record) => {
            record.label = [
                `(${record.Id}) ${record.AnswerText}`,
            ].join(' - ');
            record.value = record.Id;
            return record;
        });
    }

    return (
        <VStack alignItems={"start"} width="100%">

            <Text fontSize={"14px"} textAlign={"center"} p="15px;" paddingBottom="0px;">
                <b>Strategy {editContext.existingRecord.Id}:</b> {editContext.existingRecord.Strategy}
            </Text>            

            <Text fontSize={"14px"} textAlign={"center"} p="15px;">
                This editor maps <b>Strategies</b> to <b>ScreeningByGrade (Cognitive Area & Skill)</b>, <b>Question</b> & <b>Answer</b> combinations for each <b>Grade</b>.
            </Text>

            {selectedId && (
                <HStack justifyContent={"space-between"} mb="15px">
                    <Text fontSize={"14px"} textAlign={"center"} p="15px;">
                        Delete <code>Party_Strategy</code> record with id <b>{selectedId}</b>?
                    </Text>
                    <Button
                        backgroundColor="#FF0000"
                        onClick={onDelete}
                    >Delete</Button>
                    <Button
                        onClick={onCancel}
                    >Cancel</Button>
                </HStack>
            )}

            <HStack justifyContent={"space-between"} mb="15px">
                <Tabs variant='enclosed' defaultIndex={(selectedGrade === 'all') ? 0 : grades.indexOf(selectedGrade) + 1} onChange={(index) => {
                    setSelectedId(null);
                    if (index === 0) {
                        setSelectedGrade('all');
                        return;
                    }
                    setSelectedGrade(grades[index-1]);
                }}>
                    <TabList>
                        <Tab>All Grades</Tab>
                        {grades.map((grade, i) => (
                            <Tab key={i}>Grade:&nbsp;<b>{grade}</b>&nbsp;({(gradeMappings[grade] && gradeMappings[grade].length) || 0})</Tab>
                        ))}
                    </TabList>
                </Tabs>
            </HStack>

            <TableContainer mt={"18px"} height="60vh" width="100%" overflowY="scroll" onDoubleClick={onDoubleClick}>
                <Table
                    variant="simple"
                    bg={"#FFFFFF"}
                    borderRadius={"16px"}
                    size="sm"
                    sx={{
                        "& th": {
                            color: "#5F6368",
                            fontSize: "10px",
                            textTransform: "capitalize",
                        },
                        "& td": {
                            fontSize: "12px",
                        },
                    }}
                >
                    <Thead>
                        <Tr>
                            {columns.map((column, i) => (
                                <Th
                                    key={i}
                                    position={"sticky"}
                                    top={0}
                                    backgroundColor={"#ffffff"}
                                >{(column.label || column.field)}</Th>
                            ))}
                        </Tr>
                        {selectedGrade !== 'all' && fieldValuesData.data.ScreeningByGrade && (
                            <Tr>
                                {columns.map((column, i) => (

                                    <Th
                                        key={i}
                                        position={"sticky"}
                                        top={"22px"}
                                        backgroundColor={"#fafafa"}
                                        padding="0px"
                                    >
                                        {column.field === 'Id' && (
                                            <Button
                                                disabled={(!selectedId_ScreeningByGrade || !selectedId_Question || !selectedId_Answer)}
                                                onClick={onCreate}
                                            >Create</Button>
                                        )}
                                        {column.label === 'Grade' && (
                                            <Box
                                                whiteSpace={"normal"}
                                                m="1px"
                                                p="7px 17px 7px 15px"
                                            >
                                                {selectedGrade}
                                            </Box>
                                        )}
                                        {column.field === 'Id_ScreeningByGrade' && (
                                            <Box
                                                whiteSpace={"normal"}
                                                m="1px"
                                                p="7px 17px 7px 15px"
                                            >
                                                <SimpleSelect
                                                    // register={register}
                                                    name="Id_ScreeningByGrade"
                                                    placeholder='Select'
                                                    options={fieldValuesData.data.ScreeningByGrade}
                                                    rest={{
                                                        value: selectedId_ScreeningByGrade || '',
                                                        onChange: (event) => {
                                                            setSelectedId_ScreeningByGrade(event.target.value)
                                                        }
                                                    }}
                                                />
                                            </Box>
                                        )}
                                        {column.label === 'Question' && fieldValuesData.data.Question && (
                                            <Box
                                                whiteSpace={"normal"}
                                                m="1px"
                                                p="7px 17px 7px 15px"
                                            >
                                                <SimpleSelect
                                                    name="Id_Question"
                                                    placeholder='Select'
                                                    options={fieldValuesData.data.Question}
                                                    rest={{
                                                        value: selectedId_Question || '',
                                                        onChange: (event) => {
                                                            setSelectedId_Question(event.target.value)
                                                        }
                                                    }}
                                                />
                                            </Box>
                                        )}
                                        {column.label === 'Answer' && fieldValuesData.data.Answer && (
                                            <Box
                                                whiteSpace={"normal"}
                                                m="1px"
                                                p="7px 17px 7px 15px"
                                            >
                                                <SimpleSelect
                                                    name="Id_Answer"
                                                    placeholder='Select'
                                                    options={fieldValuesData.data.Answer}
                                                    rest={{
                                                        value: selectedId_Answer || '',
                                                        onChange: (event) => {
                                                            setSelectedId_Answer(event.target.value)
                                                        }
                                                    }}
                                                />
                                            </Box>
                                        )}
                                    </Th>
                                ))}
                            </Tr>
                        )}
                    </Thead>
                    <Tbody>                     
                        {editorData.data.map((record, i) => {

                            if (selectedGrade !== 'all' && `${record.ScreeningByGrade.Grade}` !== selectedGrade) {
                                return '';
                            }

                            return (
                                <Tr
                                    key={i}
                                    data-record-id={record.Id}
                                    backgroundColor={(record.Id === selectedId) ? '#FF0000' : (record.Id === lastCreatedId) ? '#b9ec5c' : ''}
                                >
                                    {columns.map((column, i) => (
                                        <Td
                                            key={i}
                                            data-record-field={column.label || column.field}
                                            p="0px"
                                        >
                                            <Box
                                                whiteSpace={"normal"}
                                                m="1px"
                                                p="7px 17px 7px 15px"
                                            >
                                                {(
                                                    (column.value && column.value(record)) ||
                                                    get(record, column.field) ||
                                                    ""
                                                )}
                                            </Box>
                                        </Td>
                                    ))}
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>
            </TableContainer>
        </VStack>
    );
}
