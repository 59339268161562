import React, { FunctionComponent } from "react";
import { FormControl, FormErrorMessage, Input, VStack } from "@chakra-ui/react";
import { UseFormRegister } from "react-hook-form";

type Props = {
    placeholder: string;
    height?: string | { base: string; lg: string };
    width?:
        | string
        | { base: string; lg: string }
        | { base: string; sm: string };
    required?: boolean;
    name: string;
    register?: UseFormRegister<any>;
    type?: string;
    error?: string;
    rest?: any;
};

export const InputForm: FunctionComponent<Props> = ({
    placeholder,
    height = { base: "53px", lg: "68px" },
    width = "268px",
    required = true,
    name,
    register,
    type = "text",
    error,
    rest,
}) => {
    return (
        <FormControl isInvalid={Boolean(error)}>
            <VStack>
                <Input
                    type={type}
                    height={height}
                    width={width}
                    minWidth={width}
                    minHeight={height}
                    placeholder={placeholder}
                    textAlign={"center"}
                    fontSize={{ base: "16px", lg: "20px" }}
                    borderRadius={"10px"}
                    border={"0.5px solid rgba(0, 0, 0, 0.19)"}
                    color={"black"}
                    {...((register && register(name, { required })) || {})}
                    {...rest}
                />
                <FormErrorMessage>{error}</FormErrorMessage>
            </VStack>
        </FormControl>
    );
};
