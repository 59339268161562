import {
  __publicField
} from "./chunk-NHABU752.mjs";

// src/modal-manager.ts
import { useEffect } from "react";
var ModalManager = class {
  constructor() {
    __publicField(this, "modals");
    this.modals = [];
  }
  add(modal) {
    this.modals.push(modal);
  }
  remove(modal) {
    this.modals = this.modals.filter((_modal) => _modal !== modal);
  }
  isTopModal(modal) {
    const topmostModal = this.modals[this.modals.length - 1];
    return topmostModal === modal;
  }
};
var manager = new ModalManager();
function useModalManager(ref, isOpen) {
  useEffect(() => {
    if (isOpen) {
      manager.add(ref);
    }
    return () => {
      manager.remove(ref);
    };
  }, [isOpen, ref]);
}

export {
  manager,
  useModalManager
};
