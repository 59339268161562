
import {
    Box,
    Button,
    HStack,
    Menu,
    MenuButton,
    MenuItemOption,
    MenuList,
} from "@chakra-ui/react";
import React from "react";
import { useApp, isAdminToolsEnabled } from './App';

export function AdminSwitchToLastUserButton () {

    const { onImpersonatePrevious } = useApp();

    if (!isAdminToolsEnabled()) {
        return null;
    }

    if (!onImpersonatePrevious.Id) {
        return null;
    }

    return (
        <Button
            size="xs"
            color="black"
            backgroundColor={"red"}
            onClick={onImpersonatePrevious}
        >
            Last User ({onImpersonatePrevious.role})
        </Button>
    );
}
