import React, { FunctionComponent, useState } from "react";
import {
    Button,
    Heading,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    VStack,
} from "@chakra-ui/react";
import { MdDownloading } from "react-icons/md";

type Props = {
    isOpen: boolean;
    modalContext: {
        Grade: number;
        Class: string;
    } | null;
    onClose: () => void;
    onUpload: (file: File) => void;
};

export const UploadCsvModal: FunctionComponent<Props> = ({
    isOpen,
    modalContext,
    onClose,
    onUpload,
}) => {
    const [file, setFile] = useState<File | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [isDragActive, setIsDragActive] = useState(false);
    const [isValid, setIsValid] = useState(false);

    const handleDragOver = (e: React.DragEvent) => {
        e.preventDefault();
        setIsDragActive(true);
    };

    const handleDragLeave = () => {
        setIsDragActive(false);
    };

    const handleDrop = (e: React.DragEvent) => {
        e.preventDefault();
        setIsDragActive(false);
        const droppedFile = e.dataTransfer.files[0];
        setFile(droppedFile);
        setIsValid(droppedFile.type === "text/csv");
    };

    const handleClick = () => {
        let input = document.createElement("input");
        input.type = "file";
        input.accept = ".csv";
        input.onchange = () => {
            const selectedFile = input.files ? input.files[0] : null;
            setFile(selectedFile);
            setIsValid(selectedFile?.type === "text/csv");
        };
        input.click();
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (!isValid) return;
        if (!file) {
            setError("No file selected");
            return;
        }
        onUpload(file);
        onClose();
    };

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent
                borderRadius={"15px"}
                p={5}
                minW={"586px"}
                minH={"320px"}
            >
                <ModalHeader color={"#032E59"}>
                    <Heading fontSize={"26px"} size={"sm"}>
                        Upload CSV to Grade {modalContext.Grade}
                        {"Class "}
                        {modalContext.Class}
                    </Heading>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    color={"#5F6368"}
                    fontSize={"14px"}
                    gap={"20px"}
                >
                    <VStack
                        onClick={handleClick}
                        onDrop={handleDrop}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragLeave}
                        w="476px"
                        h="126px"
                        mx="auto"
                        my="4"
                        bg="#FFFFFF"
                        border="1px solid #ACCBFF"
                        borderRadius="16px"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        textAlign="center"
                        cursor="pointer"
                    >
                        <MdDownloading size={"33px"} />
                        <Text fontSize="md" color="black">
                            {file ? (
                                file.name
                            ) : (
                                <>
                                    Drag CSV Here or,
                                    <br /> click to browse
                                </>
                            )}
                        </Text>
                    </VStack>
                    {file && (
                        <Text color={isValid ? "green" : "red"}>
                            {isValid ? "Valid file" : "Invalid file"}
                        </Text>
                    )}
                    <HStack>
                        <Button
                            width={"115px"}
                            borderRadius={"37px"}
                            variant="outline"
                            border={"1px solid #ACCBFF"}
                            color={"black"}
                            onClick={onClose}
                        >
                            <Text>Cancel</Text>
                        </Button>
                        <Button
                            width={"115px"}
                            bg={"#136AFF"}
                            borderRadius={"37px"}
                            color={"white"}
                            disabled={!isValid}
                            onClick={handleSubmit}
                        >
                            Upload
                        </Button>
                    </HStack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
