
import React, { useState, useEffect } from 'react';
import { Box } from "@chakra-ui/react";

export function AdminDisableToolsBookmarkletLink () {

    function createMarkup () {
        const bookmarkletUrls = [
            // TODO: Get URL's from 'useApp()'.
            `https://pal-api-pre.rellie.dev/api/admin/preview/bookmarklet?action=remove&http-cookie-only=1`,
            `https://pal-api-stage.rellie.dev/api/admin/preview/bookmarklet?action=remove&http-cookie-only=1`,
            `https://pal-api-prod.rellie.dev/api/admin/preview/bookmarklet?action=remove`
        ];
        return {__html: (`
            <a href="javascript: (() => {
                ${JSON.stringify(bookmarkletUrls).replace(/"/g, `'`)}.map(function (url) {
                    const script = document.createElement('script');
                    script.src = url;
                    script.async = true;
                    document.head.appendChild(script); 
                });
            })();">
                Disable Admin Tools Bookmarklet
            </a>
        `).replace(/\n/g, '').replace(/\s+/g, ' ')};
    }

    return (
        <>
            <Box
                border="1px solid black"
                padding="5px"
                paddingLeft="15px"
                paddingRight="15px"
                cursor={"pointer"}
                backgroundColor={"#dcdcdc"}
                dangerouslySetInnerHTML={createMarkup()}
            />
        </>
    );
}
