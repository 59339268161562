import React, { FunctionComponent } from "react";
import { RouteComponentProps } from "@reach/router";
import {
    Heading,
    VStack,
    Box,
    HStack,
    Icon,
    Progress,
    Text,
    Flex,
} from "@chakra-ui/react";
import { useApp } from "../../../lib/app/App";
import { AdminEnableToolsBookmarkletLink } from "../../../lib/app/AdminEnableToolsBookmarkletLink";
import { AdminDisableToolsBookmarkletLink } from "../../../lib/app/AdminDisableToolsBookmarkletLink";
import { AdminTestErrorLoggingButton } from "../../../lib/app/AdminTestErrorLoggingButton";
import { MdOutlineAssignment } from "react-icons/md";

export const AdminToolsPage: FunctionComponent<
    RouteComponentProps
> = () => {


    return (
        <VStack alignItems={"flex-start"} spacing={"52px"}>
            <Box marginTop={"50px;"}>
                <Heading size={"md"} color={"#032E59"}>
                    Tools
                </Heading>

                <VStack mt="30px">

                    <AdminEnableToolsBookmarkletLink />

                    <AdminDisableToolsBookmarkletLink />

                    <AdminTestErrorLoggingButton />

                </VStack>

            </Box>
        </VStack>
    );
};
