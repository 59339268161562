
import React, { useState, useEffect } from 'react';
import { Button } from "@chakra-ui/react";
import { useApp } from "./App";


export function AdminTestErrorLoggingButton () {

    const { api, fetch, formatRequestUrl } = useApp();

    let counter = 0;
    function onTestClientExceptionErrorLogging () {
        counter += 1;
        throw new Error(`Test Error (${counter})!`);
    }

    function onTestServerExceptionErrorLogging () {
        api.App.TestError.mutate();
        api.App.TestTRPCError.mutate();
    }

    function onTestRESTServerExceptionErrorLogging () {
        fetch(formatRequestUrl('api', '/api/app/test-rest-error'))
    }

    return (
        <>
            <Button onClick={onTestClientExceptionErrorLogging}>Test Client Exception Error Logging</Button>
            <Button onClick={onTestServerExceptionErrorLogging}>Test Server Exception Error Logging</Button>
            <Button onClick={onTestRESTServerExceptionErrorLogging}>Test REST Server Exception Error Logging</Button>
        </>
    );
}
