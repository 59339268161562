import React, { FunctionComponent } from "react";
import { navigate } from "@reach/router";
import {
    Badge,
    Box,
    Card,
    Heading,
    HStack,
    Icon,
    Progress,
    Text,
} from "@chakra-ui/react";
import { MdOutlineAssignment } from "react-icons/md";
import { BsExclamationLg } from "react-icons/bs";
import { FiTrendingUp } from "react-icons/fi";

type Props = {
    studentName: string;
    questionnairePercentage: number;
    AssessmentId: number;
    RegistrationType: string;
};

export const StudentCard: FunctionComponent<Props> = ({
    studentName,
    questionnairePercentage,
    AssessmentId,
    RegistrationType,
}) => {
    const isReportReady = questionnairePercentage === 100;
    return (
        <Card
            width={"767px"}
            borderRadius={"20px"}
            bg={"white"}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            flexDirection={"column"}
            p={"32px"}
            gap={"12px"}
        >
            <Heading
                fontSize={"20px"}
                fontWeight={700}
                alignSelf={"start"}
                mb={"13px"}
            >
                {studentName}
            </Heading>

            { RegistrationType === 'PARENT' && (
                <HStack
                    justifyContent={"space-between"}
                    w={"100%"}
                    spacing={"12px"}
                    border={"0.5px solid #D9D9D9"}
                    borderRadius={"16px"}
                    p={"14px"}
                    cursor={"pointer"}
                    onClick={() =>
                        navigate(`/assessment/${AssessmentId}/questionnaire`)
                    }
                >
                    <HStack spacing={"28px"}>
                        <Box
                            bg={"#FF4DD1"}
                            borderRadius={"16px"}
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            w={"88px"}
                            h={"80px"}
                        >
                            <Icon
                                color={"white"}
                                fontSize={"40px"}
                                as={MdOutlineAssignment}
                            />
                        </Box>
                        <Heading fontSize={"19px"} fontWeight={700}>
                            QUESTIONNAIRE
                        </Heading>
                    </HStack>
                    <HStack
                        spacing={questionnairePercentage < 100 ? "30px" : "14px"}
                    >
                        {questionnairePercentage < 100 ? (
                            <>
                                <Progress
                                    w={"167px"}
                                    maxW={"167px"}
                                    value={questionnairePercentage}
                                    borderRadius={"11px"}
                                    bg={"rgba(0, 0, 0, 0.08);"}
                                    colorScheme={"brand.pink"}
                                />
                                <Text fontSize={"19px"} fontWeight={500}>
                                    {questionnairePercentage}%
                                </Text>
                            </>
                        ) : (
                            <Text
                                color={"#6C6C6C"}
                                fontWeight={700}
                                fontSize={"15px"}
                            >
                                COMPLETE
                            </Text>
                        )}
                    </HStack>
                </HStack>
            )}

            <HStack
                justifyContent={"space-between"}
                w={"100%"}
                spacing={"12px"}
                border={"0.5px solid #D9D9D9"}
                borderRadius={"16px"}
                p={"14px"}
                cursor={"pointer"}
                onClick={() =>
                    navigate(`/parent/reports`)
                }
            >
                <HStack spacing={"28px"}>
                    <Box
                        bg={
                            isReportReady
                                ? "#136AFF"
                                : "linear-gradient(0deg, rgba(19, 106, 255, 0.10) 0%, rgba(19, 106, 255, 0.10) 100%), #FFF"
                        }
                        borderRadius={"16px"}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        w={"88px"}
                        h={"80px"}
                    >
                        <Icon
                            color={"white"}
                            fontSize={"40px"}
                            as={FiTrendingUp}
                        />
                    </Box>
                    <Heading
                        fontSize={"19px"}
                        fontWeight={700}
                        color={isReportReady ? "black" : "#AAA"}
                    >
                        REPORTS
                    </Heading>
                </HStack>
                <HStack>
                    {isReportReady ? (
                        <Badge
                            w={"72px"}
                            h={"32px"}
                            colorScheme="green"
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            fontSize={"15px"}
                        >
                            READY
                        </Badge>
                    ) : (
                        <>
                            <Text
                                color={"#6C6C6C"}
                                fontWeight={500}
                                fontSize={"13px"}
                                maxW={"187px"}
                            >
                                Please complete questionnaire to create your
                                report
                            </Text>

                            <Box
                                borderRadius={"30px"}
                                bg={"#F56806"}
                                w={"30px"}
                                h={"30px"}
                                display={"flex"}
                                alignItems={"center"}
                                justifyContent={"center"}
                            >
                                <Icon as={BsExclamationLg} />
                            </Box>
                        </>
                    )}
                </HStack>
            </HStack>
        </Card>
    );
};
