import React, { FC, useEffect, useState } from "react";

import { WelcomeComponent } from "../../components/assessment/Welcome";
import { QuestionsComponent } from "../../components/assessment/Questions";
import { CompletedComponent } from "../../components/assessment/Completed";

import {
    RouteComponentProps,
    Router,
    useLocation,
    navigate,
} from "@reach/router";
import { LayOut } from "../../components/common/LayOut";

import { useApp } from "../../../lib/app/App";
import { isNil } from "lodash";
import { Environment } from "../../components/assessment/Environment";

interface QuestionnaireStartCompleteProps {
    complete: boolean;
    firstQuestionPath: string;
}

function QuestionnaireStartComplete({
    complete,
    firstQuestionPath,
    Id_Assessment,
}: QuestionnaireStartCompleteProps & RouteComponentProps) {
    const { useData, useSave } = useApp();
    const userData = useData("User");
    const [selectedEnvironment, setSelectedEnvironment] = useState(null);

    if (!userData.data) return null;

    const showParentDisclaimer = userData.data.login.role === "parent";
    if (complete) {
        return <CompletedComponent />;
    }

    const saveEnvironment = useSave("Assessment.Environment", {
        loadAfterMutation: false
    });

    if (isNil(selectedEnvironment)) {
        return (
            <Environment
                onStartCallback={async (value) => {
                    setSelectedEnvironment(value)
                    await saveEnvironment.save({
                        Id_Assessment,
                        Environment: value
                    });
                }}
            />
        );
    }

    return (
        <WelcomeComponent
            firstQuestionPath={firstQuestionPath}
            dashboardPath={`/${userData.data.login.role}/dashboard`}
            showParentDisclaimer={showParentDisclaimer}
        />
    );
}

export const AssessmentQuestionnairePage: FC<RouteComponentProps> = () => {
    const { useData, useSave } = useApp();

    const location = useLocation();

    const assessmentId = location.pathname.split("/")[2];
    const questionNumber = parseInt(location.pathname.split("/")[4]) || null;

    function makeUrl(questionNumber: string | number = "") {
        return `/assessment/${assessmentId}/questionnaire${
            questionNumber && `/${questionNumber}`
        }`;
    }

    const questionnaireData = useData("Assessment.Questionnaire");

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [assessmentId, questionNumber]);

    useEffect(() => {
        if (!questionnaireData.isFetched || !questionnaireData.data) {
            return;
        }
        if (
            questionnaireData.data.complete ||
            (Object.keys(questionnaireData.data.answers).length === 0 &&
                !/action=start/.test(location.search))
        ) {
            const completeUri = makeUrl();
            if (location.pathname !== completeUri) {
                navigate(completeUri);
            }
            return;
        }
        // Position to the first unanswered question.

        if (/action=(previous|next)/.test(location.search)) {
            // We do not want to reposition as user clicked on previous button.
            return;
        }

        let nextAnswerNumber = -1;
        questionnaireData.data.questions.forEach((question, i) => {
            if (nextAnswerNumber === -1) {
                if (!questionnaireData.data.answers[question.Id]) {
                    nextAnswerNumber = i + 1;
                }
            }
        });

        if (questionNumber != nextAnswerNumber) {
            navigate(makeUrl(nextAnswerNumber));
        }
    });

    const saveAnswer = useSave("Assessment.Questionnaire");

    const onSaveAnswer = async (Id_Question: Number, Id_Answer: Number) => {
        function continueToNext(questionnaireData) {
            const nextQuestionNumber = questionNumber + 1;
            const nextUrl = makeUrl(nextQuestionNumber);

            if (
                questionnaireData.questions[nextQuestionNumber - 1] &&
                questionnaireData.answers[
                    questionnaireData.questions[nextQuestionNumber - 1].Id
                ]
            ) {
                // The next question is already answered
                navigate(`${nextUrl}?action=next`);
            } else {
                navigate(nextUrl);
            }
        }

        if (questionnaireData.data.answers[Id_Question]) {
            // We had an existing answer so we update it and deal with the next button context.

            if (
                JSON.stringify(questionnaireData.data.answers[Id_Question]) ===
                JSON.stringify(Id_Answer)
            ) {
                // Answer has not changed
                continueToNext(questionnaireData.data);
                return;
            }

            // Answer has changed
            const updatedQuestionnaireData = await saveAnswer.save({
                Id_Model: questionnaireData.data.Id_Model,
                Id_Assessment: questionnaireData.data.Id_Assessment,
                Id_Question,
                Id_Answer: JSON.stringify(Id_Answer),
            });
            continueToNext(updatedQuestionnaireData);
            return;
        }

        // No existing answer
        const updatedQuestionnaireData = await saveAnswer.save({
            Id_Model: questionnaireData.data.Id_Model,
            Id_Assessment: questionnaireData.data.Id_Assessment,
            Id_Question,
            Id_Answer: JSON.stringify(Id_Answer),
        });
        continueToNext(updatedQuestionnaireData);
        return;
    };

    if (!questionnaireData.isFetched || !questionnaireData.data) {
        return null;
    }

    return (
        <LayOut>
            <Router>
                <QuestionnaireStartComplete
                    path="/"
                    default
                    complete={questionnaireData.data.complete}
                    firstQuestionPath={`/assessment/${assessmentId}/questionnaire/1?action=start`}
                    Id_Assessment={questionnaireData.data.Id_Assessment}
                />
                {!questionnaireData.data.complete && (
                    <QuestionsComponent
                        path={`/:questionIndex`}
                        questionnaireData={questionnaireData}
                        onSaveAnswer={onSaveAnswer}
                    />
                )}
            </Router>
        </LayOut>
    );
};
