
// @see https://chakra-ui.com/docs/styled-system/style-props#margin-and-padding

export const FocusStyles = {
    Heading: {
        fontFamily: "Nunito",
        fontStyle: "normal",
        fontWeight: "900",
        fontSize: {
            base: "1.625rem",
            lg: "2.1875rem",
        },
        lineHeight: "30px",
        textAlign: "center",
        color: "#032E59",
        w: "100%",
        marginTop: { base: "-40px" },
    },
    Card: {
        bg: "#FFFFFF",
        borderRadius: "30px",
        boxShadow: "0px 4px 54px rgba(19, 106, 255, 0.3)",
        w: { base: "356px", lg: "982px" },
        minH: { base: "400px" },
        h: "auto",
        fontSize: { base: "16px", lg: "20px" },
        lineHeight: "27px",
        fontWeight: "400",
        fontStyle: "normal",
        alignItems: "center",
        textAlign: "center",
        padding: "50px",
        paddingBottom: "0px",
        marginTop: "40px",
        marginBottom: "100px",
    },
    CardHeading: {
        fontSize: { base: "16px", lg: "20px" },
        fontWeight: 700,
        mx: "auto",
        mb: { base: "50px" },
    },
    CardSummary: {
        "mx": "50px",
    },
    BodySection: {
        padding: "0",
        mb: { base: "50px" },
        w: "100%",
        h: "auto",
    },
    Columns: {
        display: "flex",
        gap: {
            base: "16px",
            lg: "9px",
        },
        flexDirection: {
            base: "column",
            lg: "row",
        },
    },
    Button: {
        w: 184,
        h: 43,
        bg: "#136AFF",
        borderRadius: "60px",
        color: "white"
    }
};
