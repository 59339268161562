import React, { FunctionComponent } from "react";
import {
    Box,
    Heading,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
} from "@chakra-ui/react";
import { navigate } from "@reach/router";
import { handleColorPercentage } from "./TeacherClassReport";
import { useApp } from "../../../lib/app/App";

export type CognitiveArea = {
    Id: number;
    label: string;
    colorScheme: string;
};

export type Student = {
    Id: number;
    StudentName: string;
    RegistrationType: string;
    ShareWithTeacher: boolean;
    Id_Assessment: number;
    cognitiveAreas: {
        [key: string]: {
            percentage: number;
        };
    };
};

type Props = {
    selectedClass: string;
    cognitiveAreas: CognitiveArea[];
    students: Student[];
};

export const FullReportTable: FunctionComponent<Props> = ({
    selectedClass,
    cognitiveAreas,
    students,
}) => {

    const { isAdminToolsEnabled } = useApp();

    const displayPercentageNumbers = isAdminToolsEnabled()

    return (
        <>
            <Heading size={"md"} color={"#032E59"}>
                Student Reports
            </Heading>

            {((!cognitiveAreas || !Object.values(cognitiveAreas).length) && (
                <Box color="#FF0000" pt="30px">
                    A student questionnaire has NOT been completed.
                </Box>
            )) || (
                <>
                    <Text>
                        Click on the student to see their personal full report.
                    </Text>
                    {/* <TableContainer
                        mt={"18px"}
                        p={"20px"}
                        minWidth={"100%"}
                        bg={"#E8F2FF"}
                        borderRadius={"20px"}
                        boxSizing={"border-box"}
                    > */}
                        <Table
                            variant="simple"
                            bg={"#FFFFFF"}
                            borderRadius={"16px"}
                            sx={{
                                "& th": {
                                    color: "#5F6368",
                                    fontSize: "10px",
                                    padding: "8px",
                                    // wordBreak: "normal",
                                    whiteSpace: "normal",
                                    maxWidth: "130px",
                                    textAlign: "center",
                                },
                                "thead > tr:hover": {
                                    cursor: "default"
                                },
                                "thead > tr > th:hover": {
                                    color: "#5F6368",
                                    cursor: "default"
                                },
                                "td >  div": {
                                    fontSize: "12px",
                                    color: "#5F6368",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    maxWidth: "100%",
                                },
                                td: {
                                    fontSize: "12px",
                                    padding: "8px",
                                    color: "#5F6368",
                                    minWidth: "130px",
                                    whiteSpace: "nowrap"
                                },
                                "tbody tr td:nth-of-type(even)": {
                                    backgroundColor:
                                        "rgba(239, 246, 255, 0.46)",
                                },
                                "th:nth-of-type(even)": {
                                    backgroundColor:
                                        "rgba(239, 246, 255, 0.46)",
                                },
                                "tbody > tr:hover": {
                                    backgroundColor:
                                        "rgba(239, 246, 255, 0.96)",
                                },
                            }}
                        >
                            <Thead>
                                <Tr>
                                    <Th>Student Name</Th>
                                    {Object.values(cognitiveAreas).map(
                                        (cognitiveArea, i) => (
                                            <Th
                                                key={i}
                                                maxW={"110px"}
                                                // onClick={() => {
                                                //     navigate(
                                                //         `/teacher/reports/${selectedClass}/strategies#strategy-${cognitiveArea.Id}`,
                                                //     );
                                                // }}
                                                _hover={{
                                                    cursor: "pointer",
                                                    color: "#136AFF",
                                                }}
                                            >
                                                {cognitiveArea.label}
                                            </Th>
                                        ),
                                    )}
                                </Tr>
                            </Thead>
                            <Tbody>
                                {students.map((studentData, i) => (
                                    <Tr
                                        key={i}
                                        onClick={() => {
                                            if (
                                                !studentData.Id_Assessment ||
                                                (studentData.RegistrationType ===
                                                    "PARENT" &&
                                                    !studentData.ShareWithTeacher)
                                            ) {
                                                return;
                                            }
                                            navigate(
                                                `/teacher/reports/${selectedClass}/${studentData.Id_Assessment}`,
                                            );
                                        }}
                                        cursor={`${
                                            !studentData.Id_Assessment ||
                                            (studentData.RegistrationType ===
                                                "PARENT" &&
                                                !studentData.ShareWithTeacher)
                                                ? "inherit"
                                                : "pointer"
                                        }`}
                                    >
                                        <Td>{studentData.StudentName}</Td>

                                        {(studentData.cognitiveAreas && (
                                            <>
                                                {(studentData.RegistrationType ===
                                                    "PARENT" &&
                                                    !studentData.ShareWithTeacher && (
                                                        <Td
                                                            colSpan={
                                                                Object.values(
                                                                    cognitiveAreas,
                                                                ).length
                                                            }
                                                        >
                                                            Report not shared by
                                                            Parent.
                                                        </Td>
                                                    )) ||
                                                    Object.values(
                                                        cognitiveAreas,
                                                    ).map(
                                                        (cognitiveArea, i) => {
                                                            const percentage =
                                                                (studentData
                                                                    .cognitiveAreas[
                                                                    cognitiveArea
                                                                        .Id
                                                                ] &&
                                                                    studentData
                                                                        .cognitiveAreas[
                                                                        cognitiveArea
                                                                            .Id
                                                                    ]
                                                                        .percentage) ||
                                                                0;

                                                            return (
                                                                <Td key={i}>
                                                                    <Box
                                                                        m={
                                                                            "auto"
                                                                        }
                                                                        w={
                                                                            "15px"
                                                                        }
                                                                        h={
                                                                            "15px"
                                                                        }
                                                                        bg={handleColorPercentage(
                                                                            percentage,
                                                                            false,
                                                                        )}
                                                                        borderRadius={
                                                                            "50%"
                                                                        }
                                                                    >{displayPercentageNumbers && percentage}</Box>
                                                                </Td>
                                                            );
                                                        },
                                                    )}
                                            </>
                                        )) || (
                                            <Td
                                                colSpan={
                                                    Object.values(
                                                        cognitiveAreas,
                                                    ).length
                                                }
                                            >
                                                Report is incomplete.
                                            </Td>
                                        )}
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    {/* </TableContainer> */}
                </>
            )}
        </>
    );
};
