import React, { FunctionComponent } from "react";
import {
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Button,
    chakra,
    Heading,
    Icon,
} from "@chakra-ui/react";
import { RiParentLine } from "react-icons/ri";
import { ProgressButton } from "../common/ProgressButton";

type Props = {
    onConfirm: () => void;
    isOpen: boolean;
    onClose: () => void;
};

export const ShareReportWithSchoolModal: FunctionComponent<Props> = ({
    onConfirm,
    isOpen,
    onClose,
}) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent maxW="540px" alignItems={"center"}>
                <ModalHeader mt="14px" textAlign={"center"}>
                    <Icon
                        fontSize={"46px"}
                        as={RiParentLine}
                        color={"#136AFF"}
                    />
                    <Heading
                        fontStyle="normal"
                        fontWeight="700"
                        fontSize="26px"
                        lineHeight="20px"
                        color="#032E59"
                    >
                        Share report with your child's school
                    </Heading>
                    <Text
                        fontFamily="Nunito"
                        fontStyle="normal"
                        fontWeight="400"
                        fontSize="16px"
                        lineHeight="24px"
                        mt={"22px"}
                    >
                        You are about to share all reports with your child's school.
                    </Text>
                </ModalHeader>
                <chakra.form>
                    <ModalBody
                        color={"black"}
                        display={"flex"}
                        flexDirection={"column"}
                        alignItems={"center"}
                        textAlign={"center"}
                        gap={"22px"}
                        maxW="450px"
                    >
                        <Text>
                            <strong>This action cannot be undone.</strong>
                        </Text>
                        <Text>
                            Click{" "}
                            <Text
                                as={"span"}
                                fontWeight={700}
                                color={"#0051DB"}
                            >
                                Confirm
                            </Text>{" "}
                            to share your child's report or
                            <strong> Cancel </strong>
                            to return.
                        </Text>
                    </ModalBody>
                    <ModalFooter justifyContent="center">
                        <Button
                            w="100px"
                            h="30px"
                            bg="#fff"
                            border="1px solid #ACCBFF"
                            borderRadius="37px"
                            color="#032E59"
                            fontSize="14px"
                            fontWeight="400"
                            lineHeight="16px"
                            mr="10px"
                            onClick={onClose}
                        >
                            Cancel
                        </Button>

                        <ProgressButton
                            getTag={({ onClick }) =>
                                <Button
                                    w="100px"
                                    h="30px"
                                    bg="#136AFF"
                                    borderRadius="37px"
                                    color="#fff"
                                    fontSize="14px"
                                    fontWeight="400"
                                    lineHeight="16px"
                                    onClick={onClick}
                                >
                                    Confirm
                                </Button>
                            }
                            onClick={async ({ resetButton }) => {
                                await onConfirm();
                                resetButton();
                                onClose();
                            }}
                        />
                    </ModalFooter>
                </chakra.form>
            </ModalContent>
        </Modal>
    );
};
