import {
    Text,
    Image,
    Button,
    VStack,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardProps,
} from "@chakra-ui/react";
import React from "react";
import congratulations from "../../assets/congratulations.png";
import { Link as ReachLink } from "@reach/router";
import { AdminMakeIncompleteQuestionnaireButtonComponent } from "../admin/AdminMakeIncompleteQuestionnaireButton";
import { useApp } from "../../../lib/app/App";
import { FocusStyles } from "../../styles";
import ConfettiExplosion from "react-confetti-explosion";

export function CompletedComponent() {
    const { useData } = useApp();

    const userData = useData("User");

    return (
        (userData.isFetched && userData.data && (
            <Card {...(FocusStyles.Card as CardProps)}>
                <ConfettiExplosion />
                <CardHeader {...FocusStyles.BodySection}>
                    <VStack w="100%" placeContent={"center"}>
                        <Image src={congratulations} h="162px" />
                        <Text fontWeight={900} fontSize={"2rem"}>
                            Congratulations!
                        </Text>
                    </VStack>
                </CardHeader>

                <CardBody {...FocusStyles.BodySection}>
                    <Text {...FocusStyles.CardSummary} mb="0">
                        You’ve completed the screening questionnaire.
                    </Text>
                    {userData.data.login.role === "parent" && (
                        <Text {...FocusStyles.CardSummary} mb="0" mt="25px">
                            Once the report is ready you will receive an email
                            with instructions on how to Log In to access your
                            Rellie dashboard to view the full report and
                            customized strategies to support your child.
                        </Text>
                    )}
                    {userData.data.login.role === "student" && (
                        <Text {...FocusStyles.CardSummary} mb="0" mt="25px">
                            A detailed report with personalized strategies will
                            be shared with your teacher to help support your
                            learning path ahead.
                        </Text>
                    )}
                </CardBody>
                <CardFooter {...FocusStyles.BodySection}>
                    <VStack w="100%" placeContent={"center"}>
                        <Button
                            {...FocusStyles.Button}
                            mr="auto"
                            ml="auto"
                            as={ReachLink}
                            to={`/${userData.data.login.role}/dashboard`}
                        >
                            BACK HOME
                        </Button>

                        <AdminMakeIncompleteQuestionnaireButtonComponent />
                    </VStack>
                </CardFooter>
            </Card>
        )) ||
        null
    );
}
