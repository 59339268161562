import React, { FunctionComponent } from "react";
import {
    // HStack,
    // Icon,
    Input,
    InputGroup,
    InputLeftElement,
    Stack,
    VStack,
} from "@chakra-ui/react";
import {
    // FiMessageSquare,
    FiSearch,
} from "react-icons/fi";
// import { BsBellFill } from "react-icons/all";

type Props = {};

export const Searchbar: FunctionComponent<Props> = () => {
    return (
        <Stack
            direction={["column", "row"]}
            alignItems={["center", "flex-end"]}
            justifyContent={"flex-end"}
            w={"100%"}
            mt={"28px"}
            mb={"28px"}
        >
            {/* <InputGroup maxW={{ base: "100%", lg: "161px" }}>
                <InputLeftElement
                    pointerEvents="none"
                    children={<FiSearch color="#ACCBFF" />}
                />
                <Input
                    borderRadius={"20px"}
                    border={"1px solid #ACCBFF"}
                    type="tel"
                    placeholder="search"
                />
            </InputGroup> */}

            {/*<HStack>*/}
            {/*    <VStack*/}
            {/*        alignItems={"center"}*/}
            {/*        justifyContent={"center"}*/}
            {/*        background={"#FFFFFF"}*/}
            {/*        width={"40px"}*/}
            {/*        height={"40px"}*/}
            {/*        minW={"40px"}*/}
            {/*        minHeight={"40px"}*/}
            {/*        borderRadius={"50%"}*/}
            {/*    >*/}
            {/*        <Icon*/}
            {/*            as={FiMessageSquare}*/}
            {/*            color={"#1CCF8C"}*/}
            {/*            fill={"#1CCF8C"}*/}
            {/*        />*/}
            {/*    </VStack>*/}
            {/*    <VStack*/}
            {/*        alignItems={"center"}*/}
            {/*        justifyContent={"center"}*/}
            {/*        background={"#FFFFFF"}*/}
            {/*        width={"40px"}*/}
            {/*        minW={"40px"}*/}
            {/*        minHeight={"40px"}*/}
            {/*        height={"40px"}*/}
            {/*        borderRadius={"50%"}*/}
            {/*    >*/}
            {/*        <Icon as={BsBellFill} color={"#FF4DD1"} />*/}
            {/*    </VStack>*/}
            {/*    <VStack*/}
            {/*        alignItems={"center"}*/}
            {/*        justifyContent={"center"}*/}
            {/*        background={"#FFFFFF"}*/}
            {/*        width={"40px"}*/}
            {/*        height={"40px"}*/}
            {/*        minW={"40px"}*/}
            {/*        minHeight={"40px"}*/}
            {/*        borderRadius={"50%"}*/}
            {/*    />*/}
            {/*</HStack>*/}
        </Stack>
    );
};
