import React, { FunctionComponent } from "react";
import { Spinner, VStack, Text } from "@chakra-ui/react";

export const Loading: FunctionComponent = () => {
    return (
        <VStack
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
        >
            <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                w="100px"
                minW="100px"
                height="100px"
                minH="100px"
            />
            <Text position="relative" top="85px" fontFamily="Nunito">
                Loading ...
            </Text>
        </VStack>
    );
};
