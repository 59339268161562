import {
    Box,
    BoxProps,
    CloseButton,
    HStack,
    Icon,
    Image,
    Text,
    useColorModeValue,
    VStack,
} from "@chakra-ui/react";
import logoSmall from "../../assets/logov2.svg";
import { IoPerson } from "react-icons/io5";
import React from "react";
import { IconType } from "react-icons";
import { NavItem } from "./NavItem";

import { useApp } from "../../../lib/app/App";

import { AdminSelectAPIBackendComponent } from "../../../lib/app/AdminSelectAPIBackend";
import { AdminSwitchToLastUserButton } from "../../../lib/app/AdminSwitchToLastUserButton";

interface SidebarProps extends BoxProps {
    onClose: () => void;
    links: Array<LinkItemProps>;
}

interface LinkItemProps {
    name: string;
    icon?: IconType;
    url?: string;
    onClick?: () => void;
}

export const Sidebar = ({ onClose, links, ...rest }: SidebarProps) => {
    const { useData } = useApp();

    const userData = useData("User");

    return (
        <Box
            bg={"#032E59"}
            borderRight="1px"
            borderRightColor={useColorModeValue("gray.200", "gray.700")}
            w={{ base: "full", md: "265px" }}
            pos="fixed"
            h="full"
            color="white"
            {...rest}
            overflowY={"auto"}
        >
            <HStack>
                <Box
                    mx="8"
                    marginTop="15px"
                    marginBottom="15px"
                    marginRight="3px"
                >
                    <Image
                        minW={{ base: "51px" }}
                        minH={{ base: "45px" }}
                        src={logoSmall}
                        alt="logo"
                    />
                    <CloseButton
                        display={{ base: "flex", md: "none" }}
                        onClick={onClose}
                    />
                </Box>
                <AdminSwitchToLastUserButton />
            </HStack>

            <HStack
                border="1px solid #ACCBFF"
                borderRadius={"30px 0px 0px 30px"}
                w="236px"
                h="56px"
                justifyContent="center"
                marginLeft="auto"
                spacing="58px"
            >
                <HStack spacing="18px" marginLeft={"10px"}>
                    <Box
                        bg={"#EFF6FF"}
                        borderRadius={"50%"}
                        w={"36px"}
                        h={"36px"}
                        minW={"36px"}
                        minH={"36px"}
                        display={"flex"}
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        <Icon
                            fontSize="20"
                            _groupHover={{
                                color: "white",
                            }}
                            color={"#136AFF"}
                            as={IoPerson}
                        />
                    </Box>
                    <VStack>
                        <Text
                            margin="0 !important"
                            fontSize="16px"
                            fontWeight="bold"
                            color="white"
                            lineHeight="20px"
                        >
                            {userData.data?.user?.FirstName}
                        </Text>
                        <Text
                            margin="0 !important"
                            fontSize="12px"
                            fontWeight="400"
                            color="#ACCBFF"
                        >
                            {userData.data?.login?.role === "admin" && (
                                <>Admin Account</>
                            )}
                            {userData.data?.login?.role === "teacher" && (
                                <>Teacher Account</>
                            )}
                            {userData.data?.login?.role === "parent" && (
                                <>Parent Account</>
                            )}
                            {userData.data?.login?.role === "student" && (
                                <>Student Account</>
                            )}
                        </Text>
                    </VStack>
                </HStack>

                {/* <VStack spacing="0px" cursor={"pointer"}>
                    <Icon color={"#E1ECFF"} as={MdArrowDropUp} />
                    <Icon color={"#E1ECFF"} as={MdArrowDropDown} />
                </VStack> */}
            </HStack>

            <Box
                border={"1px solid #2A4C6F"}
                width={"210px"}
                margin={"20px auto"}
                height={"1px"}
            />

            {links.map((link, index) => {
                if (link.name === "Separator") {
                    return (
                        <Box
                            key={`${link.name}-${index}`}
                            border={"1px solid #2A4C6F"}
                            width={"210px"}
                            margin={"20px auto"}
                            height={"1px"}
                        />
                    );
                }

                return (
                    <NavItem
                        key={link.name}
                        icon={link.icon}
                        to={link.url}
                        {...(link?.onClick && { onClick: link.onClick })}
                    >
                        {link.name}
                    </NavItem>
                );
            })}

            <AdminSelectAPIBackendComponent
                m="20px" ml="27px" mb="70px"
            />
        </Box>
    );
};
