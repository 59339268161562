import React, { FunctionComponent } from "react";
import { RouteComponentProps } from "@reach/router";
import {
    Heading,
    Text,
    VStack,
    Box,
} from "@chakra-ui/react";

import { useApp } from "../../../lib/app/App";
import { StudentCard } from "../../components/parent/StudentCard";

export const ParentDashboardPage: FunctionComponent<
    RouteComponentProps
> = () => {
    const { useData } = useApp();
    const userData = useData("User");
    const studentsData = useData("Parent.Students");

// console.log('studentsData', studentsData.data);

    if (
        !userData.isFetched ||
        !userData.data ||
        !studentsData.isFetched ||
        !studentsData.data
    ) {
        return null;
    }

    if (studentsData.data.error) {
        return (
            <Text>There was an error loading this page! Please try again later.</Text>
        )
    }

    studentsData.data.forEach((student) => {

        if (student.AssessmentStatus === "PENDING") {
            student.percentage = 0;
        } else
        if (student.AssessmentStatus === "STARTED") {
            student.percentage = 10;
        } else
        if (student.AssessmentStatus === "PROGRESS_50") {
            student.percentage = 50;
        } else
        if (student.AssessmentStatus === "COMPLETE") {
            student.percentage = 100;
        } else {
            throw new Error(`Unknown AssessmentStatus '${student.AssessmentStatus}'!`);
        }
    });

    return (
        <VStack alignItems={"flex-start"} spacing={"15px"}>
            <Box marginTop="20px">
                <Heading size={"md"} color={"#032E59"}>
                    Hi {userData.data?.user?.FirstName},
                </Heading>
                <Text color={"#032E59"}>
                    Here’s your Rellie overview.{" "}
                </Text>
            </Box>
            {studentsData.data.map((student, i) => (
                <StudentCard
                    key={i}
                    AssessmentId={student.AssessmentId}
                    studentName={`${student.FirstName || ''} ${student.LastName || ''}`}
                    questionnairePercentage={student.percentage}
                    RegistrationType={student.RegistrationType}
                />
            ))}
        </VStack>
    );
};
