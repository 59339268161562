import React, { FunctionComponent } from "react";
import { Link as ReachLink, RouteComponentProps } from "@reach/router";
import {
    Heading,
    Text,
    VStack,
    Box,
    Icon,
    HStack,
    Progress,
} from "@chakra-ui/react";
import { FiTrendingUp } from "react-icons/fi";
import { TbAward } from "react-icons/tb";
import { useApp } from "../../../lib/app/App";
import { Loading } from "../../components/common/Loading";

export const SchoolDashboardPage: FunctionComponent<
    RouteComponentProps
> = () => {
    const { useData } = useApp();
    const userData = useData("User");

    const classesData = useData("Teacher.Classes");

    if (!userData.isFetched || !classesData.isFetched) {
        return <Loading />;
    }
    return (
        <VStack alignItems={"flex-start"} spacing={"15px"}>
            <Box marginTop="20px">
                <Heading size={"md"} color={"#032E59"}>
                    Hi {userData.data.user.FirstName},
                </Heading>
                <Text color={"#032E59"}>Here’s your Rellie overview. </Text>
            </Box>

            {classesData.data.map((classData) => {
                return (
                    <VStack
                        key={classData.id}
                        bg={"#FFFFFF"}
                        borderRadius={"20px"}
                        p={"20px"}
                        spacing={"19px"}
                        w={"100%"}
                        maxW={"767px"}
                    >
                        <Heading
                            alignSelf={"flex-start"}
                            color={"#032E59"}
                            fontSize={"20px"}
                        >
                            {classData.label}
                        </Heading>
                        <HStack
                            w={"100%"}
                            border={"0.5px solid #D9D9D9"}
                            borderRadius={"16px"}
                            p={"14px"}
                            justifyContent={"space-between"}
                        >
                            <HStack
                                spacing={"28px"}
                                as={ReachLink}
                                //TODO:change to the correct report page once it is implemented
                                to={`/teacher/reports/${classData.id}`}
                            >
                                <Box
                                    display={"flex"}
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                    bg={"#136AFF"}
                                    borderRadius={"16px"}
                                    w={"88px"}
                                    h={"80px"}
                                >
                                    <Icon
                                        as={FiTrendingUp}
                                        fontSize={"34px"}
                                        color={"white"}
                                    />
                                </Box>
                                <Text
                                    fontWeight={700}
                                    lineHeight={"20px"}
                                    color={"#032E59"}
                                >
                                    REPORTS
                                </Text>
                            </HStack>
                            <HStack>
                                <Progress
                                    w={"167px"}
                                    height={"10px"}
                                    value={Math.round(
                                        (classData.counts.reportsCompleted /
                                            classData.counts.licensesUsed) *
                                            100,
                                    )}
                                    borderRadius={"11px"}
                                    colorScheme={"brand.blue"}
                                />
                                <Text fontWeight={700} lineHeight={"20px"}>
                                    {classData.counts.reportsCompleted}/
                                    {classData.counts.licensesUsed}
                                </Text>
                            </HStack>
                        </HStack>
                        <HStack
                            w={"100%"}
                            border={"0.5px solid #D9D9D9"}
                            borderRadius={"16px"}
                            p={"14px"}
                            justifyContent={"space-between"}
                        >
                            <HStack
                                spacing={"28px"}
                                as={ReachLink}
                                //TODO:change to the correct license page once it is implemented
                                to={`/teacher/licenses/#${classData.id}`}
                            >
                                <Box
                                    display={"flex"}
                                    alignItems={"center"}
                                    justifyContent={"center"}
                                    bg={"#FBBC04"}
                                    borderRadius={"16px"}
                                    w={"88px"}
                                    h={"80px"}
                                >
                                    <Icon
                                        as={TbAward}
                                        fontSize={"34px"}
                                        color={"white"}
                                    />
                                </Box>
                                <Text
                                    fontWeight={700}
                                    lineHeight={"20px"}
                                    color={"#032E59"}
                                >
                                    LICENSES
                                </Text>
                            </HStack>
                            <HStack>
                                <Progress
                                    w={"167px"}
                                    height={"10px"}
                                    value={Math.round(
                                        (classData.counts.licensesUsed /
                                            classData.counts.licenses) *
                                            100,
                                    )}
                                    borderRadius={"11px"}
                                    colorScheme={"brand.yellow"}
                                />
                                <Text fontWeight={700} lineHeight={"20px"}>
                                    {classData.counts.licensesUsed}/
                                    {classData.counts.licenses}
                                </Text>
                            </HStack>
                        </HStack>
                    </VStack>
                );
            })}
        </VStack>
    );
};
