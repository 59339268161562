import React, { FunctionComponent, useState } from "react";
import {
    HStack,
    Progress,
    VStack,
    Text,
    Heading,
    Link,
    Image,
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    useDisclosure,
    Box,
} from "@chakra-ui/react";
import questionIcon from "../../assets/question.svg";
import { Link as ReachLink } from "@reach/router";
import { getColor } from "../../app";
import { handleColorPercentage } from "../../pages/Teacher/TeacherClassReport";
import { StrategyCard } from "./StrategyCard";

type Skill = {
    name: string;
    percentage: number;
    label: string;
};

type Strategy = {
    id: number;
    label: string;
    description: string;
};

type Props = {
    cognitiveArea: {
        order: string;
        id: string;
        label: string;
        color: string;
        colorScheme: string;
        description: string;
        percentage: number;
        firstStrtegy: Strategy;
        skills: Skill[];
        strategies: Strategy[];
    };
    skills: Skill[];
    description: string;
    colorScheme: string;
    isForTeacher?: boolean;
    handleMoreParentStrategies?: () => void;
    onStrategyViewMore: (cognitiveAreaId: string) => void;
};

export const CognitiveArea: FunctionComponent<Props> = ({
    cognitiveArea,
    skills,
    description,
    colorScheme,
    isForTeacher = false,
    handleMoreParentStrategies,
    onStrategyViewMore,
}) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [currentInfo, setCurrentInfo] = useState<
        | {
              name: string;
              text: string;
          }
        | undefined
    >(undefined);

    const onInfoClick = () => {
        setCurrentInfo({
            name: cognitiveArea.label,
            text: cognitiveArea.description,
        });
        onOpen();
    };

    if (!cognitiveArea) {
        return null;
    }

    return (
        <>
            {isOpen && (
                <Modal isOpen={isOpen} onClose={onClose}>
                    <ModalOverlay />
                    <ModalContent borderRadius={"15px"} p={5}>
                        <ModalHeader fontSize={"14px"} color={"#032E59"}>
                            {currentInfo.name}
                        </ModalHeader>
                        <ModalCloseButton />
                        <ModalBody color={"#5F6368"} fontSize={"14px"}>
                            {currentInfo.text}
                        </ModalBody>
                    </ModalContent>
                </Modal>
            )}
            <HStack
                bg={"#FFFFFF"}
                spacing={"50px"}
                p={"25px"}
                borderRadius={"16px"}
                w="100%"
                alignItems="top"
                position={"relative"}
            >
                <Box
                    position={"absolute"}
                    left={"0px"}
                    w="5px"
                    h={"70%"}
                    backgroundColor={getColor(colorScheme)}
                />

                <VStack w={"60%"} spacing={"12px"}>
                    <Heading
                        size={"sm"}
                        alignSelf={"flex-start"}
                        fontSize={"16px"}
                        fontWeight={700}
                        lineHeight={"20px"}
                        color={"#032E59"}
                        display={"flex"}
                        alignItems={"center"}
                        gap={"10px"}
                    >
                        {cognitiveArea.label}
                        <Image
                            cursor={"pointer"}
                            src={questionIcon}
                            alt="question mark"
                            w={"13px"}
                            h={"13px"}
                            marginTop={"-10px"}
                            onClick={() => {
                                onInfoClick();
                            }}
                        />
                    </Heading>

                    <HStack w={"100%"} justifyContent={"space-between"}>
                        <Text
                            fontSize={"14px"}
                            fontWeight={"800"}
                            color={"#000"}
                            lineHeight={"20px"}
                            ml={"-24px"}
                        >
                            Overall:
                        </Text>
                        <HStack>
                            <Progress
                                w={"306px"}
                                maxW={"306px"}
                                value={cognitiveArea.percentage}
                                borderRadius={"11px"}
                                bg={"#F5F5F5"}
                                colorScheme={handleColorPercentage(
                                    cognitiveArea.percentage,
                                )}
                            />
                            <Text fontSize={"11px"} color={"#6C6C6C"}>
                                {cognitiveArea.percentage}%
                            </Text>
                        </HStack>
                    </HStack>

                    {skills.map((skill, index) => {
                        return (
                            <HStack
                                w={"100%"}
                                justifyContent={"space-between"}
                                borderTop={"0.5px dashed #AAAAAA"}
                                paddingTop={"9px"}
                                spacing={"0"}
                                key={index}
                            >
                                <Text fontSize={"14px"} color={"#000000"}>
                                    {skill.label}
                                </Text>
                                <HStack>
                                    <Progress
                                        w={"306px"}
                                        maxW={"306px"}
                                        value={skill.percentage}
                                        borderRadius={"11px"}
                                        bg={"#F5F5F5"}
                                        colorScheme={handleColorPercentage(
                                            skill.percentage,
                                        )}
                                    />
                                    <Text fontSize={"11px"} color={"#6C6C6C"}>
                                        {skill.percentage}%
                                    </Text>
                                </HStack>
                            </HStack>
                        );
                    })}
                </VStack>
                <VStack w={"40%"} alignItems={"flex-start"} spacing={"15px"}>
                    <Text
                        color={"#5F6368"}
                        fontWeight={"700"}
                        fontSize={"14px"}
                    >
                        Strategies for improvement
                    </Text>
                    <StrategyCard
                        description={description ?? "No intervention required"}
                    />
                    {!isForTeacher ? (
                        <Button
                            mt={"14px"}
                            bg={"#E1ECFF"}
                            fontWeight={"700"}
                            fontSize={"12px"}
                            color={"#032E59"}
                            borderRadius={"50px"}
                            w={"71.571px"}
                            h={"25px"}
                            onClick={() => {
                                onStrategyViewMore(cognitiveArea.id);
                            }}
                            display={"flex"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            alignSelf={"flex-end"}
                        >
                            View All
                        </Button>
                    ) : (
                        <HStack justifyContent={"space-between"} w={"100%"}>
                            <Link
                                as={ReachLink}
                                fontWeight={"600"}
                                fontSize={"14px"}
                                color={"#032E59"}
                                paddingTop={"15px"}
                                to={"/teacher/report-strategies/"}
                            >
                                VIEW MORE STRATEGIES
                            </Link>
                            <Button
                                variant="link"
                                fontWeight={"600"}
                                fontSize={"14px"}
                                color={"#032E59"}
                                paddingTop={"15px"}
                                onClick={handleMoreParentStrategies}
                            >
                                PARENT STRATEGIES
                            </Button>
                        </HStack>
                    )}
                </VStack>
            </HStack>
        </>
    );
};
