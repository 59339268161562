
import React, { useState, useEffect } from 'react';
import { Box } from "@chakra-ui/react";
import { useApp } from "./App";

export function AdminEnableToolsBookmarkletLink () {

    const { useData, api } = useApp();

    const userData = useData('User');

    const [token, setToken] = useState(null);

    useEffect(() => {
        if (token) return;
        if (!userData.data || userData.data.login.role !== 'admin') return;
        (async () => {
            setToken(await api.Admin.Preview.GetUnlockToken.query());
        })();
    });

    function createMarkup () {
        const bookmarkletUrls = [
            `https://pal-api-pre.rellie.dev/api/admin/preview/bookmarklet?token=${token}&http-cookie-only=1`,
            `https://pal-api-stage.rellie.dev/api/admin/preview/bookmarklet?token=${token}&http-cookie-only=1`,
            `https://pal-api-prod.rellie.dev/api/admin/preview/bookmarklet?token=${token}`
        ];
        return {__html: (`
            <a href="javascript: (() => {
                ${JSON.stringify(bookmarkletUrls).replace(/"/g, `'`)}.map(function (url) {
                    const script = document.createElement('script');
                    script.src = url;
                    script.async = true;
                    document.head.appendChild(script); 
                });
            })();">
                Enable Admin Tools Bookmarklet
            </a>
        `).replace(/\n/g, '').replace(/\s+/g, ' ')};
    }

    return token && (
        <>
            <Box
                border="1px solid black"
                padding="5px"
                paddingLeft="15px"
                paddingRight="15px"
                cursor={"pointer"}
                backgroundColor={"#dcdcdc"}
                dangerouslySetInnerHTML={createMarkup()}
            />
        </>
    ) || null;
}
