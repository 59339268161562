import React, { FunctionComponent } from "react";
import { RouteComponentProps, useParams, navigate, Link as ReachLink } from "@reach/router";
import { Loading } from "../../components/common/Loading";
import { Heading, VStack, Box, Button, Link, Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr } from "@chakra-ui/react";
import { RellieSelect } from "../../components/common/RellieSelect";
import { useApp } from "../../../lib/app/App";

export const AdminQaReportsExportsPage: FunctionComponent<
    RouteComponentProps
> = () => {
    const { isAdmin, formatRequestUrl, useData } = useApp();

    const params = useParams();
   
    const reportType = params.reportType || "";
   
    const reportTypes = useData("Admin.QA.Report.Types");

    let selectedReport = null
    if (reportType && reportTypes.data) {
        selectedReport = reportTypes.data.filter((report) => (report.name === reportType))?.[0] || null
    }

    const report = useData("Admin.QA.Report", {
        name: selectedReport?.name || ''
    });

    function onSelectReportType (reportType) {
        navigate(`/admin/qa/reports-exports/${reportType}`);
    }

    function onDownload(type) {
        const url = formatRequestUrl(
            "api",
            `/api/admin/data/exports-download/${type}`,
        );
        window.open(url);
    }

    if (!isAdmin) {
        return null;
    }

    if (!reportTypes.data) {
        return <Loading/>
    }

    return (
        <VStack alignItems={"flex-start"} spacing={"25px"}>

            { !reportType && (
                <>
                    <Box marginTop="52px">
                        <Heading size={"md"} color={"#032E59"} mb="15px">
                            Exports
                        </Heading>
                        <VStack alignItems={"start"}>
                            <Button
                                color="#032E59"
                                variant="outline"
                                borderColor="#0051DB"
                                borderRadius={"41px"}
                                height={"20px"}
                                fontSize={"12px"}
                                onClick={() => {
                                    onDownload("Questions");
                                }}
                            >
                                Download Questions
                            </Button>

                            <Button
                                color="#032E59"
                                variant="outline"
                                borderColor="#0051DB"
                                borderRadius={"41px"}
                                height={"20px"}
                                fontSize={"12px"}
                                onClick={() => {
                                    onDownload("Answers");
                                }}
                            >
                                Download Answers
                            </Button>

                            <Button
                                color="#032E59"
                                variant="outline"
                                borderColor="#0051DB"
                                borderRadius={"41px"}
                                height={"20px"}
                                fontSize={"12px"}
                                onClick={() => {
                                    onDownload("Skills");
                                }}
                            >
                                Download Skills
                            </Button>

                            <Button
                                color="#032E59"
                                variant="outline"
                                borderColor="#0051DB"
                                borderRadius={"41px"}
                                height={"20px"}
                                fontSize={"12px"}
                                onClick={() => {
                                    onDownload("ScreeningByGrade");
                                }}
                            >
                                Download Screening By Grade
                            </Button>

                            <Button
                                color="#032E59"
                                variant="outline"
                                borderColor="#0051DB"
                                borderRadius={"41px"}
                                height={"20px"}
                                fontSize={"12px"}
                                onClick={() => {
                                    onDownload("QA_Screening");
                                }}
                            >
                                Download QA Screening
                            </Button>

                            <Button
                                color="#032E59"
                                variant="outline"
                                borderColor="#0051DB"
                                borderRadius={"41px"}
                                height={"20px"}
                                fontSize={"12px"}
                                onClick={() => {
                                    onDownload("Strategies");
                                }}
                            >
                                Download Strategies
                            </Button>

                            <Button
                                color="#032E59"
                                variant="outline"
                                borderColor="#0051DB"
                                borderRadius={"41px"}
                                height={"20px"}
                                fontSize={"12px"}
                                onClick={() => {
                                    onDownload("PartyStrategies");
                                }}
                            >
                                Download Party Strategies
                            </Button>
                        </VStack>
                    </Box>
                    <Box>
                        <Heading size={"md"} color={"#032E59"} mb="15px">
                            Reports
                        </Heading>
                        <VStack alignItems={"start"}>
                            { reportTypes.data.map((report, i) => (

                                <Link
                                    key={i}
                                    to={report.name}
                                    as={ReachLink}
                                    getProps={({ isCurrent }) => {
                                        return {
                                            style: {
                                                color: "#032E59",
                                                variant: "outline",
                                                borderColor: "#0051DB",
                                                borderRadius: "41px",
                                                height: "20px",
                                                fontSize: "12px",
                                            },
                                        };
                                    }}
                                >
                                    {report.label}
                                </Link>
                            ))}
                        </VStack>
                    </Box>
                </>
            ) || (

                <Box marginTop="52px">
                    <Heading size={"md"} color={"#032E59"} mb="15px">
                        Report: {selectedReport.label}
                    </Heading>
                    <VStack alignItems={"start"}>

                        { report.data?.records && (
                            <>
                                <Box>Result count: <b>{report.data.records.length}</b></Box>

                                <TableContainer mt={"18px"}>
                                    <Table
                                        variant="simple"
                                        bg={"#FFFFFF"}
                                        borderRadius={"16px"}
                                        size="sm"
                                        sx={{
                                            "& th": {
                                                color: "#5F6368",
                                                fontSize: "10px",
                                                textTransform: "capitalize",
                                            },
                                            "& td": {
                                                fontSize: "12px",
                                            },
                                        }}
                                    >
                                        <Thead>
                                            <Tr>
                                                {report.data.columns.map((column, i) => {
                                                    return (
                                                        <Th
                                                            key={i}
                                                            position={"sticky"}
                                                            top={0}
                                                            backgroundColor={"#ffffff"}
                                                        >{column.label}</Th>
                                                    )
                                                })}
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {report.data.records.map((record, i) => (
                                                <Tr key={i}>
                                                    {report.data.columns.map((column, i) => {

                                                        let value = record[column.key]
                                                        if (column.join) {
                                                            value = value.join(column.join)
                                                        }
                                                        if (typeof value !== "string" && typeof value != "number") {
                                                            value = JSON.stringify(value)
                                                        }

                                                        return (
                                                            <Td key={i}>
                                                                {value}
                                                            </Td>
                                                        )
                                                    })}
                                                </Tr>
                                            ))}
                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            </>
                        ) || (<Loading/>)}
                    </VStack>
                </Box>
            )}

        </VStack>
    );
};
