import React from "react";
import { Box } from "@chakra-ui/react";

type DotProps = {
    top?: string | { base: string; lg: string };
    right?: string | { base: string; lg: string };
    left?: string | { base: string; lg: string };
    bottom?: string | { base: string; lg: string };
    color: string | { base: string; lg: string };
    size: string | { base: string; lg: string };
};
export const Dot = ({ top, right, left, bottom, color, size }: DotProps) => {
    return (
        <Box
            position={"absolute"}
            w={size}
            h={size}
            bg={color}
            borderRadius={"50%"}
            marginRight={"10px"}
            top={top}
            right={right}
            left={left}
            bottom={bottom}
            zIndex={1}
        />
    );
};
