import React from "react";
import { HStack, Image } from "@chakra-ui/react";
import logo from "../../assets/logo.svg";

export const Header = () => {
    return (
        <HStack
            marginTop={"38px"}
            justifyContent={"start"}
            w={"100%"}
            maxW={"1494px"}
            paddingLeft={"20px"}
            paddingRight={"20px"}
        >
            <Image
                src={logo}
                alt="logo"
                onClick={() => {
                    window.location.href = "https://rellie.com/";
                }}
                cursor={"pointer"}
                minW={{ lg: "141px", base: "72px" }}
                maxW={{ lg: "141px", base: "72px" }}
                minH={{ lg: "50px", base: "24px" }}
            />
        </HStack>
    );
};
