import React, { FunctionComponent } from "react";
import {
    AbsoluteCenter,
    Box,
    Button,
    Checkbox,
    Divider,
    Grid,
    GridItem,
    Heading,
    HStack,
    Image,
    Stack,
    Text,
    VStack,
} from "@chakra-ui/react";
import { BsCheck2, BsX } from "react-icons/bs";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import { InputForm } from "../common/InputForm";
import { AdminFillFormButton } from "../../../lib/app/AdminFillFormButton";
import { Link as ReactRouterLink, useLocation } from "@reach/router";
import googleIcon from "../../assets/google-icon.png";
import { Dot } from "../common/Dot";
import { useApp } from "../../../lib/app/App";
import { ErrorMessage } from "../common/ErrorMessage"

type Props = {
    feedback: any;
    email: undefined|string;
    onContinueHandler: any;
};
const schema = z.object({
    email: z.string().email()
});

export const SpecifyEmailComponent: FunctionComponent<Props> = ({
    feedback,
    email,
    onContinueHandler
}) => {

    const {
        register,
        formState: { errors },
        getValues,
        clearErrors,
        setValue,
        watch,
        handleSubmit,
    } = useForm({
        resolver: zodResolver(schema),
        defaultValues: {
            email
        },
    });

    function onAutofill() {
        const isPreview = window.localStorage.getItem(
            "re-unlock-preview-token",
        );
        if (isPreview) {
            const lastEmail = window.localStorage.getItem(
                "re-preview-invite-last-email",
            );
            if (!getValues("email") && lastEmail && lastEmail !== "null") {
                setValue("email", lastEmail);
            }
        }
    }
    
    return (
        <>
            <HStack
                zIndex={"2"}
                spacing={"144px"}
                mb={"80px"}
                bg={"#fff"}
                borderRadius={"20px"}
            >
                <VStack
                    bg={"rgba(19, 106, 255, 0.2)"}
                    w={"100%"}
                    maxW={"420px"}
                    p={"30px"}
                    boxShadow={"0px 4px 14px rgba(19, 106, 255, 0.2)"}
                    borderRadius={"20px"}
                    spacing={"11px"}
                >
                    <Heading
                        textAlign={"left"}
                        w={"100%"}
                        fontSize={"26px"}
                        size={"md"}
                        lineHeight={"20px"}
                        color={"#032E59"}
                    >
                        Let’s Get Started
                    </Heading>
                    {/* <Text alignSelf={"start"}>
                        Already have an account? &nbsp;
                        <Text
                            as={ReactRouterLink}
                            to="/"
                            fontWeight={"900"}
                            color={"#032E59"}
                        >
                            Log in here.
                        </Text>
                    </Text> */}

                    <Text
                        alignSelf={"flex-start"}
                        fontSize={"16px"}
                        color={"#032E59"}
                        fontWeight={"700"}
                    >
                        Account Information
                    </Text>

                    <VStack alignItems={"flex-start"}>
                        <AdminFillFormButton onClick={onAutofill} />

                        <Text fontSize={"12px"}>
                            Enter your email address
                        </Text>
                        <InputForm
                            placeholder={"Enter email address"}
                            name={"email"}
                            required={true}
                            register={register}
                            height={"40px"}
                            width={"354px"}
                            type={"email"}
                            rest={{
                                fontSize: "14px",
                                border: "1px solid #ACCBFF",
                                borderRadius: "37px",
                                bg: "black.100",
                                color: "gray.800",
                                sx: {
                                    "&[disabled],& [aria-disabled=true],&[aria-disabled]":
                                        {
                                            opacity: 1,
                                        },
                                }
                            }}
                            error={
                                errors?.email?.message as string | undefined
                            }
                        />
                    </VStack>

                    <VStack w={"100%"} alignItems={"start"}>

                        <ErrorMessage feedback={feedback}/>

                    </VStack>
                    <Button
                        bg={"#136AFF"}
                        borderRadius={"30px"}
                        width={"171px"}
                        minHeight={"40px"}
                        color={"#FFFFFF"}
                        fontWeight={"900"}
                        fontSize={"16px"}
                        lineHeight={"20px"}
                        onClick={handleSubmit(onContinueHandler)}
                    >
                        Continue
                    </Button>
                </VStack>
            </HStack>
            <Stack zIndex={"1"}>
                <Dot
                    size={"29px"}
                    color={"#1CCF8C"}
                    top={{ base: "2%", lg: "10%" }}
                    left={{ base: "82%", lg: "83.17%" }}
                />
                <Dot
                    size={"41px"}
                    color={"#F5C306"}
                    top={{ base: "2%", lg: "18.875%" }}
                    left={{ base: "18%", lg: "24.33%" }}
                />
                <Dot
                    size={"44px"}
                    color={"#FFFC82"}
                    top={{ base: "41%", lg: "46.84%" }}
                    left={{ base: "3%", lg: "8.68%" }}
                />
                <Dot
                    size={"18px"}
                    color={"#136AFF"}
                    top={{ base: "21%", lg: "26.76%" }}
                    left={{ base: "90%", lg: "76.05%" }}
                />
                <Dot
                    size={"53px"}
                    color={"#F56806"}
                    top={{ base: "41%", lg: "57.23%" }}
                    left={{ base: "89%", lg: "77.44%" }}
                />
                <Dot
                    size={"53px"}
                    color={"#ED47C4"}
                    top={{ base: "16%", lg: "60.28%" }}
                    left={{ base: "3%", lg: "20.83%" }}
                />
            </Stack>
        </>
    );
};
