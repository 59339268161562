import React from "react";
import { Flex } from "@chakra-ui/react";
import { Header } from "./Header";
import { Footer } from "./Footer";

type Props = {
    children: React.ReactNode;
};

export const LayOut = ({ children }: Props) => {
    return (
        <Flex
            flexDirection={"column"}
            alignItems={"center"}
            bg={"#EFF6FF"}
            minHeight={"100vh"}
            w={"100%"}
            maxW={"100%"}
            justifyContent={"space-between"}
            position={"relative"}
        >
            <Header />
            {children}
            <Footer />
        </Flex>
    );
};
