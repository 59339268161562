import React, { FunctionComponent, useState, useEffect } from "react";
import { RouteComponentProps, useParams, navigate } from "@reach/router";
import {
    Heading,
    VStack,
    HStack,
    Text,
    Box,
    Table,
    TableContainer,
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
    Button,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    chakra,
    Textarea,
    Input,
    Tabs,
    TabList,
    Tab,
} from "@chakra-ui/react";
import { SimpleSelect } from "../../components/common/SimpleSelect";
import { Loading } from "../../components/common/Loading";
import { useApp } from "../../../lib/app/App";
import { RellieSelect } from "../../components/common/RellieSelect";
import { useForm } from "react-hook-form";
import { ProgressButton } from "../../components/common/ProgressButton";
import { get } from "lodash";

import { PartyStrategyEditor } from "./AdminDataEditor-PartyStrategy"



export const AdminDataEditorPage: FunctionComponent<
    RouteComponentProps
> = () => {
    const { isAdmin, useData, api } = useApp();

    const params = useParams();

    const selectedDatasetName = params.datasetName || "";

    const datasetsData = useData("Admin.Data.Editor.Datasets");

    const datasetData = useData("Admin.Data.Editor.Dataset", {
        datasetName: selectedDatasetName,
    });

    function onSelectDataset(datasetName) {
        navigate(`/admin/data/editor/${datasetName}`);
    }

    const {
        isOpen: isOpenEditor,
        onOpen: onOpenEditor,
        onClose: onCloseEditor,
    } = useDisclosure();

    const [editorContext, setEditorContext] = useState(null);

    function onDoubleClick(event) {
        const rowEl = event.target.closest("tr");
        const cellEl = event.target.closest("td");

        if (
            !cellEl?.dataset?.recordField ||
            !rowEl?.dataset?.recordId
        ) {
            return;
        }

        if (
            cellEl.dataset.recordField === 'Id' &&
            datasetsData.data[selectedDatasetName].allowDelete
        ) {

            setSelectedId(parseInt(rowEl.dataset.recordId));

        } else
        if (
            datasetsData.data[selectedDatasetName].fields[
                cellEl.dataset.recordField
            ]?.editable
        ) {

            const recordId = parseInt(rowEl.dataset.recordId);
            const recordField = cellEl.dataset.recordField;
    
            setEditorContext({
                datasetName: selectedDatasetName,
                recordId,
                recordField,
                existingValue: datasetData.data.filter((record) => {
                    return record.Id == recordId;
                })[0][recordField],
                existingRecord: datasetData.data.filter((record) => {
                    return record.Id == recordId;
                })[0],
                schema: datasetsData.data[selectedDatasetName].fields[recordField]
            });
    
            onOpenEditor();
        }
    }

    function onCancelEditor () {
        onCloseEditor();
        setEditorContext(null);
        datasetData.refetch();
        setSelectedId(null);
    }

    async function onSaveEditor(data) {
        // console.log("on save editor", data);

        let newValue = null;
        if (editorContext.schema.type === "text") {
            newValue = data.textfield;
        } else
        if (editorContext.schema.type === "integer") {
            if (data.integer === `${parseInt(data.integer)}`) {
                newValue = parseInt(data.integer);
            } else {
                newValue = 0;
            }
        } else
        if (editorContext.schema.type === "float") {
            if (data.float === `${parseFloat(data.float)}`) {
                newValue = parseFloat(data.float);
            } else {
                newValue = 0;
            }
        } else
        if (editorContext.schema.type === "boolean") {
            newValue = (data.boolean === '0' || data.boolean === '') ? 0 : 1;
        } else
        if (editorContext.schema.editor) {
            // Editor dialogs save data directly.
            return;
        }
        if (newValue === null) {
            throw new Error(`Schema type '${editorContext.schema.type}' not supported!`);
        }

        const result = await api.Admin.Data.Editor.Dataset.Field.Save.mutate({
            datasetName: editorContext.datasetName,
            recordId: editorContext.recordId,
            recordField: editorContext.recordField,
            newValue,
        });
        if (result.error?.message) {
            alert(result.error.message)
        }

        datasetData.refetch();
        setSelectedId(null);

        onCloseEditor();
        setEditorContext(null);
    }

    const [selectedId, setSelectedId] = useState(null);

    function onCancel () {
        setSelectedId(null);
    }

    async function onDelete () {

        const { Id } = await api.Admin.Data.Editor.Dataset.Record.Delete.mutate({
            datasetName: selectedDatasetName,
            Id: selectedId,
        });
        
        datasetData.refetch();
        setSelectedId(null);
    }

    async function onCreate () {

        await api.Admin.Data.Editor.Dataset.Record.Create.mutate({
            datasetName: selectedDatasetName
        });

        datasetData.refetch();
        setSelectedId(null);
    }

    useEffect(() => {
        setSelectedId(null);
    }, [selectedDatasetName]);

    if (!isAdmin) {
        return null;
    }
    
    if (!datasetsData.data) {
        return <Loading />;
    }

    function renderTable() {

        if (!datasetData.data.length) {
            return (
                <Box>No records found</Box>
            )
        }

        return (
            <TableContainer mt={"18px"} onDoubleClick={onDoubleClick} height="70vh" overflowY="scroll">
                <Table
                    variant="simple"
                    bg={"#FFFFFF"}
                    borderRadius={"16px"}
                    size="sm"
                    sx={{
                        "& th": {
                            color: "#5F6368",
                            fontSize: "10px",
                            textTransform: "capitalize",
                        },
                        "& td": {
                            fontSize: "12px",
                        },
                    }}
                >
                    <Thead>
                        <Tr>
                            {Object.keys(datasetData.data[0]).map((name, i) => (
                                <Th
                                    key={i}
                                    position={"sticky"}
                                    top={0}
                                    backgroundColor={"#ffffff"}
                                >{name}</Th>
                            ))}
                        </Tr>
                    </Thead>
                    <Tbody>
                        {datasetData.data.map((record, i) => (
                            <Tr
                                key={i}
                                data-record-id={record.Id}
                            >
                                {Object.keys(record).map((name, i) => (
                                    <Td
                                        key={i}
                                        data-record-field={name}
                                        p="0px"
                                    >
                                        <Box
                                            whiteSpace={"normal"}
                                            m="1px"
                                            p="7px 17px 7px 15px"
                                            _hover={
                                                (datasetsData.data[
                                                    selectedDatasetName
                                                ].fields[name] && datasetsData.data[
                                                    selectedDatasetName
                                                ].fields[name].editable && {
                                                    bgColor: "#fafafa",
                                                    cursor: "pointer",
                                                }) ||
                                                ""
                                            }
                                        >
                                            {record[name] || ""}
                                        </Box>
                                    </Td>
                                ))}
                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
        );
    }

    return (
        <>
            {editorContext && (
                <EditorModal
                    isOpen={isOpenEditor}
                    onCancel={onCancelEditor}
                    onSave={onSaveEditor}
                    editContext={editorContext}
                />
            )}
            <VStack alignItems={"flex-start"} spacing={"52px"}>
                <Box marginTop={"50px;"}>

                    <Heading size={"md"} color={"#032E59"} mb="15px">
                        Data Editor
                    </Heading>

                    <VStack alignItems={"start"}>
                        <HStack justifyContent={"space-between"} mb="15px">
                            <RellieSelect
                                value={selectedDatasetName}
                                placeholder={"SELECT DATASET"}
                                onChangeHandler={onSelectDataset}
                                options={Object.keys(datasetsData.data).filter((datasetName) => (datasetsData.data[datasetName].editorName === 'Generic')).map(
                                    (datasetName) => ({
                                        label: datasetsData.data[datasetName]
                                            .label,
                                        value: datasetName,
                                    }),
                                )}
                            />
                        </HStack>

                        {(selectedDatasetName === "" && (
                            <>
                                <Text>Select a dataset to see the editor.</Text>
                            </>
                        )) || datasetData.data && (
                            <>
                                {selectedId && (
                                    <HStack justifyContent={"space-between"} mb="15px">
                                        <Text fontSize={"14px"} textAlign={"center"} p="15px;">
                                            Delete record with id <b>{selectedId}</b>?
                                        </Text>
                                        <Button
                                            backgroundColor="#FF0000"
                                            onClick={onDelete}
                                        >Delete</Button>
                                        <Button
                                            onClick={onCancel}
                                        >Cancel</Button>
                                    </HStack>
                                )}                                
                                {renderTable()}
                                { datasetsData.data[selectedDatasetName].allowCreate  && (
                                    <Button
                                        onClick={onCreate}
                                    >
                                        Create
                                    </Button>
                                )}
                            </>
                        ) || (
                            <Loading/>
                        )}
                    </VStack>
                </Box>
            </VStack>
        </>
    );
};

export const EditorModal = ({ isOpen, onCancel, onSave, editContext }) => {

    const defaultValues = {};
    let maxW = "640px";
    let minH = "100px";
    if (editContext.schema.type === 'text') {
        defaultValues.textfield = `${editContext?.existingValue}` || "";
    } else
    if (editContext.schema.type === 'integer') {
        defaultValues.integer = parseInt(`${editContext?.existingValue}` || "0");
    } else
    if (editContext.schema.type === 'float') {
        defaultValues.float = parseFloat(`${editContext?.existingValue}` || "0");
    } else
    if (editContext.schema.type === 'boolean') {
        defaultValues.boolean = parseInt(`${editContext?.existingValue}` || "0");
    }

    if (editContext.schema.editor === 'Party_Strategy') {
        maxW = "90vw";
        minH = "300px";
    }

    const {
        register,
        // formState: { errors },
        handleSubmit,
    } = useForm({
        shouldUnregister: false,
        defaultValues,
    });

    if (!editContext) {
        return "";
    }

    return (
        <>
            <Modal isOpen={isOpen} onClose={onCancel}>
                <ModalOverlay />
                <ModalContent p={5} maxW={maxW} alignItems={"center"}>
                    <ModalHeader mt="14px" textAlign={"center"}>
                        <Heading
                            fontStyle="normal"
                            fontWeight="700"
                            fontSize="26px"
                            lineHeight="20px"
                            color="#032E59"
                        >
                            Edit Value: {editContext.recordField}
                        </Heading>
                    </ModalHeader>
                    <chakra.form w="100%">
                        <ModalBody
                            // color={"black"}
                            // display={"flex"}
                            // flexDirection={"column"}
                            alignItems={"center"}
                            textAlign={"center"}
                            gap={"22px"}
                            w="100%"
                        >
                            <HStack w="100%" minW="100%">

                                {editContext.schema.type === "text" && (
                                    <Textarea
                                        {...register("textfield", {
                                            required: true,
                                        })}
                                        w="100%"
                                        isRequired={true}
                                        size="md"
                                    />
                                )}
                                {editContext.schema.type === "integer" && (
                                    <Input
                                        {...register("integer", {
                                            required: true,
                                        })}
                                        isRequired={true}
                                        size="md"
                                    />
                                )}
                                {editContext.schema.type === "float" && (
                                    <Input
                                        {...register("float", {
                                            required: true,
                                        })}
                                        isRequired={true}
                                        size="md"
                                    />
                                )}
                                {editContext.schema.type === "boolean" && (
                                    <Input
                                        {...register("boolean", {
                                            required: true,
                                        })}
                                        isRequired={true}
                                        size="md"
                                    />
                                )}
                                {(
                                    editContext.schema.editor === "Party_Strategy" &&
                                    editContext.recordField === "Party_Strategy"
                                ) && (
                                    <VStack minH={minH} width="100%">
                                        <PartyStrategyEditor
                                            editContext={editContext}
                                        />
                                    </VStack>
                                )}
                            </HStack>
                        </ModalBody>
                        <ModalFooter justifyContent="center">
                            <Button
                                w="100px"
                                h="30px"
                                bg="#fff"
                                border="1px solid #ACCBFF"
                                borderRadius="37px"
                                color="#032E59"
                                fontSize="14px"
                                fontWeight="400"
                                lineHeight="16px"
                                mr="10px"
                                onClick={onCancel}
                            >
                                Cancel
                            </Button>

                            {!editContext.schema.editor && (
                                <ProgressButton
                                    getTag={({ onClick }) => (
                                        <Button
                                            w="100px"
                                            h="30px"
                                            bg="#136AFF"
                                            borderRadius="37px"
                                            color="#fff"
                                            fontSize="14px"
                                            fontWeight="400"
                                            lineHeight="16px"
                                            onClick={onClick}
                                        >
                                            Save
                                        </Button>
                                    )}
                                    onClick={async ({ resetButton }) => {
                                        await handleSubmit(onSave)();

                                        resetButton();
                                    }}
                                />
                            )}
                        </ModalFooter>
                    </chakra.form>
                </ModalContent>
            </Modal>
        </>
    );
};
