import React, { useState, useEffect, useMemo } from "react";
import {
    useRadio,
    useRadioGroup,
    Box,
    VStack,
    Input,
    Fade,
    HStack,
    Image,
    Card,
    CardBody,
    Heading,
    CardFooter,
    CardHeader,
    CardProps,
    HeadingProps,
} from "@chakra-ui/react";

import Button from '../common/Button'

import congratulationsImage from "../../assets/congratulations.png";
import { RouteComponentProps, useNavigate, useParams } from "@reach/router";

import { AdminMakeCompleteQuestionnaireButtonComponent } from "../admin/AdminMakeCompleteQuestionnaireButton";
import { FocusStyles } from "../../styles";

import { moveOtherToEnd, shuffleArray } from "../../../lib/util";
import { isArray } from "lodash";

export const sectionColors = [
    "#FFE8F9",
    "#FCD2B4",
    "#E1ECFF",
    "#D2F5E8",
    "#DECFFF",
];
export const sectionBarColors = [
    "#FF4DD1",
    "#F56806",
    "#136AFF",
    "#1CCF8C",
    "#8257FF",
];

function RadioOptionCard(props) {
    const { getInputProps, getCheckboxProps } = useRadio(props);

    const input = getInputProps();
    const checkbox = getCheckboxProps();
    return (
        <Box
            as="label"
            minW={320}
            maxW={{ base: "80%", lg: "100%" }}
            minH={45}
            textAlign="center"
        >
            <Input {...input} />
            <Box
                {...checkbox}
                cursor="pointer"
                borderWidth={0.5}
                borderRadius={50}
                background={"#fff"}
                _checked={{
                    bg: "#FFB8F9",
                    color: "white",
                    borderColor: "#FFB8F9",
                }}
                _focus={{
                    bg: "#FFB8F9",
                    color: "white",
                    borderColor: "#FFB8F9",
                }}
                borderColor="rgba(0, 0, 0, 0.19)"
                px={5}
                py={3}
            >
                {props.children}
            </Box>
        </Box>
    );
}

function CheckboxOptionCard({ value, isChecked, onChange, children }) {
    return (
        <Box
            as="label"
            minW={320}
            maxW={{ base: "80%", lg: "100%" }}
            minH={45}
            textAlign="center"
        >
            <Input
                type="checkbox"
                display="none"
                value={value}
                onChange={onChange}
            />
            <Box
                cursor="pointer"
                borderWidth={0.5}
                borderRadius={50}
                background={isChecked ? "#FFB8F9" : "#fff"}
                color={isChecked ? "white" : "black"}
                borderColor={isChecked ? "#FFB8F9" : "rgba(0, 0, 0, 0.19)"}
                px={5}
                py={3}
            >
                {children}
            </Box>
        </Box>
    );
}

function SliderBarSection({
    index,
    question,
    sectionBarColors,
    totalQuestions,
}) {
    const [show, setShow] = useState(null);

    const onLoad = () => {
        setShow(true);
        setTimeout(() => setShow(false), 3000);
    };
    const confettiText = [
        "Excellent, you're doing great!",
        "Well done, keep it up!",
        "Fantastic progress!",
        "Almost finished, good job!",
    ];
    const questionsPerSection = Math.ceil(totalQuestions / 5);
    const questionsInLastSection = totalQuestions - questionsPerSection * 4;
    const questionsInCurrentSection =
        index === 4 ? questionsInLastSection : questionsPerSection;
    const isActiveSection =
        Math.floor(question._index / questionsPerSection) > index;
    const isNewSection =
        Math.ceil(question._index / questionsPerSection) === index + 1 &&
        question._index % questionsPerSection === 1;
    const isInProgressSection =
        Math.ceil(question._index / questionsPerSection) === index + 1 &&
        question._index % questionsPerSection > 1;
    // if section is completed
    if (isActiveSection) {
        return (
            <Box w="100%" key={index}>
                <Box
                    h="13px"
                    bg={sectionBarColors[index]}
                    borderRadius="20px"
                />
            </Box>
        );
    }
    // if a new section starts
    if (isNewSection) {
        if (Math.ceil(question._index / questionsPerSection) > 1) {
            return (
                <Box w="100%" pos="relative" key={index}>
                    <Fade in={show} onLoad={() => setTimeout(onLoad)}>
                        <Box
                            pos="absolute"
                            left="-80px"
                            top="-140px"
                            w="150px"
                            h="80px"
                        >
                            <Image
                                w="150px"
                                mx="auto"
                                src={congratulationsImage}
                            />
                            <Box
                                w="150px"
                                h="24px"
                                lineHeight="24px"
                                fontSize="11px"
                                fontWeight="600"
                                mx="auto"
                                bg={"#B2CEFF"}
                                borderRadius="30px"
                            >
                                {confettiText[index - 1]}
                            </Box>
                        </Box>
                    </Fade>
                    <Box
                        w="5%"
                        h="13px"
                        bg={sectionBarColors[index]}
                        borderRadius="20px"
                    />
                </Box>
            );
        } else {
            return (
                <Box w="100%" key={index}>
                    <Box
                        w="5%"
                        h="13px"
                        bg={sectionBarColors[index]}
                        borderRadius="20px"
                    />
                </Box>
            );
        }
    }

    // if a section is in progress
    if (isInProgressSection) {
        const progressWidth =
            ((question._index % questionsPerSection) /
                questionsInCurrentSection) *
                100 +
            "%";

        return (
            <Box w="100%" key={index}>
                <Box
                    w={progressWidth}
                    h="13px"
                    bg={sectionBarColors[index]}
                    borderRadius="20px"
                />
            </Box>
        );
    }

    // default case (not active, not started and not in progress)
    return (
        <Box w="100%" key={index}>
            <Box h="13px" bg="none" borderRadius="20px" />
        </Box>
    );
}

function SliderBar({
    sectionColors,
    sectionBarColors,
    question,
    totalQuestions,
}) {
    return (
        <Box pos="relative" w="100%" h="23px" bg="#FFFFFF" borderRadius={20}>
            <HStack
                mx="13px"
                mt="5px"
                pos="absolute"
                w="calc(100% - 26px)"
                h="13px"
                zIndex={1}
                gap="11px"
            >
                {sectionColors.map((color, index) => (
                    <Box w="100%" key={"base" + index + color}>
                        <Box
                            h="13px"
                            bg={sectionBarColors[index]}
                            opacity=".2"
                            borderRadius="20px"
                        />
                    </Box>
                ))}
            </HStack>
            <HStack
                mx="13px"
                mt="5px"
                pos="absolute"
                w="calc(100% - 26px)"
                h="13px"
                zIndex={2}
                gap="11px"
            >
                {sectionColors.map((color, index) => (
                    <SliderBarSection
                        key={"active" + index + color}
                        index={index}
                        question={question}
                        sectionBarColors={sectionBarColors}
                        totalQuestions={totalQuestions}
                    />
                ))}
            </HStack>
        </Box>
    );
}

type QuestionsProps = {
    questionnaireData: any;
    onSaveAnswer: any;
};

export function QuestionsComponent({
    questionnaireData,
    onSaveAnswer,
}: QuestionsProps & RouteComponentProps) {
    const { questions, answers } = questionnaireData.data;

    const params = useParams();
    const questionIndex = params?.questionIndex || 0;
    const isMultipleSelect =
        questions[questionIndex - 1].numberOfAnswers === "multiple";

    const navigate = useNavigate();
    const [modified, setModified] = useState(null);
    const [Id_Answer, setId_Answer] = useState(null);
    const [enableContinue, setEnableContinue] = useState(true); // Initialize to true

    const question = questions[questionIndex - 1];
    question._index = questionIndex;
    if (questionIndex > 1) {
        question.onPrevious = () => {
            navigate(`${questionIndex - 1}?action=previous`);
        };
    }
    question.onNext = () => {
        if (typeof Id_Answer === "number") {
            onSaveAnswer(question.Id, Id_Answer);
        }
        if (typeof Id_Answer === "string") {
            onSaveAnswer(question.Id, parseInt(Id_Answer));
        }
        if (typeof Id_Answer === "object" && isArray(Id_Answer)) {
            onSaveAnswer(
                question.Id,
                Id_Answer.map((v) => parseInt(v)),
            );
        }
    };

    const answerOptions = useMemo(() => {
        if (question.shuffleAnswers === false) {
            return moveOtherToEnd(question.Answers);
        }
        return shuffleArray(question.Answers);
    }, [question.Id]);

    useEffect(() => {
        if (modified !== null && modified !== questionIndex) {
            setModified(null);
            return;
        }

        if (modified === null) {
            if (answers[question.Id]) {
                if (isMultipleSelect) {
                    if (isArray(answers[question.Id])) {
                        setId_Answer(answers[question.Id]);
                        setEnableContinue(answers[question.Id].length > 0);
                    }
                } else {
                    // Set existing answer if we have one (applicable when using previous & next buttons)
                    if (Id_Answer !== `${answers[question.Id]}`) {
                        setId_Answer(answers[question.Id]);
                        setEnableContinue(true);
                    }
                }
            } else {
                setId_Answer(null);
                setEnableContinue(false);
            }
        }
    }, [answers, question.Id, questionIndex, modified, Id_Answer]);

    return (
        <>
            <Heading {...(FocusStyles.Heading as HeadingProps)}>
                Screening Questionnaire
            </Heading>
            <Card {...(FocusStyles.Card as CardProps)}>
                <CardHeader {...FocusStyles.BodySection}>
                    <Heading {...FocusStyles.CardHeading} mb="0">
                        {question.Text}
                    </Heading>
                </CardHeader>

                <CardBody {...FocusStyles.BodySection}>
                    {isMultipleSelect ? (
                        <MultipleSelectComponent
                            answerOptions={answerOptions}
                            currentAnswer={Id_Answer}
                            modified={modified}
                            setModified={setModified}
                            questionIndex={questionIndex}
                            setId_Answer={setId_Answer}
                            setEnableContinue={setEnableContinue}
                        />
                    ) : (
                        <SignleSelectComponent
                            answerOptions={answerOptions}
                            currentAnswer={Id_Answer}
                            modified={modified}
                            setModified={setModified}
                            questionIndex={questionIndex}
                            setId_Answer={setId_Answer}
                            setEnableContinue={setEnableContinue}
                        />
                    )}
                </CardBody>

                <CardFooter {...FocusStyles.BodySection} mb="5px">
                    <VStack w="100%">
                        <HStack minW={"100%"} w="100%" mb="30px">
                            {question.onPrevious && (
                                <Button
                                    {...FocusStyles.Button}
                                    ml="0"
                                    mr="auto"
                                    zIndex="100"
                                    onClick={question.onPrevious}
                                >
                                    PREVIOUS
                                </Button>
                            )}

                            <AdminMakeCompleteQuestionnaireButtonComponent />

                            <Button
                                actionMode="once"
                                zIndex="100"
                                key={questionIndex + enableContinue}
                                {...FocusStyles.Button}
                                marginLeft="auto !important"
                                onClick={question.onNext}
                                disabled={!enableContinue}
                            >
                                CONTINUE
                            </Button>
                        </HStack>

                        <SliderBar
                            sectionColors={sectionColors}
                            sectionBarColors={sectionBarColors}
                            question={question}
                            totalQuestions={questions.length}
                        />
                    </VStack>
                </CardFooter>
            </Card>
        </>
    );
}

export const SignleSelectComponent = ({
    answerOptions,
    currentAnswer,
    modified,
    setModified,
    questionIndex,
    setId_Answer,
    setEnableContinue,
}) => {
    const {
        getRootProps,
        getRadioProps,
        value: Id_Answer,
        setValue,
    } = useRadioGroup({
        defaultValue: currentAnswer,
        onChange: (value) => {
            setModified(questionIndex);
            setId_Answer(value);
            setValue(value);
            setEnableContinue(true);
        },
    });
    const radioGroup = getRootProps();
    useEffect(() => {
        if (modified === null) {
            if (currentAnswer) {
                if (Id_Answer !== `${currentAnswer}`) {
                    setValue(`${currentAnswer}`);
                    setId_Answer(`${currentAnswer}`);
                    setEnableContinue(true);
                }
            } else {
                setValue(null);
                setId_Answer(null);
                setEnableContinue(false);
            }
        }
    }, [modified, currentAnswer, questionIndex]);

    return (
        <VStack minH={{ base: "200px" }} gap="20px" {...radioGroup}>
            {answerOptions.map((answer: any) => {
                const radio = getRadioProps({
                    value: `${answer.Id}`,
                });
                return (
                    <RadioOptionCard key={answer.Id} {...radio}>
                        {answer.Text}
                    </RadioOptionCard>
                );
            })}
        </VStack>
    );
};

export const MultipleSelectComponent = ({
    answerOptions,
    currentAnswer,
    modified,
    setModified,
    questionIndex,
    setId_Answer,
    setEnableContinue,
}) => {
    const [selectedValues, setSelectedValues] = useState([]);
    useEffect(() => {
        if (modified === null) {
            setSelectedValues(currentAnswer || []);
            setEnableContinue(currentAnswer && currentAnswer.length > 0);
        }
    }, [modified, currentAnswer, questionIndex]);

    const handleCheckboxChange = (value) => {
        const isSelected = selectedValues.includes(value);
        const newSelectedValues = isSelected
            ? selectedValues.filter((v) => v !== value)
            : [...selectedValues, value];

        updateState(newSelectedValues);
    };

    const updateState = (newSelectedValues) => {
        setSelectedValues(newSelectedValues);
        setId_Answer(newSelectedValues);
        setEnableContinue(newSelectedValues.length > 0);
        setModified(questionIndex);
    };

    return (
        <VStack minH={{ base: "200px" }} gap="20px">
            {answerOptions.map(({ Id, Text }) => (
                <CheckboxOptionCard
                    key={Id}
                    value={Id}
                    isChecked={
                        Array.isArray(selectedValues) &&
                        selectedValues.includes(Id)
                    }
                    onChange={() => handleCheckboxChange(Id)}
                >
                    {Text}
                </CheckboxOptionCard>
            ))}
        </VStack>
    );
};
