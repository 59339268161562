import React, { FunctionComponent, useState } from "react";
import {
    Box,
    Text,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Button,
    chakra,
} from "@chakra-ui/react";
import { AiOutlineDownload } from "react-icons/ai";

interface ImportProps {
    onImport: (file: File) => void;
}

export const Import: FunctionComponent<ImportProps> = ({ onImport }) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const [file, setFile] = useState<File | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [isDragActive, setIsDragActive] = useState(false);

    const handleDragOver = (e: React.DragEvent) => {
        e.preventDefault();
        setIsDragActive(true);
    };

    const handleDragLeave = () => {
        setIsDragActive(false);
    };

    const handleDrop = (e: React.DragEvent) => {
        e.preventDefault();
        setIsDragActive(false);
        setFile(e.dataTransfer.files[0]);
    };

    const handleClick = () => {
        let input = document.createElement("input");
        input.type = "file";
        input.accept = ".csv";
        input.onchange = () => {
            setFile(input.files ? input.files[0] : null);
        };
        input.click();
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        // TODO: handle file upload here
        if (!file) {
            setError("No file selected");
            return;
        }
        onImport(file);
        onClose();
    };

    return (
        <>
            <Button
                leftIcon={<AiOutlineDownload size="14px" />}
                color="#0051DB"
                variant="outline"
                borderColor="#0051DB"
                borderRadius={"41px"}
                height={"22px"}
                fontSize={"12px"}
                onClick={onOpen}
            >
                Import
            </Button>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent maxW="724px">
                    <ModalHeader
                        mt="14px"
                        fontFamily="Nunito"
                        fontStyle="normal"
                        fontWeight="700"
                        fontSize="26px"
                        lineHeight="32px"
                        color="#032E59"
                    >
                        Upload CSV to Grade 8A
                    </ModalHeader>
                    <chakra.form onSubmit={handleSubmit}>
                        <ModalBody>
                            <Box
                                onClick={handleClick}
                                onDrop={handleDrop}
                                onDragOver={handleDragOver}
                                onDragLeave={handleDragLeave}
                                w="476px"
                                h="126px"
                                mx="auto"
                                my="4"
                                bg="#FFFFFF"
                                border="1px solid #ACCBFF"
                                borderRadius="16px"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                textAlign="center"
                                cursor="pointer"
                            >
                                <Text fontSize="md" color="black">
                                    {file
                                        ? file.name
                                        : "Drag CSV Here or, click to browse"}
                                </Text>
                            </Box>
                            <Text
                                fontFamily="Nunito"
                                fontStyle="normal"
                                fontWeight="400"
                                fontSize="14px"
                                lineHeight="16px"
                                color="#032E59"
                                textAlign="center"
                                mb="4"
                            >
                                {error}
                            </Text>
                        </ModalBody>
                        <ModalFooter justifyContent="center">
                            <Button
                                w="100px"
                                h="30px"
                                bg="#fff"
                                border="1px solid #ACCBFF"
                                borderRadius="37px"
                                color="#032E59"
                                fontSize="14px"
                                fontWeight="400"
                                lineHeight="16px"
                                mr="10px"
                                onClick={onClose}
                            >
                                Cancel
                            </Button>
                            <Button
                                w="100px"
                                h="30px"
                                bg="#136AFF"
                                borderRadius="37px"
                                color="#fff"
                                fontSize="14px"
                                fontWeight="400"
                                lineHeight="16px"
                                type="submit"
                                disabled={!file}
                            >
                                Upload
                            </Button>
                        </ModalFooter>
                    </chakra.form>
                </ModalContent>
            </Modal>
        </>
    );
};
