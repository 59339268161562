import React from "react";
import { Text } from "@chakra-ui/react";

export function ErrorMessage ({ feedback }) {

    let message = null

    if (!feedback?.error?.code) {
        return null
    }

    switch (feedback.error.code) {

        case 'ACCOUNT_WITH_EMAIL_ALREADY_EXISTS':
            message = (<>An account with your email address already exists.</>)
            break

        case 'EXPIRED_REGISTRATION_TOKEN':
            message = (<>Your registration link has expired. Please request a new one
                from your Rellie license administrator.</>)
            break

        case 'NO_INVITE_TOKEN_FOR_USER_EMAIL':
            message = (<>We could not find an invite token for your email address.</>)
            break

        case 'INVITE_EMAIL_DOES_NOT_MATCH_USER_EMAIL':
            message = (<>We found an invite token but the email address does not match. Please request a new invite token.</>)
            break

        case 'OLD_REGISTRATION_TOKEN':
            message = (<>
                You are not using the most recent registration invitation.
                Please look for the latest email and try again. <br />
                If you cannot find it, please request a new one from your Rellie
                license administrator.
            </>)
            break

        case 'OLD_PASSWORD_RESET_TOKEN':
            message = (<> You are not using the most recent password reset invitation.
                Please look for the latest email and try again. <br />
                If you cannot find it, please request a new password reset email.</>)
            break

        case'EXPIRED_PASSWORD_RESET_TOKEN':
            message = (<>Your password reset link has expired. Please request a new
                password reset email.</>)
            break

        case 'NOT_A_PASSWORD_RESET_TOKEN':
            message = (<>You are not using the most recent password reset invitation.
                Please look for the latest email and try again. <br />
                If you cannot find it, please request a new password reset email.</>)
            break

        case 'MUST_USE_DIFFERENT_PASSWORD':
            message = (<>You cannot use the same password as before. You must enter a new password.<br/>
                Please try again by creating a new password that you have not used before on Rellie.</>)
            break

        case 'VERIFICATION_CODE_EXPIRED':
            message = (<>The code has expired. Please click on 'Resend Code' to
                request a new one.</>)
            break

        case 'VERIFICATION_CODE_MISMATCH':
            message = (<>The code does not match. Please try entering it again.</>)
            break

        case 'USER_MUST_USE_GOOGLE_OAUTH_TO_REGISTER':
            message = (<>You must sign in with a Google Account to register.</>)
            break

        case 'UNKNOWN_ERROR':
        case 'INTERNAL_SERVER_ERROR':
        case 'BAD_REQUEST':
        default:
            message = (<>An unexpected error occured. Please try again. If the error persists, please let your Rellie
                license administrator know you are stuck on error code: {feedback.error.code}</>)
            break
    }

    if (!message) {
        return null
    }

    return (
        <Text
            fontSize={"14px"}
            color="#FF0000"
            fontWeight={"bold"}
        >
            {message}
        </Text>
    )
}
