import React from "react";
import { Text } from "@chakra-ui/react";

export function SuccessMessage ({ feedback }) {

    let message = null

    if (!feedback?.success?.code) {
        return null
    }

    switch (feedback.success.code) {

        case 'VERIFICATION_CODE_RESENT':
            message = (<>A new code has been sent.</>)
            break


        default:
            message = (<>Success code: {feedback.success.code}</>)
            break
    }

    if (!message) {
        return null
    }

    return (
        <Text
            fontSize={"14px"}
            color="#1CCF8C"
            fontWeight={"bold"}
        >
            {message}
        </Text>
    )
}
