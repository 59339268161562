import {
    Box,
    Flex,
    Text,
    Button,
    Card,
    CardHeader,
    Heading,
    CardBody,
    CardFooter,
    Switch,
    VStack,
    HStack,
    CardBodyProps,
    CardProps,
    HeadingProps,
    useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { sectionBarColors, sectionColors } from "./Questions";
import { Link as ReachLink, RouteComponentProps } from "@reach/router";
import { useApp, isAdminToolsEnabled } from "../../../lib/app/App";
import { FocusStyles } from "../../styles";
import { ShareReportWithSchoolModal } from "../parent/ShareReportWithSchoolModal";

type WelcomeToQuestionnaireProps = {
    firstQuestionPath: string;
    dashboardPath: string;
    showParentDisclaimer: boolean;
};

export function WelcomeComponent({
    firstQuestionPath,
    dashboardPath,
    showParentDisclaimer,
}: WelcomeToQuestionnaireProps & RouteComponentProps) {
    const { useData, api, isUserRole } = useApp();

    let profilePermissionsData = {};
    if (isUserRole('parent')) {
        profilePermissionsData = useData("Parent.ProfilePermissions");
    }

    async function onToggleShareWithTeacher() {
        await api.Parent.ProfilePermissions.ToggleShareWithTeacher.mutate({
            ShareWithTeacher: !profilePermissionsData.data["SHARE_WITH_SCHOOL"],
        });
        profilePermissionsData.refetch();
    }

    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <ShareReportWithSchoolModal
                isOpen={isOpen}
                onClose={onClose}
                onConfirm={async () => {
                    await onToggleShareWithTeacher();
                }}
            />

            <Heading {...(FocusStyles.Heading as HeadingProps)}>
                Screening Questionnaire
            </Heading>
            <Card {...(FocusStyles.Card as CardProps)}>
                <CardHeader {...FocusStyles.BodySection}>
                    <Heading {...FocusStyles.CardHeading}>
                        Welcome to the Rellie Screening Questionnaire
                    </Heading>
                    <Text {...FocusStyles.CardSummary}>
                        The Questionnaire is broken down into the subsections
                        below. You can stop and continue later at any point, but
                        it’s best if you complete it all at once.
                    </Text>
                </CardHeader>
                <CardBody
                    {...(FocusStyles.BodySection as CardBodyProps)}
                    {...(FocusStyles.Columns as CardBodyProps)}
                >
                    {sectionColors.map((color, index) => (
                        <Box key={index} w={"100%"} fontWeight={700}>
                            <Flex
                                mx="auto"
                                w={{ base: "100%", lg: "80%" }}
                                placeContent="center"
                                placeItems="center"
                                h={{
                                    base: "35px",
                                    lg: "45px",
                                }}
                                bg={color}
                                borderRadius="40px"
                            >
                                <Text>SECTION {index + 1}</Text>
                            </Flex>
                            <Box
                                mt={{
                                    base: "18px",
                                    lg: "32px",
                                }}
                                h="13px"
                                bg={sectionBarColors[index]}
                                borderRadius="20px"
                            />
                        </Box>
                    ))}
                </CardBody>
                <CardFooter {...FocusStyles.BodySection}>
                    <VStack spacing={"15px"} w="100%">
                        <HStack w="100%">
                            <Button
                                {...FocusStyles.Button}
                                ml="0"
                                mr="auto"
                                as={ReachLink}
                                to={dashboardPath}
                            >
                                DO IT LATER
                            </Button>

                            <Button
                                {...FocusStyles.Button}
                                ml="auto"
                                mr="0"
                                bg="#0B7064"
                                as={ReachLink}
                                to={firstQuestionPath}
                            >
                                START
                            </Button>
                        </HStack>

                        {showParentDisclaimer && (profilePermissionsData.isFetched || profilePermissionsData.data) && (
                            <VStack spacing={"36px"} pt={"40px"}>
                                {profilePermissionsData.data[
                                    "SHARE_WITH_SCHOOL"
                                ] !== undefined && (
                                    <HStack>
                                        {isAdminToolsEnabled() && (
                                            <Button
                                                size="xs"
                                                backgroundColor={"red"}
                                                onClick={
                                                    onToggleShareWithTeacher
                                                }
                                            >
                                                Toggle
                                            </Button>
                                        )}
                                        <Switch
                                            colorScheme="green"
                                            isChecked={
                                                profilePermissionsData.data[
                                                    "SHARE_WITH_SCHOOL"
                                                ]
                                            }
                                            disabled={
                                                !!profilePermissionsData.data[
                                                    "SHARE_WITH_SCHOOL"
                                                ]
                                            }
                                            onChange={onOpen}
                                        />
                                        <Box>
                                            Yes, I agree to share reports with
                                            my child’s school.
                                        </Box>
                                    </HStack>
                                )}
                                <Text>
                                    <strong>Disclaimer: </strong>A baseline
                                    screening questionnaire, like all screeners,
                                    is not a diagnostic tool. “At risk” / “low
                                    score” status indicates which students
                                    require more attention, such as more
                                    intensive instruction, specific strategies
                                    to support learning or further assessment.
                                </Text>
                            </VStack>
                        )}
                    </VStack>
                </CardFooter>
            </Card>
        </>
    );
}
