import React, { FC } from "react";
import type { RouteComponentProps } from "@reach/router";
import {
    Box,
    Button,
    Card,
    CardBody,
    Grid,
    GridItem,
    Heading,
    HStack,
    Icon,
    Spacer,
    Table,
    TableContainer,
    Tbody,
    Td,
    Text,
    Tr,
    VStack,
} from "@chakra-ui/react";
import { IoPerson } from "react-icons/io5";
import { useQuery } from "@tanstack/react-query";
import { Searchbar } from "../../components/common/Searchbar";
import { Loading } from "../../components/common/Loading";

type ProfileInfo = {
    email: string;
    type: string;
    firstname: string;
    lastname: string;
};

export const SchoolProfilePage: FC<RouteComponentProps> = () => {
    const { data, isLoading } = useQuery<ProfileInfo>(["profileInfo"], () => {
        return new Promise<ProfileInfo>((resolve) => {
            resolve({
                email: "mcmora94@gmail.com",
                type: "Teacher",
                firstname: "Javier",
                lastname: "Fuentes Mora",
            });
        });
    });
    if (isLoading) {
        return <Loading />;
    }

    return (
        <VStack w={"100%"} spacing={"17px"}>
            <Searchbar />
            <HStack spacing={"25px"} w={"100%"} maxW={"1130px"}>
                <Box
                    bg={"#FFFFFF"}
                    borderRadius={"50%"}
                    w={"109px"}
                    h={"109px"}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <Icon
                        fontSize="62"
                        _groupHover={{
                            color: "white",
                        }}
                        color={"#136AFF"}
                        as={IoPerson}
                    />
                </Box>
                <VStack alignItems={"flex-start"}>
                    <Heading size={"md"} color={"#032E59"}>
                        {data?.firstname} {data?.lastname}
                    </Heading>
                </VStack>
            </HStack>

            <Text
                as={"span"}
                color={"#032E59"}
                fontWeight={"700"}
                w={"100%"}
                maxW={"850px"}
                mt={"21px"}
            >
                PROFILE
            </Text>
            <Grid
                templateColumns={"repeat(1, 1fr)"}
                gap={"33.5px"}
                w={"100%"}
                maxW={"850px"}
                bg={"rgba(19, 106, 255, 0.03)"}
                padding={"14px 20px"}
                borderRadius={"20px"}
            >
                <GridItem
                    overflow={{
                        base: "auto",
                        lg: "hidden",
                    }}
                >
                    <VStack alignItems={"flex-start"}>
                        <Heading size={"sm"} color={"#032E59"}>
                            Your Info
                        </Heading>
                        <Card w={"100%"} bg={"#FFFFFF"} borderRadius={"16px"}>
                            <CardBody>
                                <TableContainer>
                                    <Table variant="unstyled">
                                        <Tbody>
                                            <Tr
                                                borderBottom={
                                                    "0.5px dashed #AAAAAA"
                                                }
                                                fontSize={"10px"}
                                                color={"#5F6368"}
                                            >
                                                <Td w={"10%"}>Full Name</Td>
                                                <Td
                                                    display={"flex"}
                                                    alignItems={"center"}
                                                    justifyContent={
                                                        "space-between"
                                                    }
                                                    borderRadius={"60px"}
                                                >
                                                    <Text
                                                        fontSize={"14px"}
                                                        color={"#000000"}
                                                    >
                                                        {data?.firstname}{" "}
                                                        {data?.lastname}
                                                    </Text>
                                                    <Text
                                                        as={"button"}
                                                        color={"#032E59"}
                                                    >
                                                        Edit Name
                                                    </Text>
                                                </Td>
                                            </Tr>

                                            <Tr
                                                borderBottom={
                                                    "0.5px dashed #AAAAAA"
                                                }
                                                fontSize={"10px"}
                                                color={"#5F6368"}
                                            >
                                                <Td w={"10%"}>Email</Td>
                                                <Td
                                                    display={"flex"}
                                                    alignItems={"center"}
                                                    justifyContent={
                                                        "space-between"
                                                    }
                                                    borderRadius={"60px"}
                                                >
                                                    <Text
                                                        fontSize={"14px"}
                                                        color={"#000000"}
                                                    >
                                                        {data?.email}
                                                    </Text>

                                                    <Spacer />
                                                </Td>
                                            </Tr>
                                            <Tr
                                                borderBottom={
                                                    "0.5px dashed #AAAAAA"
                                                }
                                                fontSize={"10px"}
                                                color={"#5F6368"}
                                            >
                                                <Td w={"10%"}>Password</Td>
                                                <Td
                                                    display={"flex"}
                                                    alignItems={"center"}
                                                    justifyContent={
                                                        "space-between"
                                                    }
                                                    borderRadius={"60px"}
                                                >
                                                    <Text
                                                        fontSize={"14px"}
                                                        color={"#000000"}
                                                    >
                                                        ***********
                                                    </Text>

                                                    <Text
                                                        as={"button"}
                                                        color={"#032E59"}
                                                        ml={"10px"}
                                                    >
                                                        Change Password
                                                    </Text>
                                                </Td>
                                            </Tr>
                                            <Tr
                                                fontSize={"10px"}
                                                color={"#5F6368"}
                                            >
                                                <Td w={"10%"}>Account type</Td>
                                                <Td
                                                    display={"flex"}
                                                    alignItems={"center"}
                                                    justifyContent={
                                                        "space-between"
                                                    }
                                                    borderRadius={"60px"}
                                                >
                                                    <Text
                                                        fontSize={"14px"}
                                                        color={"#000000"}
                                                    >
                                                        {data?.type}
                                                    </Text>

                                                    <Text
                                                        as={"button"}
                                                        color={"#032E59"}
                                                    >
                                                        Change
                                                    </Text>
                                                </Td>
                                            </Tr>
                                        </Tbody>
                                    </Table>
                                </TableContainer>
                            </CardBody>
                        </Card>
                    </VStack>
                </GridItem>
            </Grid>
            <HStack justifyContent={"flex-end"} w={"100%"} maxW={"850px"}>
                <Button
                    border={"1px solid #ACCBFF"}
                    borderRadius={"37px"}
                    variant="ghost"
                    color={"#5F6368"}
                    fontSize={"12px"}
                    minW={"83px"}
                >
                    Cancel
                </Button>
                <Button
                    minW={"110px"}
                    fontSize={"12px"}
                    bg={"#0051DB"}
                    borderRadius={"37px"}
                    variant="solid"
                    color={"#fff"}
                >
                    Save changes
                </Button>
            </HStack>
        </VStack>
    );
};
