import React, { FunctionComponent, useState } from "react";
import {
    Button,
    Heading,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Radio,
    RadioGroup,
    Text,
    VStack,
} from "@chakra-ui/react";
import { InputForm } from "../common/InputForm";
import { Controller, useForm, useWatch } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useApp } from "../../../lib/app/App";
import { AdminFillFormButton } from "../../../lib/app/AdminFillFormButton";

type Props = {
    isOpen: boolean;
    onClose: () => void;
    addStudent: (data: unknown) => Promise<unknown>;
    modalContext: {
        Grade: number;
        Class: string;
    } | null;
};
export const AddStudentModal: FunctionComponent<Props> = ({
    isOpen,
    onClose,
    addStudent,
    modalContext,
}) => {
    const { useData } = useApp();
    const userData = useData("User");

    const [registrationType, setRegistrationType] = useState("");

    function onAutofill() {
        const randomId = Math.floor(Math.random() * 9000 + 1000);

        const values = {
            StudentId: `${randomId}`,
            StudentFirstName: `${
                userData.data.user.FirstName.split(" ")[0]
            } - Student ${randomId}`,
            StudentLastName: `${userData.data.user.LastName}`,
            StudentEmail:
                (userData.data.user.Email &&
                    userData.data.user.Email.replace(
                        /(\+[^@]+)?@/,
                        `+student-${randomId}@`,
                    )) ||
                "",
            RegistrationType: "PARENT",
            ParentFirstName: `${
                userData.data.user.FirstName.split(" ")[0]
            } - Parent ${randomId}`,
            ParentLastName: `${userData.data.user.LastName}`,
            ParentEmail:
                (userData.data.user.Email &&
                    userData.data.user.Email.replace(
                        /(\+[^@]+)?@/,
                        `+parent-${randomId}@`,
                    )) ||
                "",
        };

        Object.keys(values).forEach((name) => {
            setValue(name as keyof typeof values, values[name]);
        });
    }

    const schemaObject = z.object({
        StudentFirstName: z
            .string()
            .min(1, "First name is required")
            .max(64, "First name is too long"),
        StudentLastName: z
            .string()
            .min(1, "Last name is required")
            .max(64, "Last name is too long"),
        StudentEmail: z
            .string()
            .email("Invalid email address")
            .max(256, "Email is too long"),
        StudentId: z.string().optional(),
        RegistrationType: z.string().min(1, "Registration type is required"),
    });

    const parentSchema = schemaObject.extend({
        ParentFirstName: z
            .string()
            .min(1, "First name is required")
            .max(64, "First name is too long"),
        ParentLastName: z
            .string()
            .min(1, "Last name is required")
            .max(64, "Last name is too long"),
        ParentEmail: z
            .string()
            .email("Invalid email address")
            .max(256, "Email is too long"),
    });

    const teacherDirectSchema = schemaObject.extend({
        ParentFirstName: z
            .string()
            .max(64, "First name is too long")
            .or(z.string().length(0)),
        ParentLastName: z
            .string()
            .max(64, "Last name is too long")
            .or(z.string().length(0)),
        ParentEmail: z
            .string()
            .email("Invalid email address")
            .or(z.string().length(0)),
    });

    const customResolver = (values, context, options) => {
        let schemaToUse;
        if (values.RegistrationType === "PARENT") {
            schemaToUse = parentSchema;
        } else {
            schemaToUse = teacherDirectSchema;
        }
        return zodResolver(schemaToUse)(values, context, options);
    };

    const {
        register,
        control,
        formState: { errors },
        handleSubmit,
        setValue,
        clearErrors,
    } = useForm<{
        StudentFirstName: string;
        StudentLastName: string;
        StudentId: string;
        StudentEmail: string;
        ParentFirstName: string;
        ParentLastName: string;
        ParentEmail: string;
        Grade: number;
        Class: string;
        RegistrationType: string;
    }>({
        resolver: customResolver,
    });

    const formRegistrationType = useWatch({
        control,
        name: "RegistrationType",
        defaultValue: "",
    });

    if (registrationType !== formRegistrationType) {
        setRegistrationType(formRegistrationType);
        clearErrors();
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent
                borderRadius={"15px"}
                p={5}
                minW={"732px"}
                minH={"243px"}
            >
                <ModalHeader color={"#032E59"}>
                    <Heading size={"sm"}>Add Student</Heading>
                    <Text
                        fontSize={"14px"}
                        fontWeight={"400"}
                        lineHeight={"19px"}
                    >
                        Enter the information below
                    </Text>
                    <AdminFillFormButton onClick={onAutofill} />
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    color={"black"}
                    fontSize={"14px"}
                    gap={"27px"}
                    as={"form"}
                    onSubmit={handleSubmit((data) => {
                        data.ParentFirstName = data.ParentFirstName || "";
                        data.ParentLastName = data.ParentLastName || "";
                        data.ParentEmail = data.ParentEmail || "";
                        data.Grade = modalContext.Grade;
                        data.Class = modalContext.Class;

                        addStudent(data);
                    })}
                >
                    <HStack justifyContent={"center"} alignItems={"center"}>
                        <VStack alignItems={"flex-start"}>
                            <Text fontSize={"12px"}>Student First Name</Text>

                            <InputForm
                                placeholder={"Enter student name"}
                                name={"StudentFirstName"}
                                required={true}
                                register={register}
                                height={"30px"}
                                width={"320px"}
                                rest={{
                                    fontSize: "14px",
                                    border: "1px solid #ACCBFF",
                                    borderRadius: "37px",
                                    borderColor: "#ACCBFF",
                                }}
                                error={
                                    errors?.StudentFirstName?.message as
                                        | string
                                        | undefined
                                }
                            />
                        </VStack>
                        <VStack alignItems={"flex-start"}>
                            <Text fontSize={"12px"}>Student Last Name</Text>

                            <InputForm
                                placeholder={"Enter student last name"}
                                name={"StudentLastName"}
                                required={true}
                                register={register}
                                height={"30px"}
                                width={"320px"}
                                rest={{
                                    fontSize: "14px",
                                    border: "1px solid #ACCBFF",
                                    borderRadius: "37px",
                                    borderColor: "#ACCBFF",
                                }}
                                error={
                                    errors?.StudentLastName?.message as
                                        | string
                                        | undefined
                                }
                            />
                        </VStack>
                    </HStack>
                    <HStack>
                        <VStack alignItems={"flex-start"}>
                            <Text fontSize={"12px"}>Student Id</Text>
                            <InputForm
                                placeholder={"Enter student Id"}
                                name={"StudentId"}
                                required={true}
                                register={register}
                                type={"text"}
                                height={"30px"}
                                width={"320px"}
                                rest={{
                                    fontSize: "14px",
                                    border: "1px solid #ACCBFF",
                                    borderRadius: "37px",
                                    borderColor: "#ACCBFF",
                                }}
                                error={
                                    errors?.StudentId?.message as
                                        | string
                                        | undefined
                                }
                            />
                        </VStack>
                        <VStack alignItems={"flex-start"}>
                            <Text fontSize={"12px"}>Student Email</Text>
                            <InputForm
                                placeholder={"Enter student email"}
                                name={"StudentEmail"}
                                required={true}
                                register={register}
                                type={"email"}
                                height={"30px"}
                                width={"320px"}
                                rest={{
                                    fontSize: "14px",
                                    border: "1px solid #ACCBFF",
                                    borderRadius: "37px",
                                    borderColor: "#ACCBFF",
                                }}
                                error={
                                    errors?.StudentEmail?.message as
                                        | string
                                        | undefined
                                }
                            />
                        </VStack>
                    </HStack>
                    <HStack>
                        <VStack alignItems={"flex-start"}>
                            <Text fontSize={"12px"}>Grade</Text>
                            <Text>{modalContext.Grade}</Text>
                        </VStack>
                        <VStack alignItems={"flex-start"}>
                            <Text fontSize={"12px"}>Class</Text>
                            <Text>{modalContext.Class}</Text>
                        </VStack>
                    </HStack>
                    <VStack
                        alignItems={"flex-start"}
                        w={"100%"}
                        spacing={"15px"}
                        borderTop={"0.5px solid #AAAAAA"}
                        borderBottom={"0.5px solid #AAAAAA"}
                        padding={"15px 0"}
                    >
                        <Text
                            fontSize={"14px"}
                            fontWeight={"400"}
                            lineHeight={"19px"}
                            color={"#032E59"}
                        >
                            Select Registration Type:
                        </Text>
                        <HStack w={"100%"}>
                            <Controller
                                name="RegistrationType"
                                control={control}
                                render={({ field }) => (
                                    <RadioGroup
                                        {...field}
                                        display={"flex"}
                                        w={"100%"}
                                        flexDirection={"column"}
                                    >
                                        <HStack>
                                            <VStack
                                                w={"264px"}
                                                h={"34px"}
                                                justifyContent={"center"}
                                                p={"14px"}
                                            >
                                                <Radio
                                                    size={"sm"}
                                                    value="PARENT"
                                                    w={"100%"}
                                                    isDisabled={false}
                                                >
                                                    <VStack
                                                        alignItems={
                                                            "flex-start"
                                                        }
                                                        spacing={"4px"}
                                                    >
                                                        <Text>
                                                            Parent Registration
                                                        </Text>
                                                        <Text
                                                            fontSize={"12px"}
                                                            color={"#5F6368"}
                                                        >
                                                            Send parent an email
                                                            for registration.
                                                        </Text>
                                                    </VStack>
                                                </Radio>
                                            </VStack>
                                            <VStack
                                                w={"264px"}
                                                h={"34px"}
                                                justifyContent={"center"}
                                                p={"14px"}
                                            >
                                                <Radio
                                                    size={"sm"}
                                                    value="STUDENT"
                                                    w={"100%"}
                                                >
                                                    <VStack
                                                        alignItems={
                                                            "flex-start"
                                                        }
                                                        spacing={"4px"}
                                                    >
                                                        <Text>
                                                            Teacher Direct
                                                        </Text>
                                                        <Text
                                                            fontSize={"12px"}
                                                            color={"#5F6368"}
                                                        >
                                                            Send student an
                                                            email for
                                                            registration.
                                                        </Text>
                                                    </VStack>
                                                </Radio>
                                            </VStack>
                                        </HStack>
                                        {errors.RegistrationType && (
                                            <Text
                                                fontSize={"12px"}
                                                color={"red"}
                                                mt={"15px"}
                                                mr={"auto"}
                                                ml={"auto"}
                                            >
                                                {
                                                    errors?.RegistrationType
                                                        ?.message as string
                                                }
                                            </Text>
                                        )}
                                    </RadioGroup>
                                )}
                            />
                        </HStack>
                    </VStack>

                    <HStack>
                        <VStack alignItems={"flex-start"}>
                            <Text fontSize={"12px"}>Parent First Name</Text>

                            <InputForm
                                placeholder={"Enter parent name"}
                                name={"ParentFirstName"}
                                required={true}
                                register={register}
                                height={"30px"}
                                width={"320px"}
                                rest={{
                                    fontSize: "14px",
                                    border: "1px solid #ACCBFF",
                                    borderRadius: "37px",
                                    borderColor: "#ACCBFF",
                                }}
                                error={
                                    errors?.ParentFirstName?.message as
                                        | string
                                        | undefined
                                }
                            />
                        </VStack>

                        <VStack alignItems={"flex-start"}>
                            <Text fontSize={"12px"}>Parent Last Name</Text>

                            <InputForm
                                placeholder={"Enter parent Last Name"}
                                name={"ParentLastName"}
                                required={true}
                                register={register}
                                height={"30px"}
                                width={"320px"}
                                rest={{
                                    fontSize: "14px",
                                    border: "1px solid #ACCBFF",
                                    borderRadius: "37px",
                                    borderColor: "#ACCBFF",
                                }}
                                error={
                                    errors?.ParentLastName?.message as
                                        | string
                                        | undefined
                                }
                            />
                        </VStack>
                    </HStack>
                    <VStack alignItems={"flex-start"}>
                        <Text fontSize={"12px"}>Parent Email</Text>
                        <InputForm
                            placeholder={"Enter parent email"}
                            name={"ParentEmail"}
                            required={true}
                            type={"email"}
                            register={register}
                            height={"30px"}
                            width={"320px"}
                            rest={{
                                fontSize: "14px",
                                border: "1px solid #ACCBFF",
                                borderRadius: "37px",
                                borderColor: "#ACCBFF",
                            }}
                            error={
                                errors?.ParentEmail?.message as
                                    | string
                                    | undefined
                            }
                        />
                    </VStack>
                    <HStack>
                        <Button
                            width={"115px"}
                            borderRadius={"37px"}
                            variant="outline"
                            border={"1px solid #ACCBFF"}
                            color={"black"}
                            onClick={onClose}
                            fontSize={"14px"}
                        >
                            <Text>Cancel</Text>
                        </Button>
                        <Button
                            width={"115px"}
                            bg={"#136AFF"}
                            borderRadius={"37px"}
                            color={"white"}
                            type={"submit"}
                            fontSize={"14px"}
                        >
                            Add Student
                        </Button>
                    </HStack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
