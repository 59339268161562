import React, { FunctionComponent } from "react";
import {
    Button,
    Heading,
    HStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Radio,
    Text,
    VStack,
} from "@chakra-ui/react";
import { InputForm } from "../common/InputForm";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";

type License = {
    Id: number;
    CreatedAt: string;
    UpdatedAt: string;
    Id_Administrator_Teacher_License: number;
    Id_Teacher: number;
    RegistrationStatus: string;
    RegistrationType: string;
    ParentEmail: string;
    ParentFirstName: string;
    ParentLastName: string;
    StudentId: string;
    StudentEmail: string;
    StudentFirstName: string;
    StudentLastName: string;
    Id_Parent: number;
    Id_Student: number | null;
    Administrator_Teacher_License: {
        Id: number;
        Class: string;
        Grade: number;
    };
    Student_Assessment: {
        Status: string;
    }[];
};

type Props = {
    isOpen: boolean;
    onClose: () => void;
    updateStudent: (
        Id_Teacher_Student_License: number,
        data: unknown,
    ) => Promise<unknown>;
    license: License;
};

export const EditStudentModal: FunctionComponent<Props> = ({
    isOpen,
    onClose,
    license,
    updateStudent,
}) => {
    const schemaObject = z.object({
        StudentFirstName: z
            .string()
            .min(1, "First name is required")
            .max(64, "First name is too long"),
        StudentLastName: z
            .string()
            .min(1, "Last name is required")
            .max(64, "Last name is too long"),
        StudentEmail: z
            .string()
            .email("Invalid email address")
            .max(256, "Email is too long"),
        StudentId: z.string().optional(),
    });

    const parentSchema = schemaObject.extend({
        ParentFirstName: z
            .string()
            .min(1, "First name is required")
            .max(64, "First name is too long"),
        ParentLastName: z
            .string()
            .min(1, "Last name is required")
            .max(64, "Last name is too long"),
        ParentEmail: z
            .string()
            .email("Invalid email address")
            .max(256, "Email is too long"),
    });

    const teacherDirectSchema = schemaObject.extend({
        ParentFirstName: z
            .string()
            .max(64, "First name is too long")
            .or(z.string().length(0)),
        ParentLastName: z
            .string()
            .max(64, "Last name is too long")
            .or(z.string().length(0)),
        ParentEmail: z
            .string()
            .email("Invalid email address")
            .or(z.string().length(0)),
    });

    const customResolver = (values, context, options) => {
        let schemaToUse;
        if (values.RegistrationType === "PARENT") {
            schemaToUse = parentSchema;
        } else {
            schemaToUse = teacherDirectSchema;
        }
        return zodResolver(schemaToUse)(values, context, options);
    };

    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm<{
        StudentFirstName: string;
        StudentLastName: string;
        StudentId: string;
        StudentEmail: string;
        ParentFirstName: string;
        ParentLastName: string;
        ParentEmail: string;
    }>({
        resolver: customResolver,
        defaultValues: {
            StudentId: license.StudentId || '',
            StudentFirstName: license.StudentFirstName,
            StudentLastName: license.StudentLastName,
            StudentEmail: license.StudentEmail,
            ParentFirstName: license.ParentFirstName,
            ParentLastName: license.ParentLastName,
            ParentEmail: license.ParentEmail,
        },
    });

    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent
                borderRadius={"15px"}
                p={5}
                minW={"732px"}
                minH={"243px"}
            >
                <ModalHeader color={"#032E59"}>
                    <Heading size={"sm"}>Edit Student</Heading>
                    <Text
                        fontSize={"14px"}
                        fontWeight={"400"}
                        lineHeight={"19px"}
                    >
                        Enter the information below
                    </Text>
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    color={"black"}
                    fontSize={"14px"}
                    gap={"27px"}
                    as={"form"}
                    onSubmit={handleSubmit((data) => {
                        // TODO: Only include changed fields in 'data'.

                        updateStudent(license.Id, data);
                    })}
                >
                    <HStack>
                        <VStack alignItems={"flex-start"}>
                            <Text fontSize={"12px"}>Student First Name</Text>

                            <InputForm
                                placeholder={"Enter student name"}
                                name={"StudentFirstName"}
                                required={true}
                                register={register}
                                height={"30px"}
                                width={"320px"}
                                rest={{
                                    fontSize: "14px",
                                    border: "1px solid #ACCBFF",
                                    borderRadius: "37px",
                                    borderColor: "#ACCBFF",
                                }}
                                error={
                                    errors?.StudentFirstName?.message as
                                        | string
                                        | undefined
                                }
                            />
                        </VStack>
                        <VStack alignItems={"flex-start"}>
                            <Text fontSize={"12px"}>Student Last Name</Text>

                            <InputForm
                                placeholder={"Enter student name"}
                                name={"StudentLastName"}
                                required={true}
                                register={register}
                                height={"30px"}
                                width={"320px"}
                                rest={{
                                    fontSize: "14px",
                                    border: "1px solid #ACCBFF",
                                    borderRadius: "37px",
                                    borderColor: "#ACCBFF",
                                }}
                                error={
                                    errors?.StudentLastName?.message as
                                        | string
                                        | undefined
                                }
                            />
                        </VStack>
                    </HStack>
                    <HStack>
                        <VStack alignItems={"flex-start"}>
                            <Text fontSize={"12px"}>Student Id</Text>
                            <InputForm
                                placeholder={"Enter student Id"}
                                name={"StudentId"}
                                required={true}
                                register={register}
                                type={"text"}
                                height={"30px"}
                                width={"320px"}
                                rest={{
                                    fontSize: "14px",
                                    border: "1px solid #ACCBFF",
                                    borderRadius: "37px",
                                    borderColor: "#ACCBFF",
                                }}
                                error={
                                    errors?.StudentId?.message as
                                        | string
                                        | undefined
                                }
                            />
                        </VStack>
                        <VStack alignItems={"flex-start"}>
                            <Text fontSize={"12px"}>Student Email</Text>
                            <InputForm
                                placeholder={"Enter student email"}
                                name={"StudentEmail"}
                                required={true}
                                register={register}
                                type={"email"}
                                height={"30px"}
                                width={"320px"}
                                rest={{
                                    fontSize: "14px",
                                    border: "1px solid #ACCBFF",
                                    borderRadius: "37px",
                                    borderColor: "#ACCBFF",
                                    isDisabled: !!license.Id_Student,
                                }}
                                error={
                                    errors?.StudentEmail?.message as
                                        | string
                                        | undefined
                                }
                            />
                        </VStack>
                    </HStack>
                    <VStack
                        alignItems={"flex-start"}
                        w={"100%"}
                        spacing={"15px"}
                        borderTop={"0.5px solid #AAAAAA"}
                        borderBottom={"0.5px solid #AAAAAA"}
                        padding={"15px 0"}
                    >
                        <Text
                            fontSize={"14px"}
                            fontWeight={"400"}
                            lineHeight={"19px"}
                            color={"#032E59"}
                        >
                            Registration Type:
                        </Text>
                        <HStack w={"100%"}>
                            <HStack>
                                <VStack
                                    w={"264px"}
                                    h={"34px"}
                                    justifyContent={"center"}
                                    p={"14px"}
                                >
                                    <Radio
                                        size={"sm"}
                                        w={"100%"}
                                        value="PARENT"
                                        isDisabled={
                                            license.RegistrationType !==
                                            "PARENT"
                                        }
                                        isChecked={
                                            license.RegistrationType ===
                                            "PARENT"
                                        }
                                    >
                                        <VStack
                                            alignItems={"flex-start"}
                                            spacing={"4px"}
                                        >
                                            <Text>Parent Registration</Text>
                                        </VStack>
                                    </Radio>
                                </VStack>
                                <VStack
                                    w={"264px"}
                                    h={"34px"}
                                    justifyContent={"center"}
                                    p={"14px"}
                                >
                                    <Radio
                                        size={"sm"}
                                        value="STUDENT"
                                        w={"100%"}
                                        isDisabled={
                                            license.RegistrationType !==
                                            "STUDENT"
                                        }
                                        isChecked={
                                            license.RegistrationType ===
                                            "STUDENT"
                                        }
                                    >
                                        <VStack
                                            alignItems={"flex-start"}
                                            spacing={"4px"}
                                        >
                                            <Text>Teacher Direct</Text>
                                        </VStack>
                                    </Radio>
                                </VStack>
                            </HStack>
                        </HStack>
                    </VStack>
                    <HStack>
                        <VStack alignItems={"flex-start"}>
                            <Text fontSize={"12px"}>Parent First Name</Text>

                            <InputForm
                                placeholder={"Enter parent name"}
                                name={"ParentFirstName"}
                                required={true}
                                register={register}
                                height={"30px"}
                                width={"320px"}
                                rest={{
                                    fontSize: "14px",
                                    border: "1px solid #ACCBFF",
                                    borderRadius: "37px",
                                    borderColor: "#ACCBFF",
                                }}
                                error={
                                    errors?.ParentFirstName?.message as
                                        | string
                                        | undefined
                                }
                            />
                        </VStack>

                        <VStack alignItems={"flex-start"}>
                            <Text fontSize={"12px"}>Parent Last Name</Text>

                            <InputForm
                                placeholder={"Enter parent Last Name"}
                                name={"ParentLastName"}
                                required={true}
                                register={register}
                                height={"30px"}
                                width={"320px"}
                                rest={{
                                    fontSize: "14px",
                                    border: "1px solid #ACCBFF",
                                    borderRadius: "37px",
                                    borderColor: "#ACCBFF",
                                }}
                                error={
                                    errors?.ParentLastName?.message as
                                        | string
                                        | undefined
                                }
                            />
                        </VStack>
                    </HStack>
                    <VStack alignItems={"flex-start"}>
                        <Text fontSize={"12px"}>Parent Email</Text>
                        <InputForm
                            placeholder={"Enter parent email"}
                            name={"ParentEmail"}
                            required={true}
                            type={"email"}
                            register={register}
                            height={"30px"}
                            width={"320px"}
                            rest={{
                                fontSize: "14px",
                                border: "1px solid #ACCBFF",
                                borderRadius: "37px",
                                borderColor: "#ACCBFF",
                                isDisabled: !!license.Id_Parent,
                            }}
                            error={
                                errors?.ParentEmail?.message as
                                    | string
                                    | undefined
                            }
                        />
                    </VStack>
                    <HStack>
                        <Button
                            width={"115px"}
                            borderRadius={"37px"}
                            variant="outline"
                            border={"1px solid #ACCBFF"}
                            color={"black"}
                            onClick={onClose}
                            fontSize={"14px"}
                        >
                            <Text>Cancel</Text>
                        </Button>
                        <Button
                            width={"115px"}
                            bg={"#136AFF"}
                            borderRadius={"37px"}
                            color={"white"}
                            type={"submit"}
                            fontSize={"14px"}
                        >
                            Save Changes
                        </Button>
                    </HStack>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
