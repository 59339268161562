import React, { FunctionComponent } from "react";
import {
    HStack,
    VStack,
    Box,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    Button,
    chakra,
} from "@chakra-ui/react";
import * as z from "zod";
import { InputForm } from "../common/InputForm";
import { useForm } from "react-hook-form";
import { isNil } from "lodash";

const schema = z.object({
    teacherName: z.string().nonempty("Teacher Name is required"),
    numberOfLicenses: z.number().min(1, "Number of Licenses is required"),
    teacherEmail: z
        .string()
        .email()
        .nonempty("Administrator Email is required"),
});

interface AddTeacherProps {
    handleAddTeacher: (data: Omit<any, "id">) => void;
    handleEditTeacher: (data: any) => void;
    defaultValues?: any;
    isOpen: boolean;
    onClose: () => void;
}

export const TeacherModal: FunctionComponent<AddTeacherProps> = ({
    handleAddTeacher,
    defaultValues,
    isOpen,
    onClose,
    handleEditTeacher,
}) => {
    const {
        register,
        formState: { errors },
        handleSubmit,
    } = useForm({
        resolver: async (data) => {
            try {
                const parsedData = schema.parse({
                    ...data,
                    numberOfLicenses: Number(data.numberOfLicenses),
                });
                return { values: parsedData, errors: {} };
            } catch (error) {
                return { values: {}, errors: error.formErrors.fieldErrors };
            }
        },
        defaultValues: {
            teacherName: defaultValues?.name,
            teacherLastName: defaultValues?.lastName,
            numberOfLicenses: defaultValues?.licensesTotal,
            teacherEmail: defaultValues?.email,
        },
        shouldUnregister: true,
    });

    const onSubmit = (data) => {
        const newTeacher = {
            name: data.teacherName,
            email: data.teacherEmail,
            licensesTotal: data.numberOfLicenses,
            status: "pending",
        };

        if (!isNil(defaultValues)) {
            handleEditTeacher({ ...newTeacher, id: defaultValues.id });
        } else {
            handleAddTeacher(newTeacher);
        }
        onClose();
    };

    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent maxW="724px">
                    <ModalHeader
                        mt="14px"
                        fontFamily="Nunito"
                        fontStyle="normal"
                        fontWeight="700"
                        fontSize="16px"
                        lineHeight="20px"
                        color="#032E59"
                    >
                        {isNil(defaultValues) ? "Add" : "Edit"} License
                        Recipient
                        <Text
                            fontFamily="Nunito"
                            fontStyle="normal"
                            fontWeight="400"
                            fontSize="14px"
                            lineHeight="24px"
                        >
                            Enter the information below.
                        </Text>
                    </ModalHeader>
                    <chakra.form onSubmit={handleSubmit(onSubmit)}>
                        <ModalBody color={"black"}>
                            <VStack w="100%">
                                <HStack w="100%" gap="20px">
                                    <Box w="50%" minHeight="75px">
                                        <Text
                                            w={"100%"}
                                            textAlign={"left"}
                                            fontSize={"12px"}
                                            fontWeight={"400"}
                                            lineHeight={"16px"}
                                            mb={"11px"}
                                        >
                                            Teacher First Name
                                        </Text>
                                        <InputForm
                                            register={register}
                                            type={"name"}
                                            name={"teacherName"}
                                            placeholder={"Enter first name"}
                                            height={"30px"}
                                            width={"100%"}
                                            error={
                                                errors?.teacherName?.message as
                                                    | string
                                                    | undefined
                                            }
                                            rest={{
                                                border: "1px solid #ACCBFF",
                                                borderColor: "#ACCBFF",
                                                borderRadius: "37px",
                                                bg: "#fff",
                                                mx: "0px",
                                                fontSize: "16px",
                                                textAlign: "left",
                                            }}
                                        />
                                    </Box>

                                    <Box w="50%" minHeight="75px">
                                        <Text
                                            w={"100%"}
                                            textAlign={"left"}
                                            fontSize={"12px"}
                                            fontWeight={"400"}
                                            lineHeight={"16px"}
                                            mb={"11px"}
                                        >
                                            Teacher Last Name
                                        </Text>
                                        <InputForm
                                            register={register}
                                            type={"name"}
                                            name={"teacherLastName"}
                                            placeholder={"Enter last name"}
                                            height={"30px"}
                                            width={"100%"}
                                            error={
                                                errors?.teacherName?.message as
                                                    | string
                                                    | undefined
                                            }
                                            rest={{
                                                border: "1px solid #ACCBFF",
                                                borderColor: "#ACCBFF",
                                                borderRadius: "37px",
                                                bg: "#fff",
                                                mx: "0px",
                                                fontSize: "16px",
                                                textAlign: "left",
                                            }}
                                        />
                                    </Box>
                                </HStack>

                                <HStack w="100%" gap="20px">
                                    <Box w="50%" minHeight="75px">
                                        <Text
                                            w={"100%"}
                                            textAlign={"left"}
                                            fontSize={"12px"}
                                            fontWeight={"400"}
                                            lineHeight={"16px"}
                                            mb={"11px"}
                                        >
                                            Teacher Email
                                        </Text>
                                        <InputForm
                                            register={register}
                                            type={"email"}
                                            name={"teacherEmail"}
                                            placeholder={"Enter email"}
                                            height={"30px"}
                                            width={"100%"}
                                            error={
                                                errors?.teacherEmail
                                                    ?.message as
                                                    | string
                                                    | undefined
                                            }
                                            rest={{
                                                border: "1px solid #ACCBFF",
                                                borderColor: "#ACCBFF",
                                                borderRadius: "37px",
                                                bg: "#fff",
                                                mx: "0px",
                                                fontSize: "16px",
                                                textAlign: "left",
                                            }}
                                        />
                                    </Box>
                                    <Box w="50%" minHeight="75px">
                                        <Text
                                            w={"100%"}
                                            textAlign={"left"}
                                            fontSize={"12px"}
                                            fontWeight={"400"}
                                            lineHeight={"16px"}
                                            mb={"11px"}
                                        >
                                            Number of Licenses
                                        </Text>
                                        <InputForm
                                            register={register}
                                            type={"number"}
                                            name={"numberOfLicenses"}
                                            placeholder={"500"}
                                            height={"30px"}
                                            width={"100%"}
                                            error={
                                                errors?.numberOfLicenses
                                                    ?.message as
                                                    | string
                                                    | undefined
                                            }
                                            rest={{
                                                border: "1px solid #ACCBFF",
                                                borderColor: "#ACCBFF",
                                                borderRadius: "37px",
                                                bg: "#fff",
                                                mx: "0px",
                                                fontSize: "16px",
                                                textAlign: "left",
                                            }}
                                        />
                                        <Text
                                            w={"100%"}
                                            textAlign={"right"}
                                            fontSize={"10px"}
                                            fontWeight={"400"}
                                            lineHeight={"16px"}
                                        >
                                            Licenses available: 20,000
                                        </Text>
                                    </Box>
                                </HStack>
                            </VStack>
                        </ModalBody>
                        <ModalFooter justifyContent="center" gap={"20px"}>
                            <Button
                                width={"115px"}
                                borderRadius={"37px"}
                                variant="outline"
                                border={"1px solid #ACCBFF"}
                                color={"black"}
                                onClick={() => {
                                    onClose();
                                }}
                                fontSize={"14px"}
                            >
                                <Text>Cancel</Text>
                            </Button>
                            <Button
                                width={"115px"}
                                bg={"#136AFF"}
                                borderRadius={"37px"}
                                color={"white"}
                                type={"submit"}
                                fontSize={"14px"}
                            >
                                {defaultValues ? "Save" : "Add"} Recipient
                            </Button>
                        </ModalFooter>
                    </chakra.form>
                </ModalContent>
            </Modal>
        </>
    );
};
