import React, { FunctionComponent, useRef } from "react";
import {
    AlertDialog,
    AlertDialogBody,
    AlertDialogCloseButton,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogOverlay,
    Button,
    Grid,
    Heading,
    HStack,
    Input,
    Link,
    Text,
    useDisclosure,
    VStack,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { AdminFillFormButton } from "../../../lib/app/AdminFillFormButton";
import { InputForm } from "../common/InputForm";
import { ProgressButton } from "../common/ProgressButton";
import { ErrorMessage } from "../common/ErrorMessage";
import { SuccessMessage } from "../common/SuccessMessage";

type Props = {
    onContinueHandler: any;
    onResendCodeHandler: any;
    feedback: null | {
        error: {
            code: string;
        };
        success: {
            code: string;
        };
    };
    setCodeFeedback: (feedback: null | object) => void;
    enteredEmail: string;
};

const schema = z.object({
    firstDigit: z.string().min(1).max(1),
    secondDigit: z.string().min(1).max(1),
    thirdDigit: z.string().min(1).max(1),
    fourthDigit: z.string().min(1).max(1),
});
export const EnterCodeComponent: FunctionComponent<Props> = ({
    onContinueHandler,
    onResendCodeHandler,
    feedback,
    setCodeFeedback,
    enteredEmail,
}) => {
    const { register, handleSubmit, setValue, getValues, watch } = useForm({
        resolver: zodResolver(schema),
        reValidateMode: "onChange",
    });

    const firstDigitRef = useRef<HTMLInputElement>();
    const secondDigitRef = useRef<HTMLInputElement>();
    const thirdDigitRef = useRef<HTMLInputElement>();
    const fourthDigitRef = useRef<HTMLInputElement>();

    const firstDigit = watch("firstDigit");
    const secondDigit = watch("secondDigit");
    const thirdDigit = watch("thirdDigit");
    const fourthDigit = watch("fourthDigit");

    const { ref: refFirstDigit, ...restFirstDigit } = register("firstDigit");
    const { ref: refSecondDigit, ...restSecondDigit } = register("secondDigit");
    const { ref: refThirdDigit, ...restThirdDigit } = register("thirdDigit");
    const { ref: refFourthDigit, ...restFourthDigit } = register("fourthDigit");

    const { isOpen, onOpen, onClose } = useDisclosure();
    const cancelRef = React.useRef();

    function onAutofill() {
        const previewVerificationCode = window.localStorage.getItem(
            "re-preview-registration-verification-code",
        );
        if (previewVerificationCode) {
            window.localStorage.removeItem(
                "re-preview-registration-verification-code",
            );
            if (!getValues("firstDigit")) {
                setValue("firstDigit", previewVerificationCode.substring(0, 1));
                setValue(
                    "secondDigit",
                    previewVerificationCode.substring(1, 2),
                );
                setValue("thirdDigit", previewVerificationCode.substring(2, 3));
                setValue(
                    "fourthDigit",
                    previewVerificationCode.substring(3, 4),
                );
            }
        }
    }

    //reset code feedback if code changes
    // useEffect(() => {
    //     if (feedback) {
    //         setCodeFeedback(null);
    //     }
    // }, [firstDigit, secondDigit, thirdDigit, fourthDigit, feedback]);

    return (
        <HStack zIndex={"2"} spacing={"144px"} mb={"80px"}>
            <AlertDialog
                motionPreset="slideInBottom"
                onClose={onClose}
                isOpen={isOpen}
                isCentered
                leastDestructiveRef={cancelRef}
            >
                <AlertDialogOverlay />

                <AlertDialogContent>
                    <AlertDialogHeader>
                        Trouble signing in? We can help.
                    </AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody>
                        Please write what's the problem you are facing and we
                        will get back to you as soon as possible.
                        <Input placeholder={"Type your problem here"} />
                    </AlertDialogBody>
                    <AlertDialogFooter>
                        <Button ref={cancelRef} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button colorScheme="brand.blue" ml={3}>
                            Send
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialog>
            <VStack
                bg={"rgba(19, 106, 255, 0.2)"}
                w={"100%"}
                minH={"500px"}
                maxW={"415px"}
                p={"30px"}
                boxShadow={"0px 4px 14px rgba(19, 106, 255, 0.2)"}
                borderRadius={"20px"}
                spacing={"11px"}
            >
                <Heading
                    textAlign={"left"}
                    w={"100%"}
                    fontSize={"26px"}
                    size={"md"}
                    lineHeight={"20px"}
                >
                    Please enter your code
                </Heading>

                <AdminFillFormButton onClick={onAutofill} />

                <Text alignSelf={"start"}>
                    We sent a verification code, please enter it within 5
                    minutes of receiving it.
                </Text>

                <VStack alignItems={"flex-start"}>
                    <InputForm
                        name={"emailEntered"}
                        placeholder={""}
                        register={register}
                        height={"40px"}
                        width={"354px"}
                        type={"email"}
                        rest={{
                            value: enteredEmail,
                            fontSize: "14px",
                            borderColor: "#ACCBFF",
                            color: "#032E59",
                            borderRadius: "37px",
                            disabled: true,
                            textAlign: "left",
                        }}
                    />
                </VStack>
                <VStack
                    alignItems={"flex-start"}
                    color={"#032E59"}
                    fontSize={"9px"}
                    w={"100%"}
                >
                    <Text>Enter 4 digit code:</Text>
                    <Grid templateColumns={"1fr 1fr 1fr 1fr"} w={"100%"}>
                        <Input
                            {...restFirstDigit}
                            ref={(e) => {
                                refFirstDigit(e);
                                firstDigitRef.current = e;
                            }}
                            width={"57px"}
                            height={"53px"}
                            bg={"#FFFFFF"}
                            color="#136AFF"
                            textAlign={"center"}
                            maxLength={1}
                            minLength={1}
                            type={"number"}
                            onKeyDown={(e) => {
                                if (e.key.match(/^[0-9]+$/)) {
                                    e.preventDefault();
                                    setValue("firstDigit", e.key);
                                    secondDigitRef.current?.focus();
                                }
                            }}
                            onPaste={(e) => {
                                e.preventDefault();
                                const pastedData =
                                    e.clipboardData.getData("text");
                                if (
                                    pastedData.length === 4 &&
                                    /^[0-9]+$/.test(pastedData)
                                ) {
                                    setValue(
                                        "firstDigit",
                                        pastedData.charAt(0),
                                    );
                                    setValue(
                                        "secondDigit",
                                        pastedData.charAt(1),
                                    );
                                    setValue(
                                        "thirdDigit",
                                        pastedData.charAt(2),
                                    );
                                    setValue(
                                        "fourthDigit",
                                        pastedData.charAt(3),
                                    );
                                }
                            }}
                        />

                        <Input
                            name={"secondDigit"}
                            {...restSecondDigit}
                            ref={(e) => {
                                refSecondDigit(e);
                                secondDigitRef.current = e;
                            }}
                            width={"57px"}
                            height={"53px"}
                            bg={"#FFFFFF"}
                            color="#136AFF"
                            textAlign={"center"}
                            maxLength={1}
                            minLength={1}
                            type={"number"}
                            onKeyDown={(e) => {
                                if (e.key === "Backspace") {
                                    firstDigitRef.current?.focus();
                                }
                                //validate what we only have one digit
                                if (e.key.match(/^[0-9]+$/)) {
                                    e.preventDefault();
                                    setValue("secondDigit", e.key);
                                    thirdDigitRef.current?.focus();
                                }
                            }}
                        />
                        <Input
                            name={"thirdDigit"}
                            {...restThirdDigit}
                            ref={(e) => {
                                refThirdDigit(e);
                                thirdDigitRef.current = e;
                            }}
                            width={"57px"}
                            height={"53px"}
                            bg={"#FFFFFF"}
                            color="#136AFF"
                            textAlign={"center"}
                            maxLength={1}
                            minLength={1}
                            type={"number"}
                            onKeyDown={(e) => {
                                if (e.key === "Backspace") {
                                    secondDigitRef?.current?.focus();
                                }
                                //validate what we only have one digit
                                if (e.key.match(/^[0-9]+$/)) {
                                    e.preventDefault();
                                    setValue("thirdDigit", e.key);
                                    fourthDigitRef.current?.focus();
                                }
                            }}
                        />
                        <Input
                            name={"fourthDigit"}
                            {...restFourthDigit}
                            ref={(e) => {
                                refFourthDigit(e);
                                fourthDigitRef.current = e;
                            }}
                            width={"57px"}
                            height={"53px"}
                            bg={"#FFFFFF"}
                            color="#136AFF"
                            textAlign={"center"}
                            maxLength={1}
                            minLength={1}
                            type={"number"}
                            onKeyDown={(e) => {
                                if (e.key === "Backspace") {
                                    setValue("fourthDigit", "");
                                    thirdDigitRef?.current?.focus();
                                }
                                //validate what we only have one digit
                                if (e.key.match(/^[0-9]+$/)) {
                                    e.preventDefault();
                                    setValue("fourthDigit", e.key);
                                }
                            }}
                        />
                    </Grid>
                </VStack>

                {/* <VStack w={"100%"} alignItems={"start"}>
                    <Checkbox size="sm" colorScheme="brand.blue">
                        <Text fontSize={"12px"} color={"#032E59"}>
                            Remember me
                        </Text>
                    </Checkbox>
                </VStack> */}

                <ErrorMessage feedback={feedback} />
                <SuccessMessage feedback={feedback} />

                <Button
                    bg={"#136AFF"}
                    borderRadius={"30px"}
                    width={"171px"}
                    minHeight={"40px"}
                    color={"#FFFFFF"}
                    fontWeight={"900"}
                    fontSize={"16px"}
                    lineHeight={"20px"}
                    onClick={handleSubmit(onContinueHandler)}
                    mt={"10px"}
                >
                    Submit
                </Button>
                <Text fontSize={"14px"}>
                    If you haven’t received a code, please wait 60 seconds to
                    request a new code. Please note that if you try signing in
                    again, a new verification code will be sent to you and the
                    previous one will expire.
                </Text>

                <HStack
                    color="#032E59"
                    justifyContent={"space-between"}
                    w={"100%"}
                    fontWeight={700}
                >
                    <ProgressButton
                        getTag={({ onClick }) => (
                            <Link onClick={onClick}>Resend Code</Link>
                        )}
                        onClick={async ({ resetButton }) => {
                            setValue("firstDigit", "");
                            setValue("secondDigit", "");
                            setValue("thirdDigit", "");
                            setValue("fourthDigit", "");

                            await onResendCodeHandler();

                            resetButton();
                        }}
                    />

                    <Button
                        color={"inherit"}
                        onClick={() => {
                            window.open(
                                `https://rellie.com/troubleshooting-faq#verify`,
                            );
                        }}
                        variant={"link"}
                    >
                        Still having trouble?
                    </Button>
                </HStack>
            </VStack>
        </HStack>
    );
};
