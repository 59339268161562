import React from "react";
import { navigate, RouteComponentProps, useLocation } from "@reach/router";
import { Loading } from "../../src/components/common/Loading";
import { Box } from "@chakra-ui/react";
import { useApp } from "./App";

type LoginProtectionProps = {
    children: React.ReactNode;
};
export function LoginProtectionComponent({
    children,
}: LoginProtectionProps & RouteComponentProps) {

    const location = useLocation();

    const { useData } = useApp();
    const userData = useData("User");

    if (!userData.isFetched) {
        return (
            <Box mt="200px">
                <Loading/>
            </Box>
        );
    }

    if (!userData.data?.user) {
        if (
            location.pathname !== "/" &&
            !/(\?|&)dev-action=login-google&/.test(window.location.search) &&
            // Public pages accessible without login
            !/\/register/.test(location.pathname)
        ) {
            navigate("/");
            return null;
        }
    } else {
        // User is logged in so we need to redirect them to dashboard if they are on the /register pages
        if (/\/register/.test(location.pathname)) {
            navigate("/");
            return null;
        }
        // TODO: If user is in wrong area of app based on their role, redirect them to their dashboard.
    }

    return <>{children}</>;
}
