import React, { FunctionComponent } from "react";
import { Box, Button, Center, Heading, Text, VStack } from "@chakra-ui/react";
import { getQueryParam } from "../../lib/util";

import { Link, RouteComponentProps, navigate } from "@reach/router";
import { IoWarning } from "react-icons/io5";

type ErrorProps = {
    errorCode?: number | string;
    email?: string;
    message?: string;
    goBackUrl?: boolean | string;
};

export const ErrorPage: FunctionComponent<RouteComponentProps & ErrorProps> = ({
    errorCode,
    email,
    message,
    goBackUrl,
}) => {
    errorCode = errorCode || getQueryParam("code");

    if (!errorCode) {
        throw new Error(`No error 'code' provided in query string!`);
    }

    email = email || getQueryParam("email");
    message = message || getQueryParam("message");

    if (!goBackUrl && goBackUrl !== false) {
        goBackUrl = "/";
    }

    let ErrorMessage = null;
    if (errorCode === "ACCOUNT_WITH_EMAIL_ALREADY_EXISTS") {
        ErrorMessage = (
            <>
                An account already exists for email {email} and cannot be
                created. Please{" "}
                <Link
                    to="/"
                    getProps={() => {
                        return {
                            style: {
                                textDecoration: "underline",
                            },
                        };
                    }}
                >
                    login
                </Link>{" "}
                instead.
            </>
        );
    } else if (errorCode === "OLD_REGISTRATION_TOKEN") {
        goBackUrl = false;
        ErrorMessage = (
            <>
                You are not using the most recent registration invitation.
                Please look for the latest email and try again. <br />
                If you cannot find it, please request a new one from your Rellie
                license administrator.
            </>
        );
    } else if (errorCode === "OLD_PASSWORD_RESET_TOKEN") {
        goBackUrl = false;
        ErrorMessage = (
            <>
                You are not using the most recent password reset invitation.
                Please look for the latest email and try again. <br />
                If you cannot find it, please request a new password reset email.
            </>
        );        
    } else if (errorCode === "INVALID_REGISTRATION_TOKEN") {
        goBackUrl = false;
        ErrorMessage = (
            <>
                Your registration link is invalid. Please request an invitation
                from your Rellie license administrator.
            </>
        );
    } else if (errorCode === "EXPIRED_REGISTRATION_TOKEN") {
        goBackUrl = false;
        ErrorMessage = (
            <>
                Your registration link has expired. Please request a new one
                from your Rellie license administrator.
            </>
        );
    } else if (errorCode === "EXPIRED_PASSWORD_RESET_TOKEN") {
        goBackUrl = false;
        ErrorMessage = (
            <>
                Your password reset link has expired. Please request a new
                password reset email.
            </>
        );
    } else if (errorCode === "USER_MUST_USE_PASSWORD_TO_LOGIN") {
        ErrorMessage = (
            <>
                An account for email {email} registered using a password already
                exists. <br />
                Please use your unique password upon initial registration or
                reset your password.
            </>
        );
    } else if (errorCode === "USER_MUST_USE_PASSWORD_TO_REGISTER") {
        ErrorMessage = (
            <>
                You must use the password method to register for your account.<br/>
                Please{" "}
                <Link
                    to="/register/password"
                    getProps={() => {
                        return {
                            style: {
                                textDecoration: "underline",
                            },
                        };
                    }}
                >
                    click here
                </Link>{" "}
                to register using a password.
            </>
        );
    } else if (errorCode === "GOOGLE_OAUTH_USER_IS_NOT_AUTHORIZED") {
        goBackUrl = false;
        ErrorMessage = (
            <>
                Your Google account is not authorized to access this
                application.<br />
                <b>Make sure the email address of your Google account
                matches the email address of your invitation email.</b><br />
                If you do not have an invitation email, please request
                one from your Rellie license provider.
            </>
        );
    } else if (errorCode === "ERROR_LOADING_APPLICATION_BACKEND_API") {
        ErrorMessage = (
            <>
                An error occurred loading the application.
                <br />
                Please try again later.
            </>
        );
    } else if (errorCode === "LOCKED_PREVIEW") {
        ErrorMessage = <>{message}</>;
    } else if (
        errorCode === "INTERNAL_SERVER_ERROR" ||
        errorCode === "UNKNOWN_ERROR" ||
        true
    ) {
        goBackUrl = "https://rellie.com/about-us/";
        ErrorMessage = (
            <>
                An unknown error has occurred. Please try again. If the error
                persists, please let your Rellie license administrator know.
            </>
        );
    }

    return (
        <Center h="100vh" bg="gray.50">
            <VStack
                bg={"white"}
                w={"100%"}
                minH={"385px"}
                maxW={"500px"}
                p={"50px"}
                border="0.5px solid"
                borderColor="blue.500"
                boxShadow="0px 4px 14px 0px rgba(19, 106, 255, 0.20)"
                borderRadius={"xl"}
                spacing={"20px"}
                alignItems="center"
                justifyContent="center"
            >
                <Box as={IoWarning} boxSize={20} color="red.500" />
                <Heading
                    textAlign={"center"}
                    w={"100%"}
                    fontSize={"2xl"}
                    color={"#032E59"}
                    lineHeight={"1.2"}
                >
                    Oops! Something went wrong
                </Heading>

                <Text
                    fontSize={"md"}
                    textAlign={"center"}
                    fontWeight={"normal"}
                >
                    {ErrorMessage}
                </Text>

                {goBackUrl !== false && (
                    <Button
                        w="170px"
                        h="40px"
                        bg="#136AFF"
                        border="1px solid #ACCBFF"
                        borderRadius="37px"
                        color="#fff"
                        fontSize="14px"
                        fontWeight="400"
                        lineHeight="16px"
                        onClick={() => navigate(goBackUrl as string)}
                    >
                        {errorCode === "UNKNOWN_ERROR"
                            ? "Contact Us"
                            : "Go Back"}
                    </Button>
                )}

                <Text
                    fontSize={"xs"}
                    color="gray.700"
                    fontWeight={"medium"}
                    textAlign={"center"}
                >
                    Code: {errorCode}
                </Text>
            </VStack>
        </Center>
    );
};
