import React, { FunctionComponent } from "react";
import {
    Button,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
} from "@chakra-ui/react";
import { RiErrorWarningFill } from "react-icons/ri";
import { ProgressButton } from "./ProgressButton";


type Props = {
    isOpen: boolean;
    onClose: () => void;
    onConfirmation: () => void;
    confirmationText: string;
    confirmationButtonText: string;
};

export const ConfirmationPopup: FunctionComponent<Props> = ({
    isOpen,
    onClose,
    onConfirmation,
    confirmationText,
    confirmationButtonText,
}) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader mt={5} display={"flex"} justifyContent={"center"}>
                    <RiErrorWarningFill color={"#136AFF"} size={"30px"} />
                </ModalHeader>
                <ModalBody>
                    <Text textAlign={"center"} fontSize="20px" fontWeight="700">
                        {confirmationText}
                    </Text>
                </ModalBody>
                <ModalFooter display={"flex"} justifyContent={"center"}>
                    <Button
                        w="120px"
                        h="40px"
                        bg="#fff"
                        border="2px solid rgba(19, 106, 255, 1)"
                        borderRadius="37px"
                        color={"rgba(19, 106, 255, 1)"}
                        fontSize="14px"
                        fontWeight="400"
                        lineHeight="16px"
                        mr="10px"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <ProgressButton
                        getTag={({ onClick }) => (
                            <Button
                                w="120px"
                                h="40px"
                                bg="rgba(0, 81, 219, 1)"
                                borderRadius="37px"
                                color="#fff"
                                fontSize="14px"
                                fontWeight="400"
                                lineHeight="16px"
                                onClick={onClick}
                            >
                                {confirmationButtonText}
                            </Button>                            
                        )}
                        onClick={async ({ resetButton }) => {

                            await onConfirmation();

                            onClose();
                            resetButton();
                        }}
                    />

                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
