import React, { FunctionComponent } from "react";
import { RouteComponentProps } from "@reach/router";
import {
    Heading,
    VStack,
    Box,
    HStack,
    Icon,
    Progress,
    Text,
    Flex,
} from "@chakra-ui/react";
import { useApp } from "../../../lib/app/App";
import { AdminEnableToolsBookmarkletLink } from "../../../lib/app/AdminEnableToolsBookmarkletLink";
import { AdminDisableToolsBookmarkletLink } from "../../../lib/app/AdminDisableToolsBookmarkletLink";
import { AdminTestErrorLoggingButton } from "../../../lib/app/AdminTestErrorLoggingButton";
import { MdOutlineAssignment } from "react-icons/md";

export const AdminDashboardPage: FunctionComponent<
    RouteComponentProps
> = () => {
    const { isAdmin } = useApp();

    if (!isAdmin) {
        return null;
    }

    const questionnairePercentage = 80;
    const numberOfStudents = 20;

    const successFullyRegistered = 80;
    const numberOfTeachers = 20;

    return (
        <VStack alignItems={"flex-start"} spacing={"52px"}>
            <Box marginTop={"50px"}>
                <Heading size={"md"} color={"#032E59"}>
                    Rellie Admin Dashboard
                </Heading>

                <Text color="red" fontWeight={"bold"}>NOTE: The elements below are PREVIEWS and not yet hooked up to the backend.</Text>

                <HStack marginTop={"20px"} spacing="25px" align="top">

                    <Flex
                        mt={"20px"}
                        flexDirection={"column"}
                        gap={"20px"}
                    >
                        <VStack
                            border={"0.5px solid #D9D9D9"}
                            borderRadius={"16px"}
                            p={"14px"}
                            bg={"white"}
                        >
                            <HStack w={"100%"} justifyContent={"space-between"}>
                                <Heading
                                    fontSize={"19px"}
                                    color={"#032E59"}
                                    fontWeight={700}
                                >
                                    Total Students Licenses
                                </Heading>
                                <Text
                                    color={"#6C6C6C"}
                                    fontWeight={700}
                                    fontSize={"23px"}
                                >
                                    {numberOfStudents}
                                </Text>
                            </HStack>
                            <HStack
                                justifyContent={"space-between"}
                                w={"100%"}
                                spacing={"12px"}
                            >
                                <HStack spacing={"28px"}>
                                    <Box
                                        bg={"#FF4DD1"}
                                        borderRadius={"16px"}
                                        display={"flex"}
                                        alignItems={"center"}
                                        justifyContent={"center"}
                                        w={"88px"}
                                        h={"80px"}
                                    >
                                        <Icon
                                            color={"white"}
                                            fontSize={"40px"}
                                            as={MdOutlineAssignment}
                                        />
                                    </Box>
                                    <Heading
                                        fontSize={"19px"}
                                        color={"#032E59"}
                                        fontWeight={700}
                                    >
                                        QUESTIONNAIRE
                                    </Heading>
                                </HStack>
                                <HStack
                                    spacing={
                                        questionnairePercentage < 100
                                            ? "30px"
                                            : "14px"
                                    }
                                >
                                    {questionnairePercentage < 100 ? (
                                        <>
                                            <Progress
                                                w={"167px"}
                                                maxW={"167px"}
                                                value={questionnairePercentage}
                                                borderRadius={"11px"}
                                                bg={"rgba(0, 0, 0, 0.08);"}
                                                colorScheme={"brand.pink"}
                                            />
                                            <Text
                                                fontSize={"19px"}
                                                fontWeight={500}
                                            >
                                                {questionnairePercentage}%
                                            </Text>
                                        </>
                                    ) : (
                                        <Text
                                            color={"#6C6C6C"}
                                            fontWeight={700}
                                            fontSize={"15px"}
                                        >
                                            COMPLETE
                                        </Text>
                                    )}
                                </HStack>
                            </HStack>
                        </VStack>

                        <VStack
                            border={"0.5px solid #D9D9D9"}
                            borderRadius={"16px"}
                            p={"14px"}
                            bg={"white"}
                        >
                            <HStack w={"100%"} justifyContent={"space-between"}>
                                <Heading
                                    fontSize={"19px"}
                                    color={"#032E59"}
                                    fontWeight={700}
                                >
                                    Total Teacher Licenses
                                </Heading>
                                <Text
                                    color={"#6C6C6C"}
                                    fontWeight={700}
                                    fontSize={"23px"}
                                >
                                    {numberOfTeachers}
                                </Text>
                            </HStack>
                            <HStack
                                justifyContent={"space-between"}
                                w={"100%"}
                                spacing={"12px"}
                            >
                                <HStack spacing={"28px"}>
                                    <Box
                                        bg={"#FBBC04"}
                                        borderRadius={"16px"}
                                        display={"flex"}
                                        alignItems={"center"}
                                        justifyContent={"center"}
                                        w={"88px"}
                                        h={"80px"}
                                    >
                                        <Icon
                                            color={"white"}
                                            fontSize={"40px"}
                                            as={MdOutlineAssignment}
                                        />
                                    </Box>
                                    <Heading
                                        fontSize={"19px"}
                                        color={"#032E59"}
                                        fontWeight={700}
                                    >
                                        % Successfully Registered
                                    </Heading>
                                </HStack>
                                <HStack
                                    spacing={
                                        successFullyRegistered < 100
                                            ? "30px"
                                            : "14px"
                                    }
                                >
                                    {successFullyRegistered < 100 ? (
                                        <>
                                            <Progress
                                                w={"167px"}
                                                maxW={"167px"}
                                                value={successFullyRegistered}
                                                borderRadius={"11px"}
                                                bg={"rgba(0, 0, 0, 0.08);"}
                                                colorScheme={"brand.yellow"}
                                            />
                                            <Text
                                                fontSize={"19px"}
                                                fontWeight={500}
                                            >
                                                {successFullyRegistered}%
                                            </Text>
                                        </>
                                    ) : (
                                        <Text
                                            color={"#6C6C6C"}
                                            fontWeight={700}
                                            fontSize={"15px"}
                                        >
                                            COMPLETE
                                        </Text>
                                    )}
                                </HStack>
                            </HStack>
                        </VStack>

                        <VStack
                            border={"0.5px solid #D9D9D9"}
                            borderRadius={"16px"}
                            p={"14px"}
                            bg={"white"}
                        >
                            <Heading
                                alignSelf={"self-start"}
                                fontSize={"19px"}
                                fontWeight={700}
                            >
                                Teacher Logins
                            </Heading>
                            <HStack
                                justifyContent={"space-between"}
                                w={"100%"}
                                spacing={"12px"}
                            >
                                <HStack spacing={"28px"}>
                                    <Box
                                        bg={"#136AFF"}
                                        borderRadius={"16px"}
                                        display={"flex"}
                                        alignItems={"center"}
                                        justifyContent={"center"}
                                        w={"88px"}
                                        h={"80px"}
                                        fontSize={"30px"}
                                        color={"white"}
                                    >
                                        13
                                    </Box>
                                    <Heading
                                        fontSize={"19px"}
                                        color={"#032E59"}
                                        fontWeight={700}
                                    >
                                        Today
                                    </Heading>
                                </HStack>
                                <HStack spacing={"28px"}>
                                    <Box
                                        bg={"#FBBC04"}
                                        borderRadius={"16px"}
                                        display={"flex"}
                                        alignItems={"center"}
                                        justifyContent={"center"}
                                        w={"88px"}
                                        h={"80px"}
                                        fontSize={"30px"}
                                        color={"white"}
                                    >
                                        33
                                    </Box>
                                    <Heading
                                        fontSize={"19px"}
                                        color={"#032E59"}
                                        fontWeight={700}
                                    >
                                        This Week
                                    </Heading>
                                </HStack>
                                <HStack spacing={"28px"}>
                                    <Box
                                        bg={"#FF4DD1"}
                                        borderRadius={"16px"}
                                        display={"flex"}
                                        alignItems={"center"}
                                        justifyContent={"center"}
                                        w={"88px"}
                                        h={"80px"}
                                        fontSize={"30px"}
                                        color={"white"}
                                    >
                                        340
                                    </Box>
                                    <Heading
                                        fontSize={"19px"}
                                        color={"#032E59"}
                                        fontWeight={700}
                                    >
                                        This Month
                                    </Heading>
                                </HStack>
                            </HStack>
                        </VStack>

                        <VStack
                            border={"0.5px solid #D9D9D9"}
                            borderRadius={"16px"}
                            p={"14px"}
                            bg={"white"}
                        >
                            <Heading
                                alignSelf={"self-start"}
                                fontSize={"19px"}
                                fontWeight={700}
                            >
                                Total Resources Utilized
                            </Heading>
                            <HStack
                                justifyContent={"space-between"}
                                w={"100%"}
                                spacing={"12px"}
                            >
                                <HStack spacing={"28px"}>
                                    <Box
                                        bg={"#136AFF"}
                                        borderRadius={"16px"}
                                        display={"flex"}
                                        alignItems={"center"}
                                        justifyContent={"center"}
                                        w={"88px"}
                                        h={"80px"}
                                        fontSize={"30px"}
                                        color={"white"}
                                    >
                                        130
                                    </Box>
                                    <Heading
                                        fontSize={"19px"}
                                        color={"#032E59"}
                                        fontWeight={700}
                                    >
                                        Today
                                    </Heading>
                                </HStack>
                                <HStack spacing={"28px"}>
                                    <Box
                                        bg={"#FBBC04"}
                                        borderRadius={"16px"}
                                        display={"flex"}
                                        alignItems={"center"}
                                        justifyContent={"center"}
                                        w={"88px"}
                                        h={"80px"}
                                        fontSize={"30px"}
                                        color={"white"}
                                    >
                                        522
                                    </Box>
                                    <Heading
                                        fontSize={"19px"}
                                        color={"#032E59"}
                                        fontWeight={700}
                                    >
                                        This Week
                                    </Heading>
                                </HStack>
                                <HStack spacing={"28px"}>
                                    <Box
                                        bg={"#FF4DD1"}
                                        borderRadius={"16px"}
                                        display={"flex"}
                                        alignItems={"center"}
                                        justifyContent={"center"}
                                        w={"88px"}
                                        h={"80px"}
                                        fontSize={"30px"}
                                        color={"white"}
                                    >
                                        1853
                                    </Box>
                                    <Heading
                                        fontSize={"19px"}
                                        color={"#032E59"}
                                        fontWeight={700}
                                    >
                                        This Month
                                    </Heading>
                                </HStack>
                            </HStack>
                        </VStack>
                    </Flex>

                </HStack>

            </Box>
        </VStack>
    );
};
