
import React, { useState } from "react";
import { Button, Icon } from "@chakra-ui/react";
import { BsHourglassBottom } from "react-icons/bs";

export function ProgressButton({
    getTag = null,
    style,
    onClick,
    label,
}: {
    getTag?: (props: { onClick: () => void }) => JSX.Element | null;
    style?: "primary" | "secondary";
    onClick: (props: { resetButton: () => void }) => void;
    label?: string;
}) {
    const [actioned, setActioned] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    const props = {
        isDisabled,
        color: "#0051DB",
        borderRadius: "50px",
        height: "20px",
        fontSize: "12px",
        variant: "outline",
        borderColor: "#0051DB",
        backgroundColor: undefined,
        onClick: () => {
            setActioned(true);
            onClick({
                resetButton: () => {
                    setActioned(false);
                },
                disableButton: () => {
                    setIsDisabled(true)
                }
            });
        },
    };

    if (style === "primary") {
        props.color = "#FFFFFF";
        props.backgroundColor = "rgb(19, 106, 255)";
    } else if (style === "secondary") {
        props.backgroundColor = "rgb(255, 255, 255)";
    } else if (!getTag) {
        throw new Error(`style '${style}' not supported!`);
    }

    if (actioned) {
        return (
            <Icon
                width={"14px"}
                height={"14px"}
                color={"#AAAAAA"}
                as={BsHourglassBottom}
            />
        );
    }

    if (getTag) {
        return getTag({
            isDisabled,
            onClick: props.onClick,
        });
    }

    return <Button {...props}>{label}</Button>;
}
